<div class="row pl-3">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <button class="btn btn-outline-lightning float-right" (click)="goBack()">
        <i class="fa fa-angle-left mr-1"></i>Back
      </button>
    </div>
  </div>
  <div class="row pt-3">
    <div class="col-1 col-sm-1 col-md-3 col-lg-4 col-xl-4"></div>
    <div
      class="col-10 col-sm-10 col-md-5 col-lg-4 col-xl-4 mb-4 align-items-stretch"
    >
      <div class="card border-0 h-100">
        <div class="card-title mb-1 p-3">
          <h5>Change Your Phone</h5>
        </div>
        <div class="card-body">
                    <form
          novalidate
          autocomplete="off"
          (ngSubmit)="sendOTP(false)"
          #changePhoneForm="ngForm"
          [hidden]="isOTPSend"
        >
                  <div class="form-group">
                    <label  class="label" for="">Current Phone :</label><br />
                    <label  class="label" for="">{{userAccount.Phone}}</label><br>
                    <label class="lable" for="">New Phone :</label>
                    <input
                      class="form-control"
                      type="text"
                      name=""
                      placeholder="New Phone"
                      id=""
                      [(ngModel)]="newPhone"
                      [ngClass]="{'is-invalid': Mobile.invalid && (Mobile.dirty || Mobile.touched || changePhoneForm.submitted) }"
                      [pattern]="constants.PHONE_REGEXP"
                      name="Mobile"
                      #Mobile="ngModel"
                      [maxLength]="10"
                      [disabled]="isOTPSend"
                      required
                      [appOnlyNumberDirective]="true"
                    />
                    <div
                    class="invalid-feedback"
                    [ngStyle]="{display:Mobile.invalid &&
                  (Mobile.dirty || Mobile.touched || changePhoneForm.submitted) ? 'block': 'none'}"
                  >
                    <div [hidden]="!Mobile?.errors?.required">
                      <span class="fa fa-exclamation-circle"></span> Phone is
                      required
                    </div>
                    <div [hidden]="!Mobile?.errors?.pattern">
                      Enter valid Phone
                    </div>
                  </div>
                    <button *ngIf="!isOTPSend" type="submit" class="btn btn-primary float-right mt-4">
                        Generate OTP
                      </button>
                      </div>
                      </form>
                   <form novalidate
                   autocomplete="false"
                   #verifyOTPForm="ngForm"
                   (ngSubmit)="verifyOTP(verifyOTPForm)"
                   [hidden]="!isOTPSend">
                    <div *ngIf="isOTPSend">
                      <label class="lable" for="">New Phone :</label><br>
                      <label  class="label" for="">{{newPhone}}</label><br>
                        <label for="">Phone OTP</label>
                      <input
                        class="form-control"
                        type="text"
                        name="OTP"
                        placeholder="Phone OTP"
                        [(ngModel)]="MobileOtp"
                        [maxLength]="8"
                        required
                        [ngClass]="{'is-invalid ': OTP.invalid && (OTP.dirty || OTP.touched) }"
                        [appOnlyNumberDirective]="true"
                        #OTP="ngModel"
                      />
                      <div
                      class="invalid-feedback"
                      [ngStyle]="{display:OTP.invalid &&
            (OTP.dirty || OTP.touched || verifyOTPForm.submitted) ? 'block': 'none'}"
                    >
                      <div [hidden]="!OTP?.errors?.required">
                        <span class="fa fa-exclamation-circle"></span> OTP
                        is required
                      </div>
                    </div>
                      </div>
                      <a *ngIf="isOTPSend" (click)="sendOTP(true)" class="pointer float-left">Resend OTP</a><br>
                      <button type="button" (click)="isOTPSend = false;" class="btn btn-danger float-left mt-3">Cancel</button>
                      <button *ngIf="isOTPSend" type="submit" class="btn btn-primary float-right mt-3">Verify OTP</button><br>
                   </form>
        </div>
    </div>
    <div class="col-1 col-sm-1 col-md-4 col-lg-4 col-xl-4"></div>
  </div>
  </div>
  