<div>
  <div class="middle-reg-div">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
      <div style="background: rgb(67 74 82 / 69%); border-radius: 5px;">
        <img src="./assets/img/logo-new.png" height="50" /> <br />
        <span class="logo-text pl-1">Ask Real Teachers, Get Real Answers</span>
      </div>
    </div>
    <div class="row">
      <div class="registration-card login-box-image">
        <div
          class="form-container registration-form p-4 registration-container"
        >
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div>
              <div>
                <h4 class="text-uppercase mobileFont">
                  A (OTP) one time password has been sent to your registered
                  email id and phone no. Use the OTP to validate your email id
                  and phone no.
                </h4>
              </div>
              <div>
                <form
                  novalidate
                  autocomplete="off"
                  (ngSubmit)="submitUserDetails(registrationVerificationForm)"
                  #registrationVerificationForm="ngForm"
                >
                  <div>
                    <div class="form-group">
                      <label class="mb-0">Name: </label>&nbsp;
                      <p>
                        <strong class="color-blue"
                          >{{registrationVM.UserAccount.FirstName}}&nbsp;{{registrationVM.UserAccount.LastName}}</strong
                        >
                      </p>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="mb-0">Email: </label>&nbsp;
                    <p>
                      <strong class="color-blue"
                        >{{registrationVM.UserAccount.Email}}</strong
                      >
                    </p>
                  </div>

                  <div class="form-group">
                    <label class="required">Email</label>
                    <input
                      type="text"
                      class="form-control"
                      required
                      [(ngModel)]="registrationVM.UserAccount.Email"
                      [pattern]="constants.EMAIL_REGEXP"
                      [ngClass]="{'is-invalid': Email.invalid && (Email.dirty || Email.touched ||registrationVerificationForm.submitted)}"
                      name="Email"
                      #Email="ngModel"
                      placeholder="Email"
                    />
                    <div
                      class="invalid-feedback"
                      [ngStyle]="{display:Email.invalid &&
                              (Email.dirty || Email.touched || registrationVerificationForm.submitted) ? 'block': 'none'}"
                    >
                      <div [hidden]="!Email?.errors?.required">
                        <span class="fa fa-exclamation-circle"></span> Email is
                        required
                      </div>
                      <div [hidden]="!Email?.errors?.pattern">
                        Enter valid Email
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ml-0 pl-0"
                    *ngIf="isOTPSend"
                  >
                    <div class="form-group">
                      <label class="required"
                        >Email OTP (One Time Password)</label
                      >
                      <input
                        type="text"
                        [disabled]="isOTPVerified"
                        class="form-control"
                        required
                        [maxLength]="8"
                        [(ngModel)]="registrationOTPForEmail"
                        [ngClass]="{'is-invalid ': OTPForEmail.invalid && (OTPForEmail.dirty || OTPForEmail.touched || registrationVerificationForm.submitted) }"
                        name="registrationOTPForEmail"
                        [appOnlyNumberDirective]="true"
                        #OTPForEmail="ngModel"
                        placeholder="Email OTP"
                      />

                      <div class="row mb-3">
                        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                          <div
                            class="invalid-feedback"
                            [ngStyle]="{display:OTPForEmail.invalid &&
                  (OTPForEmail.dirty || OTPForEmail.touched || registrationVerificationForm.submitted) ? 'block': 'none'}"
                          >
                            <div *ngIf="OTPForEmail?.errors?.required">
                              <span class="fa fa-exclamation-circle"></span>
                              Email OTP is required
                            </div>
                          </div>
                        </div>
                        <div
                          class="invalid-feedback color-blue col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                          style="display: inline-block;"
                        ></div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ml-0 pl-0"
                  >
                    <div class="form-group">
                      <label class="mb-0">Phone Number: </label>&nbsp;
                      <p>
                        <strong class="color-blue"
                          >{{registrationVM.UserAccount.Phone}}</strong
                        >
                      </p>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="required">Phone Number</label>
                    <input
                      type="text"
                      class="form-control"
                      required
                      [(ngModel)]="registrationVM.UserAccount.Phone"
                      [ngClass]="{'is-invalid': Mobile.invalid && (Mobile.dirty || Mobile.touched || registrationVerificationForm.submitted) }"
                      [pattern]="constants.PHONE_REGEXP"
                      name="Mobile"
                      #Mobile="ngModel"
                      [maxLength]="10"
                      placeholder="Phone Number"
                      [appOnlyNumberDirective]="true"
                    />
                    <div
                      class="invalid-feedback"
                      [ngStyle]="{display:Mobile.invalid &&
                      (Mobile.dirty || Mobile.touched || registrationVerificationForm.submitted) ? 'block': 'none'}"
                    >
                      <div [hidden]="!Mobile?.errors?.required">
                        <span class="fa fa-exclamation-circle"></span> Phone
                        number is required
                      </div>
                      <div [hidden]="!Mobile?.errors?.pattern">
                        Enter valid Phone number
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ml-0 pl-0"
                    *ngIf="isOTPSend"
                  >
                    <div class="form-group mb-0">
                      <label class="required"
                        >Phone OTP (One Time Password)</label
                      >
                      <input
                        type="text"
                        [disabled]="isOTPVerified"
                        class="form-control"
                        required
                        [maxLength]="8"
                        [(ngModel)]="registrationOTPForMobile"
                        [ngClass]="{'is-invalid ': OTPForMobile.invalid && (OTPForMobile.dirty || OTPForMobile.touched || registrationVerificationForm.submitted) }"
                        name="registrationOTPForMobile"
                        [appOnlyNumberDirective]="true"
                        #OTPForMobile="ngModel"
                        placeholder="Phone OTP"
                      />

                      <div class="row mb-3">
                        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                          <div
                            class="invalid-feedback"
                            [ngStyle]="{display:OTPForMobile.invalid &&
                  (OTPForMobile.dirty || OTPForMobile.touched || registrationVerificationForm.submitted) ? 'block': 'none'}"
                          >
                            <div *ngIf="OTPForMobile?.errors?.required">
                              <span class="fa fa-exclamation-circle"></span>
                              Phone OTP is required
                            </div>
                          </div>
                        </div>
                        <div
                          class="invalid-feedback color-blue col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                          style="display: inline-block;"
                        ></div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ml-0 pl-0"
                  >
                    <div class="form-group">
                      <span class="float-right pointer">
                        <a (click)="sendOTP()">Resend OTP</a>
                      </span>
                    </div>
                  </div>

                  <div
                    class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 float-right mt-5"
                  >
                    <button
                      type="reset"
                      class="btn btn-secondary mb-2 float-right ml-2"
                      (click)="goBack()"
                    >
                      Cancel
                    </button>
                    <button
                      *ngIf="isOTPSend"
                      type="submit"
                      class="btn btn-primary mb-2 float-right"
                    >
                      Update
                    </button>
                    <button
                      *ngIf="!isOTPSend"
                      (click)="sendOTP()"
                      class="btn btn-primary mb-2 float-right"
                    >
                      SendOTP
                    </button>
                  </div>
                </form>
                <div class="font-weight-bold">
                  <span>
                    <a routerLink="/login" class="color-blue">
                      Login Here</a
                    ></span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
