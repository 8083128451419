export class Usersmissing {
  FirstName: string;
  LastName: string;
  UserName: string;
  Email: string;
  constructor() {
    this.FirstName = null;
    this.LastName = null;
    this.UserName = null;
    this.Email = null;
  }
}
