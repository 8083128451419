<aside class="sidebar shadow-sm">
  <div class="sidebar-profile d-flex flex-column justify-content-center pt-4">
    <div class="picture-wrapper mb-4">
      <div class="user-picture m-auto">
        <!-- <img
          [src]="currentUser.ProfileUrl !== null ? currentUser.ProfileUrl : './assets/img/default-user.png'"
          class="img-fluid"
          alt="{{currentUser.FirstName}}"
        /> -->
        <app-profile-pic
          [type]="'profile'"
          [documentId]="currentUser.ProfilePictureId"
        ></app-profile-pic>
      </div>
    </div>
    <div class="profile-details text-center pb-4">
      <p class="mb-0 text-uppercase name">
        <span class="pointer" (click)="viewProfile()"
          >{{currentUser.FirstName}}&nbsp;{{currentUser.LastName}}</span
        >
      </p>
      <small class="text-uppercase role" [innerHTML]="role"></small>
      <br />
      <small class="text-uppercase role"
        ><span class="pointer" (click)="changePassword()">Change password</span>
        | <span class="pointer" (click)="logout()">Logout</span></small
      >
    </div>
  </div>
  <div class="sidebar-search pt-1 pb-1 pl-3 pr-3">
    <input
      type="search"
      class="form-control rounded-pill"
      name="search"
      placeholder="Search ..."
    />
  </div>
  <nav class="sidebar-menu pb-3">
    <ul class="menu-items">
      <li *ngFor="let nav of navLinks">
        <a
          [routerLink]="nav.route"
          [queryParams]="nav.queryParams"
          href="#"
          routerLinkActive="active"
        >
          <i [ngClass]="nav.iconClass" title="{{nav.title}}"></i>
          <span>{{nav.title}}</span>
        </a>
      </li>
    </ul>
  </nav>
</aside>
