<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 text-center mb-1">
      <!-- <small>
        © 2020 made
        by
        <span class="text-secondary font-weight-bold"
          >Ask Real Teachers</span
        ></small
      > -->
      <small>
        © 2015 - 2021 made
        by
        <span class="text-secondary font-weight-bold"
          >A.R.T.101 LLC</span
        ></small
      >
    </div>
    <!-- <div class="col-md-12 text-center mb-3">
      <a
        href="#"
        target="_blank"
        class="btn btn-sm bg-light text-dark shadow-sm rounded-circle text-light m-2"
        ><i class="fab fa-github"></i
      ></a>
      <a
        href="#"
        target="_blank"
        class="btn btn-sm bg-light text-dark shadow-sm rounded-circle text-light m-2"
        ><i class="fab fa-twitter"></i
      ></a>
      <a
        href="#"
        target="_blank"
        class="btn btn-sm bg-light text-dark shadow-sm rounded-circle text-light m-2"
        ><i class="fab fa-instagram"></i
      ></a>
      <a
        href="#"
        target="_blank"
        class="btn btn-sm bg-light text-dark shadow-sm rounded-circle text-light m-2"
        ><i class="fab fa-linkedin-in"></i
      ></a>
    </div> -->
  </div>
</div>
