<div class="text-center">
    <div class="row">
      <div class="col-lg-3 col-xl-3"></div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mt-5">
        <div class="card p-3 h-100 w-75 login-box-image">
          <!-- <h2 class="fs-title text-center text-nowrap">Registration Successful !</h2> <br><br> -->
          <div class="row justify-content-center">
            <div class="align-center col-12 col-lg-2 col-md-12 col-sm-12 ml-4">
              <br>
              <h5>
                <i class="fa fa-4x fa-user-check light-green"></i>
              </h5>
            </div>
          </div>
          <div class="card-body">
            <div class="row justify-content-center">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <h5 class="font-30 text-success">
                 Success!
                </h5>
                <h6 class="text-success">
                  Your registration is successful.
                </h6>
                <h6>
                    <a class="text-info" [routerLink]="['/login']">Click here to login</a>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-2 col-xl-2"></div> -->
    </div>
  </div>
  