import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../core/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class SubjectCoursesService {

  constructor(private httpService: HttpService) { }

  submitSubject(subject: any): Observable<any> {
    return this.httpService.post('subject/Add', subject);
  }

  updateSubject(subject: any): Observable<any> {
    return this.httpService.post('subject/Update', subject);
  }

  deleteSubject(subject: any): Observable<any> {
    return this.httpService.post('subject/Delete', subject);
  }

  getSubjects(paginationVM): Observable<any> {
    return this.httpService.post('subject/PagedList', paginationVM);
  }

  getCurrentSubject(subjectId: any): Observable<any> {
    return this.httpService.post('subject/Get', subjectId);
  }

  // Courses
  listBySubjectId(paginationVM): Observable<any> {
    return this.httpService.post('course/PagedListBySubjectId', paginationVM);
  }

  getCourseListBySubjectId(subject: any): Observable<any> {
    return this.httpService.postAnonymous('master/ListCourseBySubjectId', subject);
  }

  submitCourse(course: any): Observable<any> {
    return this.httpService.post('course/Add', course);
  }

  updateCourse(course: any): Observable<any> {
    return this.httpService.post('course/Update', course);
  }

  deleteCourse(course: any): Observable<any> {
    return this.httpService.post('course/Delete', course);
  }
}
