import { Component, OnInit, Renderer2 } from '@angular/core';
import { UserAccount } from 'src/app/shared/models/user-account';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubscriptionService } from 'src/app/admin/subscription/subscription.service';
import { Subscription } from '../../../shared/models/subscription-model';
import { SubscriptionCategory } from '../../../shared/enums/subscription-category.enum';
import { StudentService } from '../../../student/student.service';
import { Router } from '@angular/router';
import { UserSubscription } from 'src/app/shared/models/user-subscription';
import { Payment } from '../../models/payment';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
declare var Stripe: any;
@Component({
  selector: 'app-student-subscription-plans',
  templateUrl: './student-subscription-plans.component.html',
  styleUrls: ['./student-subscription-plans.component.scss'],
})
export class StudentSubscriptionPlansComponent implements OnInit {
  currentUser: UserAccount;
  userSubscription: UserSubscription;
  subscriptionPlanList: Subscription[];
  subscriptionCategory = SubscriptionCategory;
  globalListener: any;
  stripehandler: any;
  tax = 0;
  payment: Payment;
  subscribedPlan: Subscription;
  modalRef: BsModalRef;
  constructor(
    private renderer: Renderer2,
    private spinner: NgxSpinnerService,
    private subscriptionService: SubscriptionService,
    private studentService: StudentService,
    private router: Router,
    private appService: AppService,
    private modalService: BsModalService
  ) {
    this.subscriptionPlanList = new Array<Subscription>();
    this.userSubscription = new UserSubscription();
    this.currentUser = JSON.parse(sessionStorage.getItem('CurrentUser'));
    this.payment = new Payment();
  }

  ngOnInit(): void {
    this.getSubscriptionPlanList();
    this.ischanageQuestionCount();
    this.stripehandler = Stripe(
      'pk_live_3AujqhhMDnnbfXZtU5cHffkf'
    );
  }

  ischanageQuestionCount() {
    this.studentService.ischanageQuestionCount$.subscribe((value) => {
      if (value) {
        this.userSubscription = value;
      }
    });
  }

  getSubscriptionPlanList() {
    this.spinner.show();
    const plan = { Category: 1 };
    this.subscriptionService.getSubscriptionListByCategoryId(plan).subscribe(
      (res) => {
        if (!res.HasErrors) {
          this.subscriptionPlanList =
            res.Rows !== null ? res.Rows : new Array<Subscription>();
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  createCheckoutSession(plan) {
    if (plan.Price != 0) {
      const sessionData = {
        SuccessUrl: `${environment.uiUrl}/student/payment-success`,
        CancelUrl: `${environment.uiUrl}/subscription-plan`,
        Customer: {
          Email: this.currentUser.Email,
          Name: this.currentUser.FirstName + ' ' + this.currentUser.Email,
        },
        Price: { PriceId: plan.StripeSubscriptionPlanId },
      };
      this.appService.createSession(sessionData).subscribe((res: any) => {
        if (res) {
          if (!res.HasErrors) {
            this.appService.setSession(res.sessionId);
            const StripeData = {
              StripeSessionId: res.sessionId,
              subscriptionPlan: plan,
            };
            sessionStorage.setItem(
              'stripesessionData',
              JSON.stringify(StripeData)
            );
            this.stripehandler
              .redirectToCheckout({
                sessionId: res.sessionId,
              })
              .then((result) => this.subscribeNow(result, plan));
          }
        }
      });
    } else {
      let token = null;
      this.subscribeNow(token, plan);
    }
  }

  openCheckout(subscriptionPlan) {
    const handler = (<any>window).StripeCheckout.configure({
      key: 'pk_live_3AujqhhMDnnbfXZtU5cHffkf',
      locale: 'auto',
      token: (token: any) => {
        this.subscribeNow(token, subscriptionPlan);
      },
    });

    handler.open({
      // amount: amount * 100,
      amount:
        (subscriptionPlan.Price * (this.tax / 100) +
          parseFloat(subscriptionPlan.Price)) *
        100,
      image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
      name: 'Ask Real Teachers',
      description: subscriptionPlan.Title,
      allowRememberMe: true,
    });
    this.globalListener = this.renderer.listen('window', 'popstate', () => {
      handler.close();
    });
  }
  subscribeNow(token, subscriptionPlan) {
    let subscriptionPlanObj: any;
    this.spinner.show();
    if (subscriptionPlan.Price != 0) {
      const total =
        subscriptionPlan.Price * (this.tax / 100) +
        parseFloat(subscriptionPlan.Price);
      this.payment = <Payment>{
        Subscription: subscriptionPlan.Title,
        Tax: this.tax,
        PlanPrice: subscriptionPlan.Price,
        TotalAmount: parseFloat(total.toString()),
        Last4Digit: token.card.last4,
        TransactionId: token.card.id,
        StripeJson: JSON.stringify(token),
        CreatedById: this.currentUser.Id,
        ModifiedById: this.currentUser.Id,
        UserAccountId: this.currentUser.Id,
      };
      subscriptionPlanObj = {
        UserDetails: this.currentUser,
        SubscriptionDetails: subscriptionPlan,
        Payment: this.payment,
      };
    } else {
      subscriptionPlanObj = {
        UserDetails: this.currentUser,
        SubscriptionDetails: subscriptionPlan,
        Payment: null,
      };
    }
    this.currentUser.IsFirstLogin = false;
    if (subscriptionPlan.Price == 0) {
      this.currentUser.IsFreePlanAvail = true;
    }
    sessionStorage.setItem('CurrentUser', JSON.stringify(this.currentUser));

    this.studentService.subscribePlan(subscriptionPlanObj).subscribe(
      (res) => {
        if (!res.HasErrors) {
          this.userSubscription = res;
          this.studentService.chanageQuestionCount(this.userSubscription);
          this.studentService.IsUserSubscribePlan.next(true);
          this.router.navigateByUrl('/student/studentDashboard');
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  unsubscribe(template, subscriptionPlan) {
    this.subscribedPlan = subscriptionPlan;
    this.modalRef = this.modalService.show(template, {
      class: 'modal-md',
      backdrop: 'static',
    });
  }

  unSubscribeConfirm() {
    this.spinner.show();
    this.currentUser.IsFirstLogin = false;
    sessionStorage.setItem('CurrentUser', JSON.stringify(this.currentUser));
    const subscriptionPlanObj = {
      UserDetails: this.currentUser,
      SubscriptionDetails: this.subscribedPlan,
    };
    this.studentService.unSubscribePlan(subscriptionPlanObj).subscribe(
      (res) => {
        if (!res.HasErrors) {
          this.userSubscription = res;
          this.studentService.IsUserSubscribePlan.next(false);
          this.studentService.chanageQuestionCount(this.userSubscription);
          this.router.navigate(['/student/payment-cancel']);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  logout() {
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }
}
