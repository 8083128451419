import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "./shared/shared.module";
import { httpInterceptProviders } from "./core/interceptor";
import { PopoverModule } from "ngx-bootstrap/popover";
import { CustomToastComponent } from "./custom-toast/custom-toast.component";
import { UserIdleModule } from "angular-user-idle";
// import { KatexModule } from 'ng-katex';

@NgModule({
  declarations: [AppComponent, CustomToastComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "ngx-quill-universal" }),
    AppRoutingModule,
    CommonModule,
    FormsModule,
    PopoverModule.forRoot(),
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    UserIdleModule.forRoot({ idle: 300, timeout: 150, ping: 60 }),
    ToastrModule.forRoot({
      timeOut: 2500,
      positionClass: "toast-top-center",
      preventDuplicates: true,
      countDuplicates: false,
      progressBar: true,
    }),
  ],
  providers: [httpInterceptProviders, DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
