<div class="outer-div" [class.reg-ste--two]="isOTPSend">
  <div class="row">
    <div
      class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4 text-center"
    >
      <div
        style="
          background: rgb(67 74 82 / 69%);
          border-radius: 5px;
          padding: 10px 0;
        "
      >
        <img src="./assets/img/logo-new.png" height="50" /> <br />
        <span class="logo-text pl-1">Ask Real Teachers, Get Real Answers</span>
      </div>
    </div>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="registration-card login-box-image">
        <div
          class="form-container registration-form p-4 pt-4 registration-container registration-scrollbar"
        >
          <div class="row mb-0">
            <div
              class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form-group mb-0"
            >
              <h4 class="signIn-text text-uppercase float-left">
                Register as a {{registerAs}}
              </h4>
              <button
                *ngIf="isOTPSend"
                class="btn btn-outline-lightning float-right"
                (click)="isOTPSend=false"
              >
                <i class="fa fa-angle-left mr-1"></i> Previous
              </button>
            </div>
            <div
              class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form-group"
            >
              <hr class="sginIn-hr" />
            </div>

            <div
              class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              *ngIf="!isOTPSend"
            >
              <!-- <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="form-group">
                  <label class="required">As</label>
                  <div
                    class="custom-control custom-radio custom-control-inline ml-3"
                  >
                    <input
                      type="radio"
                      class="custom-control-input"
                      id="Student"
                      name="formType"
                      [checked]="!isTeacher"
                      (change)="changeRegistrationType(registrationForm)"
                    />
                    <label class="custom-control-label" for="Student"
                      >Student</label
                    >
                  </div>
                  <div
                    class="custom-control custom-radio custom-control-inline"
                  >
                    <input
                      type="radio"
                      class="custom-control-input"
                      id="Teacher"
                      name="formType"
                      [checked]="isTeacher"
                      (change)="changeRegistrationType(registrationForm)"
                    />
                    <label class="custom-control-label" for="Teacher"
                      >Teacher</label
                    >
                  </div>
                </div>
              </div>
            </div> -->
              <form
                novalidate
                autocomplete="off"
                (ngSubmit)="submitRegistration(registrationForm);isEmailNull=false"
                name="registrationForm"
                #registrationForm="ngForm"
              >
                <!-- <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="form-group">
                    <label class="required">Email</label>
                    <input
                      type="text"
                      class="form-control"
                      required
                      [disabled]="isOTPVerified"
                      [(ngModel)]="registrationVM.UserAccount.Email"
                      [ngClass]="{'is-invalid': Email.invalid && (Email.dirty || Email.touched || registrationForm.submitted) }"
                      [pattern]="constants.EMAIL_REGEXP"
                      [maxlength]="constants.MaxLengthConstants.Email"
                      name="Email"
                      #Email="ngModel"
                      placeholder="Email"
                      (keyup)="isEmailNull=false"
                    />
                    <div class="row mb-3">
                      <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                        <div
                          class="invalid-feedback"
                          [ngStyle]="{display:Email.invalid &&
                  (Email.dirty || Email.touched || registrationForm.submitted) ? 'block': 'none'}"
                        >
                          <div [hidden]="!Email?.errors?.required">
                            <span class="fa fa-exclamation-circle"></span> Email
                            is required
                          </div>
                          <div [hidden]="!Email?.errors?.pattern">
                            Enter valid Email
                          </div>
                        </div>
                        <div
                          class="invalid-feedback"
                          style="display: block;"
                          *ngIf="isEmailNull"
                        >
                          <div>
                            <span class="fa fa-exclamation-circle"></span> Email
                            is required
                          </div>
                        </div>
                      </div>
                      <div
                        class="invalid-feedback color-blue col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                        style="display: inline-block;"
                      >
                        <span class="float-right pointer">
                          <a (click)="sendOTP()" *ngIf="!isOTPSend">Send OTP</a>
                          <a
                            (click)="sendOTP()"
                            *ngIf="isOTPSend && !isOTPVerified"
                            >Resend OTP</a
                          >
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <hr class="mt-0" /> -->
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="form-group">
                      <label class="required">First Name</label>
                      <input
                        type="text"
                        class="form-control"
                        required
                        [(ngModel)]="registrationVM.UserAccount.FirstName"
                        [ngClass]="{'is-invalid': FirstName.invalid && (FirstName.dirty || FirstName.touched || registrationForm.submitted) }"
                        [pattern]="constants.NAME_REGEXP"
                        [maxlength]="constants.MaxLengthConstants.Name"
                        #FirstName="ngModel"
                        name="FirstName"
                        placeholder="First Name"
                        autocomplete="off"
                      />
                      <div
                        class="invalid-feedback"
                        [ngStyle]="{display:FirstName.invalid &&
                  (FirstName.dirty || FirstName.touched || registrationForm.submitted) ? 'block': 'none'}"
                      >
                        <div [hidden]="!FirstName?.errors?.required">
                          <span class="fa fa-exclamation-circle"></span> First
                          Name is required
                        </div>
                        <div [hidden]="!FirstName?.errors?.pattern">
                          Enter valid First Name
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="form-group">
                      <label class="required">Last Name</label>
                      <input
                        type="text"
                        class="form-control"
                        required
                        [(ngModel)]="registrationVM.UserAccount.LastName"
                        [ngClass]="{'is-invalid': LastName.invalid && (LastName.dirty || LastName.touched || registrationForm.submitted) }"
                        [pattern]="constants.NAME_REGEXP"
                        [maxlength]="constants.MaxLengthConstants.Name"
                        name="LastName"
                        #LastName="ngModel"
                        placeholder="Last Name"
                        autocomplete="off"
                      />
                      <div
                        class="invalid-feedback"
                        [ngStyle]="{display:LastName.invalid &&
                  (LastName.dirty || LastName.touched || registrationForm.submitted) ? 'block': 'none'}"
                      >
                        <div [hidden]="!LastName?.errors?.required">
                          <span class="fa fa-exclamation-circle"></span> Last
                          Name is required
                        </div>
                        <div [hidden]="!LastName?.errors?.pattern">
                          Enter valid Last Name
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="form-group">
                      <label class="required">Mobile Number</label>
                      <input
                        type="text"
                        class="form-control"
                        required
                        [(ngModel)]="registrationVM.UserAccount.Phone"
                        [ngClass]="{'is-invalid': Mobile.invalid && (Mobile.dirty || Mobile.touched || registrationForm.submitted) }"
                        [pattern]="constants.PHONE_REGEXP"
                        name="Mobile"
                        #Mobile="ngModel"
                        [maxLength]="10"
                        placeholder="Mobile Number"
                        [appOnlyNumberDirective]="true"
                        autocomplete="off"
                      />
                      <div
                        class="invalid-feedback"
                        [ngStyle]="{display:Mobile.invalid &&
                  (Mobile.dirty || Mobile.touched || registrationForm.submitted) ? 'block': 'none'}"
                      >
                        <div [hidden]="!Mobile?.errors?.required">
                          <span class="fa fa-exclamation-circle"></span> Mobile
                          number is required
                        </div>
                        <div [hidden]="!Mobile?.errors?.pattern">
                          Enter valid Mobile number
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="form-group">
                      <label class="required">Email</label>
                      <input
                        type="text"
                        class="form-control"
                        required
                        [(ngModel)]="registrationVM.UserAccount.Email"
                        [pattern]="constants.EMAIL_REGEXP"
                        [ngClass]="{'is-invalid': Email.invalid && (Email.dirty || Email.touched ||registrationForm.submitted)}"
                        name="Email"
                        #Email="ngModel"
                        placeholder="Email"
                        autocomplete="off"
                      />
                      <div
                        class="invalid-feedback"
                        [ngStyle]="{display:Email.invalid &&
                          (Email.dirty || Email.touched || registrationForm.submitted) ? 'block': 'none'}"
                      >
                        <div [hidden]="!Email?.errors?.required">
                          <span class="fa fa-exclamation-circle"></span> Email
                          is required
                        </div>
                        <div [hidden]="!Email?.errors?.pattern">
                          Enter valid Email
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="form-group">
                      <label class="required">Address</label>
                      <input
                        type="text"
                        class="form-control"
                        required
                        [(ngModel)]="registrationVM.UserAccount.Address"
                        [ngClass]="{'is-invalid': Address.invalid && (Address.dirty || Address.touched || registrationForm.submitted) }"
                        [pattern]="constants.ADDRESS_REGEXP"
                        name="Address"
                        #Address="ngModel"
                        placeholder="Address"
                        [maxlength]="250"
                        autocomplete="off"
                      />
                      <div
                        class="invalid-feedback"
                        [ngStyle]="{display:Address.invalid &&
                      (Address.dirty || Address.touched || registrationForm.submitted) ? 'block': 'none'}"
                      >
                        <div [hidden]="!Address?.errors?.required">
                          <span class="fa fa-exclamation-circle"></span> Address
                          is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="form-group">
                      <label>City</label>
                      <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="registrationVM.UserAccount.City"
                        [ngClass]="{'is-invalid': City.invalid && (City.dirty || City.touched || registrationForm.submitted) }"
                        [pattern]="constants.CITY"
                        name="City"
                        #City="ngModel"
                        placeholder="City"
                        autocomplete="off"
                      />
                      <div
                        class="invalid-feedback"
                        [ngStyle]="{display:City.invalid &&
                  (City.dirty || City.touched || registrationForm.submitted) ? 'block': 'none'}"
                      >
                        <div [hidden]="!City?.errors?.pattern">
                          Enter valid City
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div
                    class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                    *ngIf="stateList.length !== 0"
                  >
                    <div class="form-group">
                      <label>State</label>
                      <select
                        class="form-control"
                        [(ngModel)]="registrationVM.UserAccount.State"
                        [ngClass]="{'is-invalid': SelectState.invalid && (SelectState.dirty || SelectState.touched || registrationForm.submitted) }"
                        name="SelectState"
                        #SelectState="ngModel"
                        autocomplete="off"
                      >
                        <option [ngValue]="null" selected disabled
                          >Select State</option
                        >
                        <option value="Outside USA">Outside USA </option>
                        <option
                          *ngFor="let item of stateList"
                          [ngValue]="item.Name"
                          >{{item.Name}}</option
                        >
                      </select>
                    </div>
                  </div>

                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="form-group">
                      <label>Zip</label>
                      <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="registrationVM.UserAccount.Zip"
                        name="Zip"
                        maxlength="5"
                        #Zip="ngModel"
                        placeholder="Zip"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="form-group">
                      <label class="required">Country</label>
                      <select
                        class="form-control"
                        required
                        [(ngModel)]="registrationVM.UserAccount.Country"
                        [ngClass]="{'is-invalid': Country.invalid && (Country.dirty || Country.touched || registrationForm.submitted) }"
                        name="Country"
                        #Country="ngModel"
                        autocomplete="off"
                      >
                        <option [value]="null" selected disabled
                          >Select Country</option
                        >
                        <option
                          *ngFor="let item of countryList"
                          [value]="item.Name"
                          >{{item.Name}}</option
                        >
                      </select>

                      <div
                        class="invalid-feedback"
                        [ngStyle]="{display:Country.invalid &&
                  (Country.dirty || Country.touched || registrationForm.submitted) ? 'block': 'none'}"
                      >
                        <div [hidden]="!Country?.errors?.required">
                          <span class="fa fa-exclamation-circle"></span> Country
                          is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="!isTeacher"
                    class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                  >
                    <div class="form-group">
                      <label class="required">Grades</label>
                      <select
                        required
                        class="form-control"
                        name="Grade"
                        id="Grade"
                        #Grade="ngModel"
                        [(ngModel)]="registrationVM.UserAccount.GradeId"
                        autocomplete="off"
                      >
                        <option [value]="null" disabled selected
                          >Select Grade</option
                        >
                        <option
                          *ngFor="let item of gradesList"
                          [value]="item.Id"
                          >{{item.Name}}</option
                        >
                      </select>
                      <div
                        class="invalid-feedback"
                        [ngStyle]="{display:Grade.invalid &&
                          (Grade.dirty || Grade.touched || registrationForm.submitted) ? 'block': 'none'}"
                      >
                        <div [hidden]="!Grade?.errors?.required">
                          <span class="fa fa-exclamation-circle"></span> Grade
                          is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="isTeacher"
                    class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                  >
                    <div class="form-group">
                      <label>Teacher Type</label>
                      <select
                        class="form-control"
                        id="teacherType"
                        name="teacherType"
                        #teacherType
                        [(ngModel)]="registrationVM.Teacher.TeacherType"
                        autocomplete="off"
                      >
                        <option value="Qualified" selected
                          >I Am Qualified Teacher.</option
                        >
                        <option value="Assistant"
                          >I Am Teacher Assistant.</option
                        >
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div
                    *ngIf="!isTeacher"
                    class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                  >
                    <div class="form-group">
                      <label>School Subscription Code</label>
                      <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="registrationVM.SchoolSubscriptionCode"
                        [ngClass]="{'is-invalid': SchoolSubscriptionCode.invalid && (SchoolSubscriptionCode.dirty || SchoolSubscriptionCode.touched || registrationForm.submitted) }"
                        name="SchoolSubscriptionCode"
                        #SchoolSubscriptionCode="ngModel"
                        placeholder="School subscription code"
                        (ngModelChange)="getSchools(registrationVM.SchoolSubscriptionCode)"
                        autocomplete="off"
                      />

                      <!-- <div
                        class="invalid-feedback"
                        [ngStyle]="{display:SchoolSubscriptionCode.invalid &&
                  (SchoolSubscriptionCode.dirty || SchoolSubscriptionCode.touched || registrationForm.submitted) ? 'block': 'none'}"
                      >
                        <div
                          [hidden]="!SchoolSubscriptionCode?.errors?.pattern"
                        >
                          Enter valid SchoolSubscriptionCode
                        </div>
                      </div> -->
                    </div>
                  </div>
                  <div
                    *ngIf="!isTeacher && schoolList.length === 0"
                    class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                  >
                    <div class="form-group">
                      <label>School Name</label>
                      <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="registrationVM.UserAccount.SchoolName"
                        [pattern]="constants.SCHOOL_NAME_REGEXP"
                        name="SchoolName"
                        #SchoolName="ngModel"
                        placeholder="School Name"
                        autocomplete="off"
                      />

                      <div
                        class="invalid-feedback"
                        [ngStyle]="{display:SchoolName.invalid &&
              (SchoolName.dirty || SchoolName.touched || registrationForm.submitted) ? 'block': 'none'}"
                      >
                        <div [hidden]="!SchoolName?.errors?.pattern">
                          Enter valid school name
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="!isTeacher && schoolList.length !== 0"
                    class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                  >
                    <div class="form-group">
                      <label class="required">School Name</label>
                      <select
                        required
                        class="form-control"
                        name="SchoolNameSelection"
                        id="SchoolNameSelection"
                        #SchoolNameSelection="ngModel"
                        [(ngModel)]="registrationVM.UserAccount.SchoolId"
                        (change)="getSchoolName($event)"
                        autocomplete="off"
                      >
                        <option [value]="null" disabled selected
                          >Select School</option
                        >
                        <option
                          *ngFor="let item of schoolList"
                          [value]="item.Id"
                          >{{item.Name}}</option
                        >
                      </select>
                      <div
                        class="invalid-feedback"
                        [ngStyle]="{display:SchoolNameSelection.invalid &&
                          (SchoolNameSelection.dirty || SchoolNameSelection.touched || registrationForm.submitted) ? 'block': 'none'}"
                      >
                        <div [hidden]="!SchoolNameSelection?.errors?.required">
                          <span class="fa fa-exclamation-circle"></span> School
                          is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="isTeacher"
                    class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                  >
                    <div class="form-group">
                      <label class="required">Subjects</label>
                      <div class="multi-checkbox">
                        <p
                          class="custom-control custom-checkbox mb-0"
                          *ngFor="let item of subjectList;let i=index"
                        >
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            value="subject-{{item.Id}}"
                            id="subject-{{item.Id}}"
                            name="subject-{{item.Id}}"
                            [(ngModel)]="item.IsChecked"
                            (change)="getCoursesById(item);isEmptySubjects = false"
                          /><label
                            class="custom-control-label"
                            for="subject-{{item.Id}}"
                            >{{item.Name}}</label
                          >
                        </p>
                      </div>
                      <div class="invalid-feedback" style="display: block;">
                        <div *ngIf="isEmptySubjects">
                          <span class="fa fa-exclamation-circle"></span>
                          Subjects are required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="isTeacher"
                    class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                  >
                    <div class="form-group">
                      <label class="required">Course(s)</label>
                      <div class="multi-checkbox">
                        <p
                          class="custom-control custom-checkbox mb-0"
                          *ngFor="let item of courseList;let i=index"
                        >
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            value="course-{{item.Id}}"
                            id="course-{{item.Id}}" 
                            name="course-{{item.Id}}"
                            [(ngModel)]="item.IsChecked"
                            (change)="isEmptyCourses = false"
                          /><label
                            class="custom-control-label"
                            for="course-{{item.Id}}"
                            >{{item.Name}}</label
                          >
                        </p>
                      </div>
                      <div class="invalid-feedback" style="display: block;">
                        <div *ngIf="isEmptyCourses">
                          <span class="fa fa-exclamation-circle"></span> Select
                          atleast one course for selected subject
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="isTeacher"
                    class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                  >
                    <div class="form-group">
                      <label class="required">Grades</label>
                      <div class="multi-checkbox">
                        <p
                          class="custom-control custom-checkbox mb-0"
                          *ngFor="let item of gradesList;let i=index"
                        >
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            value="grade-{{item.Id}}"
                            id="grade-{{item.Id}}"
                            name="grade-{{item.Id}}"
                            [(ngModel)]="item.IsChecked"
                          /><label
                            class="custom-control-label"
                            for="grade-{{item.Id}}"
                            >{{item.Name}}</label
                          >
                        </p>
                      </div>
                      <div class="invalid-feedback" style="display: block;">
                        <div *ngIf="isEmptyGrades">
                          <span class="fa fa-exclamation-circle"></span> Grades
                          are required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="form-group">
                      <label class="required">Password</label
                      ><span
                        class="fa fa-exclamation-circle text-info float-right m-0"
                        containerClass="pop-container"
                        popover=" The password should be 10 to 15 characters
                    long with at least 1 uppercase,1 lowercase, 1
                    special character and 1 number."
                        triggers="mouseenter:mouseleave"
                        placement="auto"
                      ></span>
                      <input
                        type="password"
                        class="form-control"
                        required
                        [(ngModel)]="registrationVM.UserAccount.Password"
                        [ngClass]="{'is-invalid': Password.invalid && (Password.dirty || Password.touched || registrationForm.submitted) }"
                        (ngModelChange)="checkPassword()"
                        name="Password"
                        #Password="ngModel"
                        placeholder="Password"
                        [pattern]="constants.PASSWORD_REGEXP"
                        [maxLength]="15"
                        [minLength]="10"
                      />
                      <div
                        class="invalid-feedback"
                        [ngStyle]="{display:Password.invalid &&
                  (Password.dirty || Password.touched || registrationForm.submitted) ? 'block': 'none'}"
                      >
                        <div [hidden]="!Password?.errors?.required">
                          <span class="fa fa-exclamation-circle"></span>
                          Password is required
                        </div>
                        <div [hidden]="!Password?.errors?.pattern">
                          Enter valid password
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="form-group">
                      <label class="required">Confirm Password</label>
                      <input
                        type="password"
                        class="form-control"
                        required
                        [(ngModel)]="confirmPassword"
                        [ngClass]="{'is-invalid': ConfirmPassword.invalid && (ConfirmPassword.dirty || ConfirmPassword.touched ||registrationForm.submitted)}"
                        name="ConfirmPassword"
                        #ConfirmPassword="ngModel"
                        placeholder="Confirm Password"
                        (ngModelChange)="checkPassword()"
                        [pattern]="constants.PASSWORD_REGEXP"
                        [maxLength]="15"
                        [minLength]="10"
                      />
                      <div
                        class="invalid-feedback"
                        [ngStyle]="{display:ConfirmPassword.invalid &&
                          (ConfirmPassword.dirty || ConfirmPassword.touched || registrationForm.submitted) ? 'block': 'none'}"
                      >
                        <div [hidden]="!ConfirmPassword?.errors?.required">
                          <span class="fa fa-exclamation-circle"></span>Confirm
                          Password is required
                        </div>
                      </div>
                      <div
                        *ngIf="!checkPassword() && !ConfirmPassword?.errors?.required && !ConfirmPassword?.errors?.pattern"
                        class="invalid-feedback"
                        style="display: block;"
                      >
                        <span class="fa fa-exclamation-circle"></span> Password
                        mismatch
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="form-group">
                      <input
                        type="checkbox"
                        required
                        [(ngModel)]="registrationVM.IsCheckPolicy"
                        name="Policy"
                        #Policy="ngModel"
                        [ngClass]="{'is-invalid': Policy.invalid && (Policy.dirty || Policy.touched || registrationForm.submitted) }"
                      />
                      <a
                        class="pl-1 font-weight-bold required"
                        routerLink="/privacyPolicy"
                        target="_blank"
                        >I Agree to the terms of service and privacy policy.</a
                      >
                      <div
                        class="invalid-feedback"
                        [ngStyle]="{display:Policy.invalid &&
                  (Policy.dirty || Policy.touched || registrationForm.submitted) ? 'block': 'none'}"
                      >
                        <div [hidden]="!Policy?.errors?.required">
                          <span class="fa fa-exclamation-circle"></span> To
                          register you need to accept our terms of service and
                          privacy policies.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                    *ngIf="isTeacher"
                  >
                    <div class="form-group">
                      <input
                        type="checkbox"
                        required
                        [(ngModel)]="registrationVM.IsCheckTeacherPolicy"
                        name="TeacherPolicy"
                        #TeacherPolicy="ngModel"
                        [ngClass]="{'is-invalid': TeacherPolicy.invalid && (TeacherPolicy.dirty || TeacherPolicy.touched || registrationForm.submitted) }"
                      />
                      <a
                        class="pl-1 font-weight-bold required"
                        routerLink="/teacherTermsCondition"
                        target="_blank"
                        >I Agree to read the teacher code of conduct.</a
                      >
                      <div
                        class="invalid-feedback"
                        [ngStyle]="{display:TeacherPolicy.invalid &&
                  (TeacherPolicy.dirty || TeacherPolicy.touched || registrationForm.submitted) ? 'block': 'none'}"
                      >
                        <div [hidden]="!TeacherPolicy?.errors?.required">
                          <span class="fa fa-exclamation-circle"></span> To
                          register you need to accept our teacher code of
                          conduct.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <button
                      type="reset"
                      class="btn btn-secondary mb-2 float-right ml-2"
                      (click)="resetForm(registrationForm)"
                    >
                      Reset
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary mb-2 float-right"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div class="form-group font-weight-bold text-center">
                    <span
                      >Already have an account?
                      <a routerLink="/login" class="color-blue">
                        Login Here</a
                      ></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ml-0 pl-0"
            *ngIf="isOTPSend"
          >
            <form
              novalidate
              autocomplete="off"
              (ngSubmit)="registration(registrationVerificationForm)"
              #registrationVerificationForm="ngForm"
            >
              <div
                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ml-0 pl-0"
              >
                <div class="form-group">
                  <p>
                    A (OTP) one time password has been sent to your registered
                    email id and phone no. Use the OTP to validate your email id
                    and phone no. and, complete your registration.
                  </p>
                </div>
              </div>
              <div
                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ml-0 pl-0"
              >
                <div class="form-group">
                  <label class="mb-0">Name: </label>&nbsp;
                  <p>
                    <strong class="color-blue"
                      >{{registrationVM.UserAccount.FirstName}}&nbsp;{{registrationVM.UserAccount.LastName}}</strong
                    >
                  </p>
                </div>
              </div>
              <div
                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ml-0 pl-0"
              >
                <div class="form-group">
                  <label class="mb-0">Email: </label>&nbsp;
                  <p>
                    <strong class="color-blue"
                      >{{registrationVM.UserAccount.Email}}</strong
                    >
                  </p>
                </div>
              </div>

              <div
                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ml-0 pl-0"
              >
                <div class="form-group mb-0">
                  <label class="required">Email OTP (One Time Password)</label>
                  <input
                    type="text"
                    [disabled]="isOTPVerified"
                    class="form-control"
                    required
                    [maxLength]="8"
                    [(ngModel)]="registrationOTPForEmail"
                    [ngClass]="{'is-invalid ': OTPForEmail.invalid && (OTPForEmail.dirty || OTPForEmail.touched || registrationVerificationForm.submitted) }"
                    name="registrationOTPForEmail"
                    [appOnlyNumberDirective]="true"
                    #OTPForEmail="ngModel"
                    placeholder="Email OTP"
                  />

                  <div class="row mb-3">
                    <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                      <div
                        class="invalid-feedback"
                        [ngStyle]="{display:OTPForEmail.invalid &&
            (OTPForEmail.dirty || OTPForEmail.touched || registrationVerificationForm.submitted) ? 'block': 'none'}"
                      >
                        <div *ngIf="OTPForEmail?.errors?.required">
                          <span class="fa fa-exclamation-circle"></span> Email
                          OTP is required
                        </div>
                      </div>
                    </div>
                    <div
                      class="invalid-feedback color-blue col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                      style="display: inline-block;"
                    ></div>
                  </div>
                </div>
              </div>

              <div
                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ml-0 pl-0"
              >
                <div class="form-group">
                  <label class="mb-0">Phone Number: </label>&nbsp;
                  <p>
                    <strong class="color-blue"
                      >{{registrationVM.UserAccount.Phone}}</strong
                    >
                  </p>
                </div>
              </div>
              <div
                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ml-0 pl-0"
              >
                <div class="form-group mb-0">
                  <label class="required">Phone OTP (One Time Password)</label>
                  <input
                    type="text"
                    [disabled]="isOTPVerified"
                    class="form-control"
                    required
                    [maxLength]="8"
                    [(ngModel)]="registrationOTPForMobile"
                    [ngClass]="{'is-invalid ': OTPForMobile.invalid && (OTPForMobile.dirty || OTPForMobile.touched || registrationVerificationForm.submitted) }"
                    name="registrationOTPForMobile"
                    [appOnlyNumberDirective]="true"
                    #OTPForMobile="ngModel"
                    placeholder="Phone OTP"
                  />

                  <div class="row mb-3">
                    <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                      <div
                        class="invalid-feedback"
                        [ngStyle]="{display:OTPForMobile.invalid &&
            (OTPForMobile.dirty || OTPForMobile.touched || registrationVerificationForm.submitted) ? 'block': 'none'}"
                      >
                        <div *ngIf="OTPForMobile?.errors?.required">
                          <span class="fa fa-exclamation-circle"></span> Phone
                          OTP is required
                        </div>
                      </div>
                    </div>
                    <div
                      class="invalid-feedback color-blue col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                      style="display: inline-block;"
                    ></div>
                  </div>
                </div>
              </div>

              <div
                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ml-0 pl-0"
              >
                <div class="form-group">
                  <span class="float-right pointer">
                    <a (click)="sendOTP()">Resend OTP</a>
                  </span>
                </div>
              </div>

              <div
                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 float-right mt-5"
              >
                <button
                  type="reset"
                  class="btn btn-secondary mb-2 float-right ml-2"
                  (click)="resetForm(registrationVerificationForm);isOTPSend=false"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary mb-2 float-right">
                  Register
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
