import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserAccount } from 'src/app/shared/models/user-account';
import { AppService } from 'src/app/services/app.service';
import { Spinner, NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserRole } from 'src/app/shared/models/user-role';
import { Router } from '@angular/router';
import { Constants } from 'src/app/shared/models/constants';
import { retry } from 'rxjs/operators';

@Component({
  selector: 'app-changeemail',
  templateUrl: './changeemail.component.html',
  styleUrls: ['./changeemail.component.scss']
})
export class ChangeemailComponent implements OnInit {

  constructor(private appService:AppService,private spinner:NgxSpinnerService,private toastr:ToastrService,private router:Router) { 
    this.constants = Constants;
    this.userRole = new UserRole();
  }
  @ViewChild("changeEmailForm") changeEmailForm:NgForm;
  constants: any;
  MobileOtp:string;
  EmailOtp:string;
  isOTPSend = false;
  isEmailNull = true;

  newEmail = null;
  isOTPVerified = false;
  bsModalRef:BsModalRef;
  userAccount:UserAccount;
  userRole:UserRole;

  ngOnInit(): void {
    this.userAccount = JSON.parse(sessionStorage.getItem('CurrentUser'));
    this.getCurrentUser(this.userAccount.Id);
    this.getRole();
  }

  sendOTP(isResentOtp) {
   if(this.changeEmailForm.valid || isResentOtp){
    if (this.newEmail !== null) {
      if (this.userAccount.Email.localeCompare(this.newEmail)) {
        const user = {
          Email: this.newEmail,
          UserDetails: this.userAccount,
        };
        this.spinner.show();
        this.appService.sendEmailOTP(user).subscribe(
          (res) => {
            if (!res.HasErrors) {
              this.isOTPSend = true;
              this.toastr.success(
                'OTP has been successfully sent to your email.',
                'Success'
              );
              this.spinner.hide();
            } else {
              if (res.Errors !== undefined) {
                this.toastr.error(res.Errors[0].Message, 'Error');
              }
              this.spinner.hide();
            }
          },
          (err) => {
            this.spinner.show();
          }
        );
      }
      else{
        this.toastr.error("You Entered same email, Please enter different Email.");
      }
    } else {
      this.isEmailNull = true;
    }
   }
   else{
     return;
   }
  }

  getRole() {
    const roleObj = { RoleId: this.userAccount.UserRoleId };
    this.appService.getRoleById(roleObj).subscribe(
      (res) => {
        if (!res.HasErrors) {
          this.userRole = res;
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  verifyOTP(sendOTPForm:NgForm) {
    if(sendOTPForm.valid){
      this.spinner.show();
      const userDetails = {
        Email: this.newEmail,
        OTP: this.EmailOtp,
        UserDetails:this.userAccount
      };
      this.appService.ChangeEmail(userDetails).subscribe(
        (res) => {
          if (!res.HasErrors) {
            this.userAccount =
              res !== undefined && res !== null ? res : new UserAccount();
              if(sessionStorage.getItem("CurrentUser")!=null){
                sessionStorage.removeItem("CurrentUser");
                sessionStorage.setItem("CurrentUser",JSON.stringify(this.userAccount));
              }
            this.isOTPVerified = true;
            this.toastr.success('Change Email successfully.');
            if (this.userRole.Prefix === "TR") {
              if (!this.userAccount.IsApproved) {
                this.router.navigate(["/teacher/approve"]);
              } else {
                this.router.navigate(["/teacher/teacherDashboard"]);
              }
            }
            else if(this.userRole.Prefix == "ST"){
              this.router.navigate(['/student/studentDashboard']);
            }
            else if(this.userRole.Prefix == "AD"){
              this.router.navigate(['/admin/dashboard']);
            }
            this.spinner.hide();
          } else {

            this.toastr.error(res.Errors[0].Message, 'Error');
            this.spinner.hide();
          }
        },
        (err) => {
          this.spinner.hide();
        }
      );
    }
  }

  getCurrentUser(currentUserId) {
    this.spinner.show();
    const user = { Id: currentUserId };
    this.appService.getUser(user).subscribe(
      (res) => {
        if (!res.HasErrors) {
          this.userAccount = res;
          this.userAccount.DateOfBirth = new Date(this.userAccount.DateOfBirth);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  goBack() {
    this.appService.goBack();
  }

}
