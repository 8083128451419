<img
  #profilePic
  class="rounded-circle"
  [class.question-grid-img-avtar]="type === 'thumb'"
  [class.img-fluid]="type !== 'thumb'"
  [src]="dmsDocument.Base64Url ? getSantizeUrl(dmsDocument.Base64Url): './assets/img/default-user.png'"
  alt=""
/>
<!-- <img
  *ngIf="dmsDocument.Base64Url === null && documentId !== null"
  #profilePic
  class="rounded-circle"
  [class.question-grid-img-avtar]="type === 'thumb'"
  [class.img-fluid]="type !== 'thumb'"
  src="./assets/img/default-user.png"
  alt=""
/> -->
<!-- *ngIf="!dmsDocument.Base64Url && (dmsDocument.Base64Url !== null || dmsDocument.Base64Url !== undefined)  && documentId !== null" -->
<!-- <i
  *ngIf=" !dmsDocument.Base64Url && documentId !== null"
  class="fa fa-spinner fa-2x fa-fw text-black-50"
  [class.fa-pulse]="!dmsDocument.Base64Url"
></i> -->
<!-- <pre>{{dmsDocument.Base64Url | json}}</pre> -->
