<div class="wrapper default-theme bg-white">
  <nav class="navbar navbar-expand-md fixed-top navbar-dark flex-fill">
    <!-- header -->
    <div class="navbar-header pl-2 pr-2 ml-3 text-center" >
      <a class="navbar-brand m-0 w-100" href="#">
        <!-- <i class="fa fa-flash mr-1"></i> -->
        <img src="./assets/img/logo-new.png" alt="ART logo" width="35px" />
        <span class="ml-1">Ask Real Teachers</span>
      </a>
    </div>
    <!-- right menu -->
    <div class="collapse navbar-collapse right-nav" id="collapseBasic">
      <ul class="navbar-nav">
        <li class="nav-item mr-4">
          <button class="btn btn-primary" (click)="logout()">Logout</button>
        </li>
      </ul>
    </div>
  </nav>

  <main>
    <div class="pages w-100">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <section>
            <div class="container-fluid">
              <div class="container">
                <div class="row">
                  <div
                    class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-5 background-header"
                  >
                    <div class="row">
                      <div
                        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                      >
                      <h2>Get Better Grades. Join Now.</h2>
                      <h5>
                        Join now and get 24/7 access to teachers in 40+ subjects.
                      </h5>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-6"
                    *ngFor="let item of subscriptionPlanList;let i=index"
                  >
                    <div class="card text-center card-responsive"
                    style="width: 100%;height: 100%;">
                      <div class="title">
                        <i
                          class="fa"
                          [class.fa-paper-plane]="i===0"
                          [class.fa-plane]="i===1"
                          [class.fa-rocket]="i===2"
                          [class.fa-space-shuttle]="i===3"
                          aria-hidden="true"
                        ></i>
                        <h2>{{item.Title}}</h2>
                      </div>
                      <div class="price">
                        <h4><sup>$</sup>{{item.Price}}</h4>
                      </div>
                      <div class="option">
                        <ul>
                          <li>
                            <i class="fa fa-check" aria-hidden="true"></i>
                            <!-- {{userSubscription?.SubscriptionId === item.Id? (
                            userSubscription.QuestionLimit === -1 ? 'Unlimited':
                            userSubscription.QuestionLimit) : ( tem.QuestionLimit ===
                            -1 ? 'Unlimited': item.QuestionLimit)}} Questions -->
                            {{item.QuestionLimit == -1?'Unlimited' : item.QuestionLimit}}
                          </li>
                          <li>
                            <i class="fa fa-check" aria-hidden="true"></i>For
                            {{userSubscription !== null ? userSubscription?.Duration :
                            item.Duration}} Month
                          </li>
                          <li>
                            <i class="fa fa-check" aria-hidden="true"></i> For
                            {{subscriptionCategory[item.Category]}}
                          </li>
                        </ul>
                      </div>
                      <a (click)="createCheckoutSession(item)">Subscribe Now </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
</div>

<ng-template #unsubscribeTemplate>
  <div class="modal-body">
    <div class="row p-4">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
        Are you sure you want to unsubscribe
        <strong class="color-blue"
          >{{userAccount.FirstName}}&nbsp;{{userAccount.LastName}}</strong
        >?<br />
      </div>
    </div>
    <div class="align-content-center row">
      <div
        class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 text-center break-all-word"
      >
        <button
          class="btn btn-primary mr-2"
          type="button"
          (click)="unSubscribeConfirm(); modalRef.hide()"
        >
          YES
        </button>
        <button
          class="btn btn-secondary"
          type="button"
          (click)="modalRef.hide()"
        >
          NO
        </button>
      </div>
    </div>
  </div>
</ng-template>
