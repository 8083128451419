import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

declare var tinymce: any;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

// tinymce.overrideDefaults({
//   base_url: "/assets/tinymce/", // Base for assets such as skins, themes and plugins
//   suffix: ".min", // This will make Tiny load minified versions of all its assets
// });
