<div class="wrapper default-theme bg-white">
  <nav class="navbar navbar-expand-md fixed-top navbar-dark flex-fill">
    <!-- header -->
    <div class="navbar-header pl-2 pr-2 ml-3 text-center">
      <a class="navbar-brand m-0 w-100" href="#">
        <!-- <i class="fa fa-flash mr-1"></i> -->
        <img src="./assets/img/logo-new.png" alt="ART logo" width="35px" />
        <span class="ml-1">Ask Real Teachers</span>
      </a>
    </div>
  </nav>

  <main>
    <div class="pages" style="overflow-x: hidden;">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2"></div>
        <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
          <div class="text-center pt-2 pb-2">
            <h4>PRIVACY POLICY</h4>
          </div>
        </div>
        <div class="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2 pt-2">
          <!-- <button class="btn btn-outline-lightning float-left" (click)="goBack()">
            <i class="fa fa-angle-left mr-1"></i>Back
          </button> -->
        </div>
      </div>
      <div class="row">
        <div class="col-1 col-sm-1 col-md-2 col-lg-2 col-xl-2"></div>
        <div class="col-10 col-sm-10 col-md-8 col-lg-8 col-xl-8">
          <hr class="mt-0" />
          <span>
            This privacy policy has been compiled to better serve those who are
            concerned with how their 'Personally Identifiable Information' (PII)
            is being used online. PII, as described in US privacy law and
            information security, is information that can be used on its own or
            with other information to identify, contact, or locate a single
            person, or to identify an individual in context. Please read our
            privacy policy carefully to get a clear understanding of how we
            collect, use, protect or otherwise handle your Personally
            Identifiable Information in accordance with our website.<br /><br />

            <strong
              >What personal information do we collect from the people that
              visit our blog, website or app?</strong
            ><br />

            When ordering or registering on our site, as appropriate, you may be
            asked to enter your name, email address, mailing address, phone
            number, Certificates or other details to help you with your
            experience.<br /><br />

            <strong>When do we collect information?</strong><br />

            We collect information from you when you register on our site or
            enter information on our site<br /><br />

            <strong>How do we use your information?</strong><br />

            We may use the information we collect from you when you register,
            make a purchase, sign up for our newsletter, respond to a survey or
            marketing communication, surf the website, or use certain other site
            features in the following ways:<br /><br />

            <ul>
              <li>
                To personalize your experience and to allow us to deliver the
                type of content and product offerings in which you are most
                interested
              </li>
              <li>
                To allow us to better service you in responding to your customer
                service requests.
              </li>
              <li>
                To administer a contest, promotion, survey or other site
                feature.
              </li>
              <li>
                To ask for ratings and reviews of services or products
              </li>
              <li>
                To follow up with them after correspondence (live chat, email or
                phone inquiries)
              </li>
            </ul>
            <strong>How do we protect your information?</strong><br />

            Our website is scanned on a regular basis for security holes and
            known vulnerabilities in order to make your visit to our site as
            safe as possible We use regular Malware Scanning Your personal
            information is contained behind secured networks and is only
            accessible by a limited number of persons who have special access
            rights to such systems, and are required to keep the information
            confidential. In addition, all sensitive/credit information you
            supply is encrypted via Secure Socket Layer (SSL) technology We
            implement a variety of security measures when a user enters,
            submits, or accesses their information to maintain the safety of
            your personal information All transactions are processed through a
            gateway provider and are not stored or processed on our servers<br /><br />

            <strong>Do we use 'cookies'?</strong><br />

            Yes. Cookies are small files that a site or its service provider
            transfers to your computer's hard drive through your Web browser (if
            you allow) that enables the site's or service provider's systems to
            recognize your browser and capture and remember certain information.
            For instance, we use cookies to help us remember and process the
            items in your shopping cart. They are also used to help us
            understand your preferences based on previous or current site
            activity, which enables us to provide you with improved services. We
            also use cookies to help us compile aggregate data about site
            traffic and site interaction so that we can offer better site
            experiences and tools in the future.<br /><br />

            <strong>We use cookies to:</strong><br /><br />
            <ul>
              <li>
                Understand and save user's preferences for future visits.
              </li>
              <li>
                Keep track of advertisements
              </li>
              <li>
                Compile aggregate data about site traffic and site interactions
                in order to offer better site experiences and tools in the
                future. We may also use trusted third-party services that track
                this information on our behalf
              </li>
            </ul>

            You can choose to have your computer warn you each time a cookie is
            being sent, or you can choose to turn off all cookies. You do this
            through your browser settings. Since browser is a little different,
            look at your browser's Help Menu to learn the correct way to modify
            your cookies.<br />

            If you turn cookies off, some features will be disabled. It won't
            affect the user's experience that make your site experience more
            efficient and may not function properly.<br />

            However, you will still be able to place orders<br /><br />

            <strong>Third-party disclosure</strong><br />

            We do not sell, trade, or otherwise transfer to outside parties your
            Personally Identifiable Information unless we provide users with
            advance notice. This does not include website hosting partners and
            other parties who assist us in operating our website, conducting our
            business, or serving our users, so long as those parties agree to
            keep this information confidential. We may also release information
            when it's release is appropriate to comply with the law, enforce our
            site policies, or protect ours or others' rights, property or
            safety.<br /><br />

            However, non-personally identifiable visitor information may be
            provided to other parties for marketing, advertising, or other
            uses.<br /><br />

            <strong>Third-party links</strong><br />

            Occasionally, at our discretion, we may include or offer third-party
            products or services on our website. These third-party sites have
            separate and independent privacy policies. We therefore have no
            responsibility or liability for the content and activities of these
            linked sites. Nonetheless, we seek to protect the integrity of our
            site and welcome any feedback about these sites.<br /><br />

            <strong>Google</strong><br />

            Google's advertising requirements can be summed up by Google's
            Advertising Principles. They are put in place to provide a positive
            experience for users.<br />
            https://support.google.com/adwordspolicy/answer/1316548?hl=en<br />

            We have not enabled Google AdSense on our site but we may do so in
            the future.<br /><br />

            <strong>California Online Privacy Protection Act</strong><br />

            CalOPPA is the first state law in the nation to require commercial
            websites and online services to post a privacy policy. The law's
            reach stretches well beyond California to require any person or
            company in the United States (and conceivably the world) that
            operates websites collecting Personally Identifiable Information
            from California consumers to post a conspicuous privacy policy on
            its website stating exactly the information being collected and
            those individuals or companies with whom it is being shared.<br />
            - See more at:
            "http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf"<br /><br />

            <strong>According to CalOPPA, we agree to the following:</strong
            ><br />

            Users can visit our site anonymously.<br />
            Once this privacy policy is created, we will add a link to it on our
            home page or as a minimum, on the first significant page after
            entering our website.<br />
            Our Privacy Policy link includes the word 'Privacy' and can easily
            be found on the page specified above.<br /><br />

            You will be notified of any Privacy Policy changes:<br />
            <ul>
              <li>
                On our Privacy Policy Page Can change your personal information
              </li>
              <li>
                By logging in to your account
              </li>
            </ul>
            <br />

            <strong> How does our site handle Do Not Track signals?</strong
            ><br />

            We honor Do Not Track signals and Do Not Track, plant cookies, or
            use advertising when a Do Not Track (DNT) browser mechanism is in
            place.<br /><br />

            <strong>
              Does our site allow third-party behavioral tracking?</strong
            ><br />

            It's also important to note that we do not allow third-party
            behavioral tracking<br /><br />

            <strong> COPPA (Children Online Privacy Protection Act)</strong
            ><br />

            When it comes to the collection of personal information from
            children under the age of 13 years old, the Children's Online
            Privacy Protection Act (COPPA) puts parents in control. The Federal
            Trade Commission, United States' consumer protection agency,
            enforces the COPPA Rule, which spells out what operators of websites
            and online services must do to protect children's privacy and safety
            online.<br />
            We do not specifically market to children under the age of 13 years
            old.<br /><br />

            <strong>Fair Information Practices</strong><br />

            The Fair Information Practices Principles form the backbone of
            privacy law in the United States and the concepts they include have
            played a significant role in the development of data protection laws
            around the globe. Understanding the Fair Information Practice
            Principles and how they should be implemented is critical to comply
            with the various privacy laws that protect personal information.<br /><br />

            <strong>
              In order to be in line with Fair Information Practices we will
              take the following responsive action, should a data breach
              occur:</strong
            ><br />

            We will notify you via email<br />

            <ul>
              <li>
                Within 7 business days
              </li>
            </ul>

            We also agree to the Individual Redress Principle which requires
            that individuals have the right to legally pursue enforceable rights
            against data collectors and processors who fail to adhere to the
            law. This principle requires not only that individuals have
            enforceable rights against data users, but also that individuals
            have recourse to courts or government agencies to investigate and/or
            prosecute non-compliance by data processors.<br /><br />

            <strong>CAN SPAM Act</strong><br />

            The CAN-SPAM Act is a law that sets the rules for commercial email,
            establishes requirements for commercial messages, gives recipients
            the right to have emails stopped from being sent to them, and spells
            out tough penalties for violations.<br /><br />

            <strong> We collect your email address in order to:</strong><br />

            <ul>
              <li>
                Send information, respond to inquiries, and/or other requests or
                questions
              </li>
              <li>
                Market to our mailing list or continue to send emails to our
                clients after the original transaction has occurred.
              </li>
            </ul>

            <strong
              >To be in accordance with CANSPAM, we agree to the
              following:</strong
            ><br />

            <ul>
              <li>
                Not use false or misleading subjects or email addresses.
              </li>
              <li>
                Identify the message as an advertisement in some reasonable way.
              </li>
              <li>
                Include the physical address of our business or site
                headquarters.
              </li>
              <li>
                Monitor third-party email marketing services for compliance, if
                one is used.
              </li>
              <li>
                Honor opt-out/unsubscribe requests quickly.
              </li>
              <li>
                Allow users to unsubscribe by using the link at the bottom of
                each email.
              </li>
            </ul>

            If at any time you would like to unsubscribe from receiving future
            emails, you can email us at <br />

            <ul>
              <li>
                Follow the instructions at the bottom of each email. and we will
                promptly remove you from <strong>ALL</strong> correspondence.
              </li>
            </ul>

            <strong>Contact Us</strong> <br />

            If there are any questions regarding this privacy policy, you may
            contact us using the information below.<br /><br />

            askrealteachers.com<br />
            851 NW 81st Ave<br />
            Plantation, FL 33324<br />
            United States<br />
            support@askrealteachers.com<br />
            <br />
            <br />
          </span>
        </div>
        <div class="col-1 col-sm-1 col-md-2 col-lg-2 col-xl-2"></div>
      </div>
    </div>
  </main>
</div>
