<div class="row pl-3">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <button class="btn btn-outline-lightning float-right" (click)="goBack()">
        <i class="fa fa-angle-left mr-1"></i>Back
      </button>
    </div>
  </div>
  <div class="row pt-3">
    <div class="col-1 col-sm-1 col-md-3 col-lg-4 col-xl-4"></div>
    <div
      class="col-10 col-sm-10 col-md-5 col-lg-4 col-xl-4 mb-4 align-items-stretch"
    >
      <div class="card border-0 h-100">
        <div class="card-title mb-1 p-3">
          <h5>Change Your Email</h5>
        </div>
        <div class="card-body">
                <div class="modal-body">
                    <form
          novalidate
          autocomplete="off"
          (ngSubmit)="sendOTP(false)"
          #changeEmailForm="ngForm"
          [hidden]="isOTPSend"
        >
                  <div class="form-group">
                    <label  class="label" for="">Current Email :</label><br />
                    <label  class="label" for="">{{userAccount.Email}}</label><br>
                    <label class="lable" for="">New Email :</label>

                    <input
                      class="form-control"
                      type="email"
                      placeholder="New Email"
                      [(ngModel)]="newEmail"
                      required
                      [ngClass]="{'is-invalid ': Email.invalid && (Email.dirty || Email.touched  || changeEmailForm.submitted) }"
                      [pattern]="constants.EMAIL_REGEXP"
                      name="newEmail"
                      #Email="ngModel"
                      [disabled]="isOTPSend"
                    />
                    <div
                      class="invalid-feedback"
                      [ngStyle]="{display:Email.invalid &&
                    (Email.dirty || Email.touched || changeEmailForm.submitted) ? 'block': 'none'}"
                    >
                      <div [hidden]="!Email?.errors?.required">
                        <span class="fa fa-exclamation-circle"></span> Email is
                        required
                      </div>
                      <div [hidden]="!Email?.errors?.pattern">
                        Enter valid Email
                      </div>
                    </div>
                    <button *ngIf="!isOTPSend" class="btn btn-primary float-right mt-4" type="submit">
                        Generate OTP
                      </button><br>
                    </div>
                    </form>
                    <form 
                    novalidate
                    autocomplete="off"
                    (ngSubmit)="verifyOTP(sendOTPForm)"
                    #sendOTPForm="ngForm"
                    [hidden]="!isOTPSend">
                    <div *ngIf="isOTPSend">
                      <label class="lable" for="">New Email :</label><br>
                      <label  class="label" for="">{{newEmail}}</label><br>
                      <label for="">Email OTP</label>
                    <input
                      class="form-control"
                      type="text"
                      name="OTP"
                      placeholder="Email OTP"
                      required
                      [maxLength]="8"
                      [(ngModel)]="EmailOtp"
                      [ngClass]="{'is-invalid ': OTP.invalid && (OTP.dirty || OTP.touched  || sendOTPForm.submitted) }"
                      [appOnlyNumberDirective]="true"
                      #OTP="ngModel"
                    />
                    <div
                          class="invalid-feedback"
                          [ngStyle]="{display:OTP.invalid &&
                (OTP.dirty || OTP.touched || sendOTPForm.submitted) ? 'block': 'none'}"
                        >
                          <div [hidden]="!OTP?.errors?.required">
                            <span class="fa fa-exclamation-circle"></span> OTP
                            is required
                          </div>
                        </div>
                    </div>
                    <a *ngIf="isOTPSend" (click)="sendOTP(true)" class="pointer float-left">Resend OTP</a><br>
                    <button type="button" (click)="isOTPSend = false;" class="btn btn-danger float-left mt-3">Cancel</button>
                    <button *ngIf="isOTPSend" type="submit" class="btn btn-primary float-right mt-3">Verify OTP</button><br>
                    </form>
        </div>
      </div>
    </div>
    <div class="col-1 col-sm-1 col-md-4 col-lg-4 col-xl-4"></div>
  </div>
  </div>
  