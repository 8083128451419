import {
  Component,
  OnInit,
  TemplateRef,
  VERSION,
  ViewChild,
} from "@angular/core";
import { NgForm, FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AppService } from "../../../services/app.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Constants } from "src/app/shared/models/constants";
import { ToastrService } from "ngx-toastr";
import { EncryptDecryptParametersService } from "src/app/services/encrypt-decrypt-parameters.service";
import { AdminService } from "src/app/admin/admin.service";
import { UserAccount } from "src/app/shared/models/user-account";
import { ChatService } from "../../chat/chat.service";
import { UserLoginDetails } from "src/app/shared/models/UserLoginDetails";
import { UserRole } from "src/app/shared/models/user-role";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Usersmissing } from "src/app/shared/models/Usersmissing";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public version = VERSION.full;
  modalRef: BsModalRef;
  gtusrModlRef: BsModalRef;
  requestRecived: BsModalRef;
  public reactiveForm: FormGroup = new FormGroup({
    recaptchaReactive: new FormControl(null, Validators.required),
  });

  login: {
    email: string;
    password: string;
    captchaText: string;
    authCode: string;
  };
  constants: any;
  isGenerateOtp = true;
  isCaptchaValid = true;
  captchaValidationStarted = false;
  public loading = false;
  reCaptcha: {
    AuthCode: string;
    CaptchBase64Data: string;
    CaptchaByteData: string;
    Timestamp: Date;
  };
  loginRes: any;
  RememberMe = false;
  captchaImagSource: string;
  currentUser: UserAccount;
  userLoginDetails: UserLoginDetails;
  userRole: UserRole;
  missingUser: Usersmissing;

  @ViewChild("showmessage", { static: true }) popupModal: TemplateRef<any>;
  @ViewChild("getUserData", { static: true }) getUserData: TemplateRef<any>;
  @ViewChild("requestReceived", { static: true }) requestReceived: TemplateRef<
    any
  >;

  constructor(
    private router: Router,
    private appService: AppService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private adminService: AdminService,
    private encryptDecryptParametersService: EncryptDecryptParametersService,
    private chatService: ChatService,
    private modalService: BsModalService
  ) {
    this.login = {
      email: null,
      password: null,
      captchaText: null,
      authCode: null,
    };
    this.constants = Constants;
    this.userLoginDetails = new UserLoginDetails();
    this.loginRes = {};
    this.reCaptcha = {
      AuthCode: null,
      CaptchBase64Data: null,
      CaptchaByteData: null,
      Timestamp: null,
    };
    this.currentUser = new UserAccount();
    this.missingUser = new Usersmissing();
  }
  ngOnInit(): void {
    this.showInfoModal();
    this.getAuthCode();
    const string = localStorage.getItem("userAuth");
    if (string && string !== "null") {
      const user = JSON.parse(atob(string));
      this.login.email = user.email;
      this.login.password = user.password;
      this.RememberMe = true;
    }
  }

  hideOtpTxtBox() {
    this.isGenerateOtp = this.isGenerateOtp ? false : true;
  }

  loginUser(form: NgForm, authCode, captchText) {
    if (this.RememberMe) {
      const user: any = {};
      user.email = this.login.email;
      user.password = this.login.password;
      const string = btoa(JSON.stringify(user));
      localStorage.setItem("userAuth", string);
    } else {
      localStorage.setItem("userAuth", null);
    }
    this.login.authCode = authCode;
    this.login.captchaText = captchText;
    if (captchText.length === 6 && captchText.length !== null) {
      if (form.valid) {
        this.spinner.show();
        this.appService.login(this.login).subscribe(
          (res) => {
            if (!res.HasErrors && res.SessionId !== undefined) {
              this.getCurrentUser(res);
              this.isCaptchaValid = true;
            } else {
              if (res.Errors !== undefined) {
                if (res.Errors[0].ErrorNumber === 1008) {
                  this.GetCurrentUserForInvalidTeacher(res);
                  this.spinner.hide();
                } else if (res.Errors[0].ErrorNumber === 1013) {
                  this.toastr.error(res.Errors[0].Message, "Error");
                  this.isCaptchaValid = false;
                  this.spinner.hide();
                } else {
                  this.toastr.error(res.Errors[0].Message, "Error");
                  this.spinner.hide();
                }
              } else {
                this.toastr.error("Please enter valid credentials", "Error");
                this.spinner.hide();
              }
            }
          },
          (err) => {
            this.spinner.hide();
          }
        );
        // }
        // }
      }
      (err) => {};
    } else {
      this.isCaptchaValid = false;
    }
  }

  GetCurrentUserForInvalidTeacher(data) {
    this.loginRes = data;
    sessionStorage.setItem("SessionId", this.loginRes.SessionId);
    sessionStorage.setItem("AccessToken", this.loginRes.Token);
    sessionStorage.setItem("Role", this.loginRes.R);
    localStorage.setItem("SessionId", this.loginRes.SessionId);
    localStorage.setItem("AccessToken", this.loginRes.Token);
    localStorage.setItem("Role", this.loginRes.R);
    const user = { Id: data.A };
    this.appService.getUser(user).subscribe((res: any) => {
      if (!res.HasErrors) {
        sessionStorage.setItem("CurrentUser", JSON.stringify(res));
        localStorage.setItem("CurrentUser", JSON.stringify(res));
        this.router.navigate(["/teacher/approve"]);
      }
    });
  }

  getCurrentUser(data) {
    this.loginRes = data;
    sessionStorage.setItem("SessionId", this.loginRes.SessionId);
    sessionStorage.setItem("AccessToken", this.loginRes.Token);
    sessionStorage.setItem("Role", this.loginRes.R);
    localStorage.setItem("SessionId", this.loginRes.SessionId);
    localStorage.setItem("AccessToken", this.loginRes.Token);
    localStorage.setItem("Role", this.loginRes.R);
    const user = { Id: data.A };
    this.appService.getUser(user).subscribe(
      (res: any) => {
        if (!res.HasErrors) {
          this.currentUser = res;
          // this.getRoleById();
          res.IsARTUser = true;
          sessionStorage.setItem("CurrentUser", JSON.stringify(res));
          localStorage.setItem("CurrentUser", JSON.stringify(res));
          if (this.loginRes.R === "Administrator") {
            this.router.navigate(["/admin/dashboard"]);
            this.spinner.hide();
          } else if (this.loginRes.R === "Teacher") {
            this.UserLogin();
            if (!res.IsPhoneVerified && !res.IsEmailVerified) {
              this.router.navigate(["/otpVerified"]);
            } else {
              this.router.navigate(["/teacher/teacherDashboard"]);
            }
            this.spinner.hide();
          } else {
            if (!res.IsPhoneVerified && !res.IsEmailVerified) {
              this.router.navigate(["/otpVerified"]);
            } else {
              this.UserLogin();
              if (res.IsFirstLogin && res.SchoolName == null) {
                this.router.navigate(["/subscription-plan"]);
                this.spinner.hide();
              } else {
                this.router.navigate(["/student/studentDashboard"]);
                this.spinner.hide();
              }
            }
          }
        } else {
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  getRoleById() {
    this.spinner.show();
    const roleObj = { RoleId: this.currentUser.UserRoleId };
    this.appService.getRoleById(roleObj).subscribe(
      (res) => {
        if (!res.HasErrors) {
          this.userRole = res;
          this.UpdateUser();
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  UpdateUser() {
    this.spinner.show();
    this.currentUser.LastLoginTime = new Date();
    const user = {
      UserDetails: this.currentUser,
    };

    this.appService.UpdateLastLogin(user).subscribe(
      (res) => {
        if (!res.HasErrors) {
          this.currentUser = res;
        } else {
          this.spinner.hide();
        }
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  UserLogin() {
    this.userLoginDetails.firstName = this.currentUser.FirstName;
    this.userLoginDetails.lastName = this.currentUser.LastName;
    this.userLoginDetails.activityType = "Login";
    this.userLoginDetails.userAccountId = this.currentUser.Id;
    if (this.currentUser.UserRoleId == 2) {
      this.userLoginDetails.rolePrefix = "TR";
    } else if (this.currentUser.UserRoleId == 3) {
      this.userLoginDetails.rolePrefix = "ST";
    } else if (this.currentUser.UserRoleId == 1) {
      this.userLoginDetails.rolePrefix = "AD";
    }
    this.chatService.UserLoginOrLogout(this.userLoginDetails);
  }

  registrationAs(as) {
    const encryptedstring = this.encryptDecryptParametersService.encrypt(
      `as=${as}`
    );
    this.router.navigateByUrl(`/registration?${encryptedstring}`);
  }
  getAuthCode() {
    this.appService.getAuthCode().subscribe((res) => {
      if (!res.HasErrors) {
        const authCode = res.Code;
        this.getCaptch(authCode);
      }
    });
  }

  getCaptch(code) {
    this.appService.getCaptcha(code).subscribe((res) => {
      if (!res.HasErrors) {
        this.reCaptcha = res;
        this.captchaImagSource = `data:image/png;base64,${this.reCaptcha.CaptchBase64Data}`;
      }
    });
  }

  validateCaptch(authCode, captchText) {
    if (captchText.length === 6 && captchText.length !== null) {
      this.appService.validateCaptha(authCode, captchText).subscribe(
        (res) => {
          if (!res.HasErrors) {
            // this.captchaValidationStarted = false;
            this.isCaptchaValid = res.IsValid;
            return res.IsValid;
          }
        },
        (err) => {
          return false;
          // this.captchaValidationStarted = false;
        }
      );
    } else {
      // this.captchaValidationStarted = false;
      this.isCaptchaValid = false;
      return false;
    }
  }

  showInfoModal() {
    this.modalRef = this.modalService.show(this.popupModal, {
      class: "modal-lg modal-dialog-centered",
    });
  }

  getUserInfo() {
    this.modalRef.hide();
    this.gtusrModlRef = this.modalService.show(this.getUserData, {
      class: "modal-lg modal-dialog-centered",
    });
  }

  SubmitUserData(getData: NgForm) {
    if (getData.valid) {
      this.spinner.show();
      this.appService.SendAdminEmail(this.missingUser).subscribe((res) => {
        if (!res.HasErrors) {
          this.gtusrModlRef.hide();
          this.spinner.hide();
          this.Showacknowledgepopup();
        } else {
          this.toastr.error(res.Errors[0].Message, "Error");
          this.spinner.hide();
        }
      });
    }
  }

  Showacknowledgepopup() {
    this.requestRecived = this.modalService.show(this.requestReceived, {
      class: "modal-lg modal-dialog-centered",
    });
  }
}
