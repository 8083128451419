import { Injectable } from '@angular/core';
import { HttpService } from '../core/services/http.service';
import { UserAccount } from '../shared/models/user-account';
import { Observable } from 'rxjs';
import { UserIdleService } from 'angular-user-idle';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(
    private httpService: HttpService,
    private userIdle: UserIdleService
  ) {
    this.restart();
  }

  restart() {
    this.userIdle.resetTimer();
  }

  getAdminDashboardStat(): Observable<any> {
    return this.httpService.get('account/AdminDashboardStats');
  }

  validateOldPassword(user: any): Observable<any> {
    return this.httpService.post('account/ValidateOldPassword', user);
  }

  add(user: any): Observable<any> {
    return this.httpService.post('account/Add', user);
  }

  update(user: any): Observable<any> {
    return this.httpService.post('account/Update', user);
  }

  delete(userAccount: any): Observable<any> {
    const user = { UserDetails: userAccount };
    return this.httpService.post('account/Delete', user);
  }

  changePassword(userAccount: any): Observable<any> {
    const user = { UserDetails: userAccount };
    return this.httpService.post('account/ChangePassword', user);
  }

  convert(userAccount: any): Observable<any> {
    const user = { UserDetails: userAccount };
    return this.httpService.post('account/ConvertUser', user);
  }

  approveTeacher(user: any): Observable<any> {
    return this.httpService.post('account/ApproveTeacher', user);
  }

  decline(userAccount: any): Observable<any> {
    return this.httpService.post('account/Decline', userAccount);
  }

  resetPassword(userAccount: UserAccount): Observable<any> {
    const user = { UserDetails: userAccount };
    return this.httpService.post('account/ResetPassword', user);
  }

  getUserAccountList(page): Observable<any> {
    return this.httpService.post('account/List', page);
  }
  updateTeacher(user: any): Observable<any> {
    return this.httpService.post('account/Update', user);
  }
}
