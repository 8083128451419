<nav class="navbar navbar-expand-md fixed-top navbar-dark flex-fill">
  <div class="navbar-header pl-2 pr-2 ml-3 text-center">
    <a class="navbar-brand m-0 w-100">
      <img src="./assets/img/logo-new.png" alt="ART logo" width="35px" />
      <span class="ml-1">Ask Real Teachers</span>
    </a>
  </div>
  <!-- sidebar toggler -->
  <div class="sidebar-toggler ml-auto mr-3">
    <a class="btn nav-link" (click)="toggleSidebar()"
      ><i class="fa fa-bars"></i
    ></a>
  </div>
  <!-- right menu toggler -->
  <div class="nav-toggler-right mr-4 d-md-none d-flex">
    <!-- <button
      class=""
      type="button"
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseBasic"
    >
      <img src="./assets/img/user.jpg" class="img-fluid" alt="" />
    </button> -->
    <div>
      <a
        class="btn nav-link mr-3"
        data-toggle="dropdown"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        title="Alerts"
      >
        <i class="far fa-bell"></i>
        <span
          *ngIf="userNotification.length !==0"
          class="badge badge-danger badge-pill font-13 ng-star-inserted position-absolute"
          style="left: 20px;"
          >{{userNotification.length||0}}</span
        >
      </a>
      <div
        class="dropdown-menu dropdown-menu-right border-between pt-0 pb-0 w-250"
      >
        <div class="dropdown-header text-center">
          <strong
            >Notifications</strong
          >
        </div>
        <div *ngIf="userNotification.length==0">
          <hr class="dropdown-hr" />
          <div class="dropdown-header text-center">
            <p class="text-center">You have no notification to display.</p>
          </div>
        </div>
        <div *ngIf="userNotification.length>0">
          <div
          class="notification-view"
          *ngFor="let notification of userNotification;let i=index"
        >
          <p
            *ngIf="i<5"
            class="text-nowrap text-left p-2 cursor-pointer"
            [innerHTML]="notification.Description | slice:0:25"
            style="background-color:#addde6"
            (click)="redirectToChatForNotification(notification)"
          ></p>
          <hr *ngIf="i<5" class="dropdown-hr" />
        </div>
        </div>
        <div
          *ngIf="userNotification?.length>5"
          class="dropdown-header text-center"
        >
          <a
            [routerLink]="['/commonFeature/notifications']"
            routerLinkActive="router-link-active"
            >View More</a
          >
        </div>
      </div>
    </div>
    <div>
      <a
        class="btn nav-link"
        data-toggle="dropdown"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        title="Profile"
        href="user_pro_file"
      >
        <i class="far fa-user" title="My Profile"></i>
      </a>
      <div
        class="dropdown-menu dropdown-menu-right border-between pt-0 pb-0"
      >
        <div *ngFor="let nav of navLinks">
          <a class="dropdown-item pointer" [routerLink]="nav.route">
            <i [ngClass]="nav.iconClass" title="{{nav.title}}"></i>
            {{nav.title}}
          </a>
          <hr class="dropdown-hr" />
        </div>
        <div>
          <a class="dropdown-item pointer" (click)="logout()">
            <i class="fa fa-sign-out-alt pr-1" title="logout"></i>
            Logout
          </a>
        </div>
      </div>
    </div>
    <!-- <button
      class=""
      type="button"
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseBasic"
    >
      <img src="./assets/img/user.jpg" class="img-fluid" alt="" />
    </button>
    <span class="user-profile nav-alert bg-danger"></span> -->
  </div>
  <!-- left box -->
  <ul class="navbar-nav flex-fill mt-1 align-items-center left-nav">
    <li class="nav-item navicon">
      <a class="btn nav-link" (click)="toggleSidebarPin()"
        ><i class="fa fa-bars"></i
      ></a>
    </li>
    <!-- <li class="nav-item flex-fill">
      <input
        class="form-control navbar-search"
        type="text"
        placeholder="Search . . ."
      />
    </li> -->
  </ul>

  <!-- right menu -->
  <div
    class="collapse navbar-collapse right-nav"
    id="collapseBasic"
    [collapse]="isCollapsed"
  >
    <ul class="navbar-nav">
      <span class="nav-item" *ngIf="currentUser.UserRoleId===3 ">
        <span
          *ngIf="userSubscription !== null && isPlanSubscribed"
          class="badge badge-pill badge-info p-2 mt-2 font-14"
        >
          <span
            >{{userSubscription.SubscriptionDays}} Subscription days left |
          </span>
          {{ userSubscription === null ? 'Currently you have no active plan':
          ''}}
          <span *ngIf="userSubscription?.QuestionLimit === -1"
            >Unlimited Questions Left</span
          >
          <span>{{userSubscription.RemainingQuestion}} Questions Left</span>
        </span>
        <span
          *ngIf="userSubscription === null || !isPlanSubscribed"
          class="badge badge-pill badge-info p-2 mt-2 font-14"
        >
          Currently you have no active plan
        </span>
      </span>
      <li class="nav-item dropdown" *ngIf="(currentUser.IsApproved)">
        <a
          class="btn nav-link pr-0"
          data-toggle="dropdown"
          role="button"
          j
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="far fa-bell"></i>
          <span class="link-text">Alerts</span>
          <span
            *ngIf="userNotification.length !==0"
            class="badge badge-danger badge-pill ml-3 position-absolute"
            >{{userNotification.length||0}}</span
          >
        </a>
        <div
          class="dropdown-menu dropdown-menu-right border-between pt-0 pb-0 w-250"
        >
          <div class="dropdown-header text-center">
            <strong class="text-gray">
              
              Notifications</strong
            >
          </div>

          <div *ngIf="userNotification.length==0">
            <hr class="dropdown-hr" />
            <div class="dropdown-header text-center">
              <p class="text-center">You have no notifications to display.</p>
            </div>
          </div>

          <div class="notification-content" *ngIf="userNotification?.length!=0">
            <div
              class="notification-view"
              *ngFor="let notification of userNotification;let i=index"
            >
              <p
                *ngIf="i<5"
                class="text-left p-2 cursor-pointer"
                [innerHTML]="notification.Description"
                style="background-color:#addde6"
                (click)="redirectToChatForNotification(notification)"
              ></p>
              <hr *ngIf="i<5" class="dropdown-hr" />
            </div>
          </div>
          <div
            *ngIf="userNotification?.length>5"
            class="dropdown-header text-center"
          >
            <a
              [routerLink]="['/commonFeature/notifications']"
              routerLinkActive="router-link-active"
              >View More</a
            >
          </div>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a
          class="btn nav-link"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="far fa-user" title="My Profile"></i>
          <span class="link-text">Profile</span>
        </a>
        <div class="dropdown-menu dropdown-menu-right border-between pt-0 pb-0">
          <div *ngFor="let nav of navLinks">
            <a class="dropdown-item pointer" [routerLink]="nav.route">
              <i [ngClass]="nav.iconClass" title="{{nav.title}}"></i>
              {{nav.title}}
            </a>
            <hr class="dropdown-hr" />
          </div>
          <div>
            <a class="dropdown-item pointer" (click)="logout()">
              <i class="fa fa-sign-out-alt pr-1" title="logout"></i>
              Logout
            </a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</nav>

