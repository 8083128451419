<!--The content below is only a placeholder and can be replaced.-->
<div class="wrapper default-theme" [ngClass]="getClasses()">
  <app-navbar></app-navbar>
  <main>
    <app-sidebar *ngIf="currentUser.IsApproved"></app-sidebar>
    <div class="pages container-fluid pt-3 pb-4 pl-4 pr-4">
      <router-outlet></router-outlet>
      <hr />
      <app-footer></app-footer>
    </div>
    <!-- <div class="overlay" (click)="toggleSidebar()"></div> -->
  </main>
</div>
<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.99)"
  size="medium"
  color="#fff"
  type="line-spin-fade"
  [fullScreen]="true"
  ><p style="color: white;">Loading...</p></ngx-spinner
>
