import { Observable, BehaviorSubject, Subject } from "rxjs";
import { Injectable } from "@angular/core";

import { TeacherQuestionVM } from "../shared/models/teacher-question-vm";
import { HttpService } from "../core/services/http.service";
import { Question } from "../shared/models/question";
import { PaginationVM } from "../shared/models/pagination-vm";
import { QuestionDetails } from "../shared/models/question-vm";
import { UserSubscription } from "../shared/models/user-subscription";
import { UserIdleService } from "angular-user-idle";
import { PlatformLocation } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class StudentService {
  constructor(
    private httpService: HttpService,
    private userIdle: UserIdleService,
    private pLocation: PlatformLocation
  ) {
    this.restart();
  }
  private askQuestion = new BehaviorSubject<any>(null);
  private ischanageQuestionCount = new BehaviorSubject<any>(null);
  askQuestion$ = this.askQuestion.asObservable();
  ischanageQuestionCount$ = this.ischanageQuestionCount.asObservable();
  cancelSubscriptionPlanName = new Subject();
  public IsUserSubscribePlan = new Subject<any>();

  restart() {
    this.userIdle.resetTimer();
  }

  askTeacherQuestion(value: TeacherQuestionVM) {
    this.askQuestion.next(value);
  }
  chanageQuestionCount(value: UserSubscription) {
    this.ischanageQuestionCount.next(value);
  }
  getUnAnsweredQuestions(
    paginationVM: PaginationVM<QuestionDetails>
  ): Observable<any> {
    // const pagination = { PaginationVM: paginationVM };
    return this.httpService.post(
      "question/StudentUnansweredList",
      paginationVM
    );
  }

  getAnsweredQuestions(
    paginationVM: PaginationVM<QuestionDetails>
  ): Observable<any> {
    return this.httpService.post("question/StudentAnsweredList", paginationVM);
  }

  getTeacherList(filter: any): Observable<any> {
    return this.httpService.post("teacher/ListForStudent", filter);
  }

  getAssignTeacherList(paginationVM: any): Observable<any> {
    return this.httpService.post("teacher/List", paginationVM);
  }
  submitQuestionDetails(
    askTeacherQuestion: Question,
    files: any[],
    id: any
  ): Observable<any> {
    const question = {
      QuestionDetails: askTeacherQuestion,
      FilesMetadata: files,
      Id: id,
    };
    return this.httpService.post("question/Add", question);
  }
  getRecentlyAskedQuestions(studentId): Observable<any> {
    // const pagination = { PaginationVM: paginationVM };
    return this.httpService.post(
      "question/StudentRecentlyAskedQuestionsList",
      studentId
    );
  }
  getQuestionStatisticsForStudent(studentId): Observable<any> {
    return this.httpService.post("student/DashboardStatistics", studentId);
  }
  getStudentDetails(id): Observable<any> {
    return this.httpService.post("account/Get", id);
  }

  subscribePlan(subscriptionPlanObj): Observable<any> {
    return this.httpService.post("student/Subscribe", subscriptionPlanObj);
  }
  unSubscribePlan(subscriptionPlanObj): Observable<any> {
    return this.httpService.post("student/Unsubscribe", subscriptionPlanObj);
  }
  updateUserSubscription(userSubscription: UserSubscription): Observable<any> {
    const subscription = { SubscriptionDetails: userSubscription };
    return this.httpService.post("student/UpdateSubscription", subscription);
  }
  getRemainingQuestionLimit(id): Observable<any> {
    return this.httpService.post("student/Subscription", id);
  }
  uploadDocument(file, base64File): Observable<any> {
    const fileObj = {
      FileName: file.name,
      File: base64File,
      DocumentType: file.type,
      status: "1",
    };
    const document = { FilesMetadata: fileObj };
    return this.httpService.postAnonymous(
      "question/saveQuestionDocument",
      document
    );
  }

  getAskQuestionTeacherList(paginationVM: any): Observable<any> {
    return this.httpService.post("teacher/AskTeacherQuestion", paginationVM);
  }
}
