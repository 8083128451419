<div class="outer-div">
  <div class="row">
    <div
      class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4 text-center"
    >
      <div
        style="
          background: rgb(67 74 82 / 69%);
          border-radius: 5px;
          padding: 10px 0;
        "
      >
        <img src="./assets/img/logo-new.png" height="50" /> <br />
        <span class="logo-text pl-1">Ask Real Teachers, Get Real Answers</span>
      </div>
    </div>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="card login-box-image">
        <div class="p-4 pt-4">
          <div class="row">
            <div
              class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form-group"
            >
              <h3 class="signIn-text">Forgot Password</h3>
              <hr class="sginIn-hr" />
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <form
                novalidate
                autocomplete="off"
                #forgotPasswordForm="ngForm"
                *ngIf="!isOTPVerified"
                (ngSubmit)="isOTPSend === true ? verifyOTP(forgotPasswordForm) : sendOTP()"
              >
                <div>
                  <div class="form-group">
                    <label class="required">Email</label>
                    <input
                      type="text"
                      class="form-control"
                      required
                      [(ngModel)]="forgotPassword.Email"
                      [ngClass]="{'is-invalid ': Email.invalid && (Email.dirty || Email.touched  || forgotPasswordForm.submitted) }"
                      [pattern]="constants.EMAIL_REGEXP"
                      name="Email"
                      #Email="ngModel"
                      placeholder="Email"
                      [disabled]="isOTPSend"
                    />

                    <div
                      class="invalid-feedback"
                      [ngStyle]="{display:Email.invalid &&
                    (Email.dirty || forgotPasswordForm.submitted) ? 'block': 'none'}"
                    >
                      <div [hidden]="!Email?.errors?.required">
                        <span class="fa fa-exclamation-circle"></span> Email is
                        required
                      </div>
                      <div [hidden]="!Email?.errors?.pattern">
                        Enter valid Email
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="isOTPSend">
                  <div class="form-group mb-0">
                    <label class="required">OTP </label>
                    <input
                      type="text"
                      class="form-control"
                      required
                      [maxLength]="8"
                      [(ngModel)]="forgotPassword.OTP"
                      [ngClass]="{'is-invalid ': OTP.invalid && (OTP.dirty || OTP.touched) }"
                      name="OTP"
                      [appOnlyNumberDirective]="true"
                      #OTP="ngModel"
                      placeholder="OTP"
                    />

                    <div class="row mb-3">
                      <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                        <div
                          class="invalid-feedback"
                          [ngStyle]="{display:OTP.invalid &&
                (OTP.dirty || OTP.touched || forgotPasswordForm.submitted) ? 'block': 'none'}"
                        >
                          <div [hidden]="!OTP?.errors?.required">
                            <span class="fa fa-exclamation-circle"></span> OTP
                            is required
                          </div>
                        </div>
                      </div>

                      <div
                        class="invalid-feedback color-blue pointer col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                        style="display: inline-block;"
                      >
                        <span class="float-right"
                          ><a (click)="sendOTP()">Resend OTP</a></span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <button
                    type="reset"
                    class="btn btn-secondary mb-2 float-right ml-2"
                    (click)="goBack()"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    class="btn btn-primary mb-2 float-right"
                  >
                    Next
                  </button>
                </div>
                <div></div>
              </form>

              <form
                *ngIf="isOTPVerified"
                novalidate
                autocomplete="off"
                (ngSubmit)="changePassword(passwordForm)"
                #passwordForm="ngForm"
              >
                <div class="row">
                  <div
                    class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center"
                  >
                    <div class="form-group">
                      <label
                        ><strong class="text-muted"
                          >{{userAccount.FirstName}}
                          {{userAccount.LastName}}</strong
                        ></label
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="form-group">
                      <label class="required">Password</label>
                      <span
                        class="fa fa-exclamation-circle text-info float-right m-0"
                        containerClass="pop-container"
                        popover=" The password should be 10 to 15 characters
                    long with at least 1 uppercase,1 lowercase, 1
                    special character and 1 number."
                        triggers="mouseenter:mouseleave"
                        placement="auto"
                      ></span>
                      <input
                        type="password"
                        class="form-control"
                        required
                        [(ngModel)]="forgotPassword.Password"
                        [ngClass]="{'is-invalid': Password.invalid && (Password.dirty || Password.touched || passwordForm.submitted) }"
                        name="Password"
                        #Password="ngModel"
                        placeholder="Password"
                        [pattern]="constants.PASSWORD_REGEXP"
                        [maxLength]="15"
                        [minLength]="10"
                      />
                      <div
                        class="invalid-feedback"
                        [ngStyle]="{display:Password.invalid &&
                    (Password.dirty || Password.touched || passwordForm.submitted) ? 'block': 'none'}"
                      >
                        <div [hidden]="!Password?.errors?.required">
                          <span class="fa fa-exclamation-circle"></span>
                          Password is required
                        </div>
                        <div [hidden]="!Password?.errors?.pattern">
                          Enter valid confirm password
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="form-group">
                      <label class="required">Confirm Password</label>
                      <input
                        type="password"
                        class="form-control"
                        required
                        [(ngModel)]="forgotPassword.ConfirmPassword"
                        [ngClass]="{'is-invalid': ConfirmPassword.invalid && (ConfirmPassword.dirty || ConfirmPassword.touched || passwordForm.submitted) }"
                        (ngModelChange)="checkPassword()"
                        name="ConfirmPassword"
                        #ConfirmPassword="ngModel"
                        placeholder="Confirm Password"
                        [pattern]="constants.PASSWORD_REGEXP"
                        [maxLength]="15"
                        [minLength]="10"
                      />
                      <div
                        class="invalid-feedback"
                        [ngStyle]="{display:ConfirmPassword.invalid &&
                        (ConfirmPassword.dirty || ConfirmPassword.touched || passwordForm.submitted) ? 'block': 'none'}"
                      >
                        <div [hidden]="!ConfirmPassword?.errors?.required">
                          <span class="fa fa-exclamation-circle"></span>Confirm
                          Password is required
                        </div>
                      </div>
                      <div
                        *ngIf="!checkPassword() && !ConfirmPassword?.errors?.required && !ConfirmPassword?.errors?.pattern"
                        class="invalid-feedback"
                        style="display: block;"
                      >
                        <span class="fa fa-exclamation-circle"></span>
                        Password mismatch
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <button
                      type="reset"
                      class="btn btn-secondary mb-2 float-right ml-2"
                      (click)="goBack()"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary mb-2 float-right"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="form-group font-weight-bold text-center">
                <span
                  >Click Here To
                  <a routerLink="/login" class="color-blue">
                    Login
                  </a></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
