import { Injectable } from "@angular/core";
import { Subscription } from "src/app/shared/models/subscription-model";
import { Observable } from "rxjs";
import { HttpService } from "src/app/core/services/http.service";

@Injectable({
  providedIn: "root",
})
export class SubscriptionService {
  constructor(private httpService: HttpService) {}

  // Subscripton methods start
  getSubscriptionPlanList(): Observable<any> {
    return this.httpService.get("subscription/List");
  }

  PagedSubscriptionPlanList(page): Observable<any> {
    if (
      page.Filter.QuestionLimit === "Unlimited" ||
      page.Filter.QuestionLimit === "unlimited"
    ) {
      page.Filter.QuestionLimit = -1;
    }
    return this.httpService.post("subscription/PagedList", page);
  }

  getSubscriberHistoryList(page): Observable<any> {
    return this.httpService.post("subscription/SubscriberHistoryList", page);
  }

  getSubscriptionListByCategoryId(plan): Observable<any> {
    return this.httpService.post("subscription/ListByCategoryId", plan);
  }

  getSubscriptionPlanPagedList(page): Observable<any> {
    return this.httpService.post("subscription/PagedList", page);
  }

  getSubscription(subscriptionPlan): Observable<any> {
    return this.httpService.post("subscription/Get", subscriptionPlan);
  }

  addSubscriptionPlan(subscriptionPlan: Subscription): Observable<any> {
    const user = { SubscriptionDetails: subscriptionPlan };
    return this.httpService.post("subscription/Add", user);
  }

  updateSubscriptionPlan(subscriptionPlan: Subscription): Observable<any> {
    const subscription = { SubscriptionDetails: subscriptionPlan };
    return this.httpService.post("subscription/Update", subscription);
  }
  subscriptionSoftDelete(subscriptionId): Observable<any> {
    return this.httpService.post("subscription/Delete", subscriptionId);
  }

  getSubscriberList(page): Observable<any> {
    return this.httpService.post("subscription/PagedSubscriberList", page);
  }

  getExpirySubscriberList(page): Observable<any> {
    return this.httpService.post(
      "subscription/PagedExpirySubscriberList",
      page
    );
  }

  IsUserSubscribeToPlan(subscriptionPlan): Observable<any> {
    return this.httpService.post(
      "subscription/IsUserSubscribeToPlan",
      subscriptionPlan
    );
  }

  UpdateStudentExistingSubscriptionByQuestionLimit(
    subscriptionPlan
  ): Observable<any> {
    var SubscriptionDetails = {
      SubscriptionDetails: subscriptionPlan,
    };
    return this.httpService.post(
      "student/UpdateUserSubscriptionByLimit",
      SubscriptionDetails
    );
  }

  // Subscripton methods end
}
