import { TeacherSubject, TeacherCourse, TeacherGrade } from './teacher-vm';
import { UserAccount } from './user-account';
import { Teacher } from './teacher';
export class RegistrationVM {
  UserAccount: UserAccount;
  Teacher: Teacher;
  SchoolSubscriptionCode: string;
  TeacherGrades: TeacherGrade[];
  TeacherSubjects: TeacherSubject[];
  TeacherCourses: TeacherCourse[];
  IsActive: boolean;
  IsApproved: boolean;
  IsCheckPolicy: boolean;
  IsCheckTeacherPolicy: boolean;

  constructor() {
    this.UserAccount = new UserAccount();
    this.Teacher = new Teacher();
    this.SchoolSubscriptionCode = null;
    this.TeacherGrades= new Array<TeacherGrade>();
    this.TeacherSubjects = new Array<TeacherSubject>();
    this.TeacherCourses = new Array<TeacherCourse>();
    this.IsActive = true;
    this.IsApproved = false;
    this.IsCheckPolicy = null;
    this.IsCheckTeacherPolicy = null;
  }
}
