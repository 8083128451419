import { BaseEntity } from '../../shared/models/base-entity';
export class Payment extends BaseEntity {
  Subscription: string;
  StripeJson: string;
  Tax: number;
  PlanPrice: number;
  TotalAmount: number;
  Last4Digit: string;
  TransactionDate: Date;
  TransactionId: string;
  DeploymentId: number;
  UserAccountId: number;
  UserSubscriptionId: number;
  IsActive: boolean;
  constructor() {
    super();
    this.Subscription = null;
    this.StripeJson = null;
    this.Tax = null;
    this.PlanPrice = null;
    this.TotalAmount = null;
    this.Last4Digit = null;
    this.TransactionDate = null;
    this.TransactionId = null;
    this.DeploymentId = 1;
    this.UserAccountId = 0;
    this.UserSubscriptionId = 0;
    this.IsActive = true;
  }
}
