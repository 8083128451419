import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EncryptDecryptParametersService {

  constructor() { }

  encrypt(url: string) {
    return encodeURIComponent(btoa(url));
  }

  decrypt(url: string) {
    let encryptedString = '';
   if(url.includes("?")){
    if (url.split('?')[1].includes('%3D')) {
      encryptedString = url.split('?')[1].replace('%3D', '');
    } else if (
      !url.split('?')[1].includes('%3D') &&
      url.split('?')[1].includes('=')
    ) {
      encryptedString = url.split('?')[1].replace('=', '');
    }
   }else{
    encryptedString = url.replace('=','');
   }

    return atob(decodeURIComponent(encryptedString));
  }

  getQueryParams( params, url ){
    let href = url;
    let reg = new RegExp( '[?&]' + params + '=([^&#]*)', 'i' );
    let queryString = reg.exec(href);
    return queryString ? queryString[1] : null;
  }
}
