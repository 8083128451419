// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // api: "http://localhost:47634/art/",
  // notifyUrl: "http://localhost:47634/",
  // uiUrl: "http://localhost:4200",

  // api: "http://13.71.22.234:2020/API/art/",
  // notifyUrl: "http://13.71.22.234:2020/API/",
  // uiUrl: "http://13.71.22.234:2020",

  // api: 'https://stagingportal.askrealteachers.com/API/art/',
  // notifyUrl: 'https://stagingportal.askrealteachers.com/API/',
  // uiUrl: 'https://stagingportal.askrealteachers.com',
  api: "https://messenger.askrealteachers.com/API/art/",
  notifyUrl: "https://messenger.askrealteachers.com/API/",
  uiUrl: "https://messenger.askrealteachers.com",
  production: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
