import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: "safeUrl" })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(v) {
    // var url = "//www.youtube.com/embed/" + myId
    if (v.includes("youtu")) {
      var myId = this.getId(v);
      var url = "//img.youtube.com/vi/" + myId + "/0.jpg";
    } else if (v.includes("https://drive.google.com/")) {
      var myDriveId = this.getDriveId(v);
      // url = v.replace('view?usp=sharing', 'preview');
      url = `${
        "https://drive.google.com/thumbnail?authuser=0&sz=w480-h560&id=" + myDriveId
      }`;
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  getId(url) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
      return match[2];
    } else {
      return "error";
    }
  }

  getDriveId(url: string) {
    var driveUrl = url.split("/d/");
    var driveThumbnailUrl = driveUrl[1].split("/view")[0];
    return driveThumbnailUrl;
  }
}
