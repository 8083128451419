import { Component, OnInit, ElementRef } from "@angular/core";
import { AppService } from "src/app/services/app.service";
import { UserAccount } from "../../models/user-account";
import { StudentService } from 'src/app/student/student.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChatService } from 'src/app/common-feature/chat/chat.service';
import { Router } from '@angular/router';
import { UserNotification } from '../../models/userNotification';
import { EncryptDecryptParametersService } from 'src/app/services/encrypt-decrypt-parameters.service';

@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  styleUrls: ["./main-layout.component.scss"],
})
export class MainLayoutComponent implements OnInit {
  currentUser: UserAccount;
  userNotification: any;
  constructor(
    private appService: AppService,
    private elRef: ElementRef,
    private studentService: StudentService,
    private spinner: NgxSpinnerService,
    private chatService: ChatService,
    private router: Router,
    private encryptDecryptParametersService: EncryptDecryptParametersService
  ) {}

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
    
  }

  getClasses() {
    const classes = {
      "pinned-sidebar": this.appService.getSidebarStat().isSidebarPinned,
      "toggeled-sidebar": this.appService.getSidebarStat().isSidebarToggeled,
    };
    return classes;
  }

  toggleSidebar() {
    this.appService.toggleSidebar();
  }

  ngAfterViewInit() {
    // assume dynamic HTML was added before
    // this.elRef.nativeElement
    //   .querySelector("notificationredirectClick")
    //   .addEventListener("click", this.redirectToChat.bind(this));
  }

  redirectToChat(notification: UserNotification) {
    const index = this.userNotification.findIndex(
      (u) => u.Id === notification.Id
    );
    if (index !== -1) {
      this.userNotification.splice(index, 1);
    }

    const notifications = <UserNotification>{
      Id: notification.Id,
      Description: notification.Description,
      IsRead: true,
      Question: {
        QuestionId: notification.Question.QuestionId,
        Description: notification.Question.Description,
        Creator: {
          Id: notification.Question.Creator.Id,
          FirstName: notification.Question.Creator.FirstName,
          LastName: notification.Question.Creator.LastName,
          Email: notification.Question.Creator.Email,
        },
      },
      Creator: {
        Id: notification.Creator.Id,
        FirstName: notification.Creator.FirstName,
        LastName: notification.Creator.LastName,
        Email: notification.Creator.Email,
      },
      Receiver: {
        Id: notification.Receiver.Id,

        FirstName: notification.Receiver.FirstName,
        LastName: notification.Receiver.LastName,
        Email: notification.Receiver.Email,
      },
      CreatedById: notification.CreatedById,
      CreatedDate: notification.CreatedDate,
      UpdatedById: this.currentUser.Id,
    };

    this.chatService.updateNotification(notifications).subscribe((res) => {
      if (!res.HasErrors) {
        const encryptedstring = this.encryptDecryptParametersService.encrypt(
          `id=${notification.Question.QuestionId}`
        );
        this.router.navigateByUrl(
          `/student/question-chat/conversation?${encryptedstring}`
        );
        // this.userNotification = res;
      }
    });
  }
}
