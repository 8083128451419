import { BaseEntity } from './base-entity';

export class Teacher extends BaseEntity {
  Qualification: string;
  Experience: string;
  Biography: string;
  TeachingStyle: string;
  TeacherType: string;
  PreferredPaymentMethod: string;
  Awards: string;
  Roles: string[];
  UserAccountId: number;
  constructor() {
    super();
    this.Qualification = null;
    this.Experience = null;
    this.Biography = null;
    this.TeachingStyle = null;
    this.TeacherType = 'null';
    this.Awards = null;
    this.PreferredPaymentMethod = null;
    this.Roles = new Array<string>();
    this.UserAccountId = null;
  }
}
