import { Injectable, EventEmitter } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { HttpService } from "src/app/core/services/http.service";
import { HubConnection, HubConnectionBuilder } from "@aspnet/signalr";
import { Message, Notify } from "./model/chat-summary";
import * as signalR from "@aspnet/signalr";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { UserNotification } from "src/app/shared/models/userNotification";
import { PaginationVM } from "src/app/shared/models/pagination-vm";
import { Subject } from "rxjs";
import { UserLoginDetails } from "src/app/shared/models/UserLoginDetails";
import { ChatAttachment } from "./model/chat-attachments";
import { PlatformLocation } from "@angular/common";
import { EncryptDecryptParametersService } from "src/app/services/encrypt-decrypt-parameters.service";

@Injectable({ providedIn: "root" })
export class ChatService {
  messageReceived = new EventEmitter<Message>();
  connectionEstablished = new EventEmitter<Boolean>();
  notificationReceived = new EventEmitter<Message>();
  typingStartedNow = new EventEmitter<Message>();
  loginUserDetails = new EventEmitter<UserLoginDetails>();
  userNotification = new Subject<UserNotification>();
  public userNotification$ = this.userNotification.asObservable();
  messageDataReceived = new EventEmitter<Message>();

  private connectionIsEstablished = false;
  private _hubConnection: HubConnection;
  constructor(
    private httpService: HttpService,
    private pLocation: PlatformLocation,
    private encryptDecryptParametersService: EncryptDecryptParametersService
  ) {
    this.createConnection();
    this.registerOnServerEvents();
    this.startConnection();
  }
  addMessage(msg): Observable<any> {
    return this.httpService.post(`chat/Add`, msg);
  }

  getChatSummary(question): Observable<any> {
    return this.httpService.post(`chat/GetByQuestionId`, question);
  }

  sendMessage(message: Notify) {
    this._hubConnection.invoke("SendMessage", message);
  }
  sendNotification(message: Notify) {
    this._hubConnection.invoke("SendNotification", message);
  }

  startedTyping(message: Notify) {
    this._hubConnection.invoke("StartedTyping", message);
  }

  stoppedTyping(message: Notify) {
    this._hubConnection.invoke("StartedTyping", message);
  }

  UserLoginLogout(userDetails: UserLoginDetails) {
    this._hubConnection.invoke("UserLoginOrLogout", userDetails);
  }

  updateChatSummary(msg): Observable<any> {
    return this.httpService.post(`chat/update`, msg);
  }

  private createConnection() {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(environment.notifyUrl + "Notifier", {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .build();
  }

  private startConnection(): void {
    this._hubConnection
      .start()
      .then(() => {
        this.connectionIsEstablished = true;
        console.log("Hub connection started");
        this.connectionEstablished.emit(true);
      })
      .catch((err) => {
        console.log("Error while establishing connection, retrying...");
        this.startConnection();
      });
  }

  private registerOnServerEvents(): void {
    this._hubConnection.on("ReceiveMessage", (data: any) => {
      this.messageReceived.emit(data);
    });
    this._hubConnection.on("ReceiveNotification", (data: any) => {
      this.notificationReceived.emit(data);
    });

    this._hubConnection.on("ShowTyping", (data: any) => {
      this.typingStartedNow.emit(data);
    });
    this._hubConnection.on("UserLoginOrLogout", (data: any) => {
      this.loginUserDetails.emit(data);
    });
    this._hubConnection.on("ChatMessage", (data: any) => {
      this.messageDataReceived.emit(data);
    });
  }

  uploadChatAttachment(file, base64File): Observable<any> {
    const fileObj = {
      FileName: file.name,
      File: base64File,
      DocumentType: file.type,
      status: "1",
    };
    const data = { FilesMetadata: [fileObj] };
    return this.httpService.postAnonymous("chat/AddChatAttachment", data);
  }

  RateTeacher(teacherReview): Observable<any> {
    const data = { TeacherReview: teacherReview };
    return this.httpService.post("teacher/RateTeacher", data);
  }

  publishQuestion(question): Observable<any> {
    const data = { PublishQuestion: question };
    return this.httpService.post("chat/PublishQuestion", data);
  }
  getNotifications(id): Observable<any> {
    const data = { UserAccountId: id };
    return this.httpService.post(`notification/List`, data);
  }

  getNotificationList(
    paginationVM: PaginationVM<UserNotification>
  ): Observable<any> {
    return this.httpService.post(`notification/PaginatedList`, paginationVM);
  }

  playAudio() {
    const audio = new Audio();
    audio.src = "../../../assets/audio/iphone_notification.mp3";
    audio.load();
    audio.play();
  }
  addNotification(notification): Observable<any> {
    const data = { Notification: notification };
    return this.httpService.post(`notification/Add`, data);
  }
  updateNotification(notification): Observable<any> {
    const data = { Notification: notification };
    return this.httpService.post(`notification/Update`, data);
  }
  createMetting(questionId, userId, firstName): Observable<any> {
    const data = {
      QuestionId: questionId,
      CurrentUserId: userId,
      Name: firstName,
    };
    return this.httpService.post(`questionbbb/CreateNewMeeting`, data);
  }

  joinMetting(meetingName, meetingId, name): Observable<any> {
    const data = {
      meetingName: meetingName,
      meetingId: meetingId,
      name: name,
    };
    return this.httpService.post(`questionbbb/joinMeeting`, data);
  }

  GetUserSession(userDetails): Observable<any> {
    return this.httpService.post("security/GetCurrentSession", userDetails);
  }

  UserLoginOrLogout(UserLoginDetails: UserLoginDetails) {
    this._hubConnection.invoke("UserLoginOrLogout", UserLoginDetails);
  }

  UserLogout() {}

  SendChatMessage(chatMessage: Message) {
    this._hubConnection.invoke("ChatMessage", chatMessage);
  }

  sendSms(questions) {
    var questionDetails = {
      QuestionDetails: questions,
      questionUrl: (this.pLocation as any).location.href,
    };
    return this.httpService.post("question/SendMessege", questionDetails);
  }

  SendAddQuestionSms(questions) {
    const encryptedstring = this.encryptDecryptParametersService.encrypt(
      `id=${questions.Id}`
    );
    var questionDetails = {
      QuestionDetails: questions,
      questionUrl:
        "https://messenger.askrealteachers.com/student/question-chat/conversation?" +
        encryptedstring,
    };
    return this.httpService.post(
      "question/SendAddQuestionMessege",
      questionDetails
    );
  }
}
