import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/shared/models/constants';
import { AppService } from '../../../services/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Subscriber } from '../../../shared/models/subscriber-model';
import { UserAccount } from '../../../shared/models/user-account';
import { AdminService } from '../../../admin/admin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPassword: {
    Email: string;
    OTP: string;
    Password: string;
    ConfirmPassword: string;
  };
  constants: any;
  emailPattern: string;
  userAccount: UserAccount;
  isOTPSend = false;
  isOTPVerified = false;
  currentUser: UserAccount;

  constructor(
    private appService: AppService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private adminService: AdminService,
    private router:Router
  ) {
    this.constants = Constants;
    this.userAccount = new UserAccount();
    this.forgotPassword = {
      Email: null,
      OTP: null,
      Password: null,
      ConfirmPassword: null,
    };
    this.emailPattern = this.constants.EMAIL_REGEXP;
  }

  ngOnInit(): void {
    this.userAccount = JSON.parse(sessionStorage.getItem('CurrentUser'));
  }

  sendOTP() {
    if (this.forgotPassword.Email.match(this.emailPattern)) {
      this.spinner.show();
      const email = { Email: this.forgotPassword.Email };
      this.appService.sendOTP(email).subscribe(
        (res) => {
          if (!res.HasErrors) {
            this.isOTPSend = true;
            this.toastr.success(
              'OTP to sent to '+ email.Email
            );
            this.spinner.hide();
          } else {
            this.toastr.error(res?.Errors[0]?.Message, 'Error');
            this.spinner.hide();
          }
        },
        (err) => {
          this.spinner.show();
        }
      );
    }
  }

  verifyOTP(forgotPasswordForm: NgForm) {
    if (forgotPasswordForm.valid) {
      this.spinner.show();
      const userDetails = {
        Email: this.forgotPassword.Email,
        OTP: this.forgotPassword.OTP,
        UserDetails:this.userAccount
      };
      this.appService.verifyOTP(userDetails).subscribe(
        (res) => {
          if (!res.HasErrors) {
            this.userAccount =
              res !== undefined && res !== null ? res : new UserAccount();
            this.isOTPVerified = true;
            this.toastr.success('OTP verified successfully');
            this.spinner.hide();
          } else {
            this.toastr.error(res?.Errors[0]?.Message, 'Error');
            this.spinner.hide();
          }
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error('Your OTP has been expired.');
        }
      );
    }
  }

  checkPassword() {
    return this.forgotPassword.Password === this.forgotPassword.ConfirmPassword
      ? true
      : false;
  }

  changePassword(form: NgForm) {
    if (form.valid) {
      this.spinner.show();
      this.userAccount.Email = this.forgotPassword.Email;
      this.userAccount.ModifiedBy =
        this.userAccount.FirstName + ' ' + this.userAccount.LastName;
      this.userAccount.ModifiedById = this.userAccount.Id;
      this.userAccount.Password = this.forgotPassword.ConfirmPassword;
      this.adminService.changePassword(this.userAccount).subscribe(
        (res) => {
          if (!res.HasErrors) {
            this.toastr.success('Change password successful');
            this.userAccount = new UserAccount();
            this.router.navigate(['/login']);
            this.spinner.hide();
          } else {
            this.userAccount = new UserAccount();
            this.spinner.hide();
          }
        },
        (err) => {
          this.userAccount = new UserAccount();
          this.spinner.hide();
        }
      );
    }
  }

  goBack() {
    this.appService.goBack();
  }
}
