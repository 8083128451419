<div class="outer-div">
  <div class="row">
    <div
      class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4 text-center"
    >
      <div
        style="
          background: rgb(67 74 82 / 69%);
          border-radius: 5px;
          padding: 10px 0;
        "
      >
        <img src="./assets/img/logo-new.png" height="50" /> <br />
        <span class="logo-text pl-1">Ask Real Teachers, Get Real Answers</span>
      </div>
    </div>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="card login-box-image">
        <div class="login-form pt-4 p-4">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <h4 class="signIn-text text-uppercase">Sign In</h4>
              <hr class="sginIn-hr" />
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <form
                novalidate
                autocomplete="off"
                (ngSubmit)="loginUser(loginForm,reCaptcha.AuthCode,login.captchaText)"
                #loginForm="ngForm"
              >
                <div class="form-group pt-2">
                  <label class="required">Email</label>
                  <input
                    type="text"
                    class="form-control"
                    required
                    [(ngModel)]="login.email"
                    [ngClass]="{'is-invalid': Email.invalid && (Email.dirty || Email.touched || loginForm.submitted) }"
                    [pattern]="constants.EMAIL_REGEXP"
                    [maxlength]="constants.MaxLengthConstants.Email"
                    name="Email"
                    #Email="ngModel"
                    placeholder="Email"
                  />
                  <div
                    class="invalid-feedback"
                    [ngStyle]="{display:Email.invalid &&
                      (Email.dirty || Email.touched || loginForm.submitted) ? 'block': 'none'}"
                  >
                    <div [hidden]="!Email?.errors?.required">
                      <span class="fa fa-exclamation-circle"></span> Email is
                      required
                    </div>
                    <div [hidden]="!Email?.errors?.pattern">
                      Enter valid Email
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label class="required">Password</label>
                  <!-- [pattern]="constants.PASSWORD_REGEXP" -->
                  <input
                    type="password"
                    class="form-control"
                    required
                    [(ngModel)]="login.password"
                    [ngClass]="{'is-invalid': Password.invalid && (Password.dirty || Password.touched || loginForm.submitted) }"
                    name="Password"
                    #Password="ngModel"
                    placeholder="Password"
                    [maxLength]="15"
                    [minLength]="10"
                  />
                  <div
                    class="invalid-feedback"
                    [ngStyle]="{display:Password.invalid &&
                      (Password.dirty || Password.touched || loginForm.submitted) ? 'block': 'none'}"
                  >
                    <div [hidden]="!Password?.errors?.required">
                      <span class="fa fa-exclamation-circle"></span> Password is
                      required
                    </div>
                    <div [hidden]="!Password?.errors?.minLength">
                      Enter valid password
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form-group"
                  >
                    <div class="row">
                      <div class="col-3 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                        <img
                          *ngIf="captchaImagSource"
                          height="35"
                          src="{{captchaImagSource}}"
                        />
                        <i
                          *ngIf="!captchaImagSource"
                          class="fa fa-spinner fa-2x fa-fw text-black-50"
                          [class.fa-pulse]="!captchaImagSource"
                        ></i>
                      </div>
                      <div class="col-2 col-sm-2 9ol-md-2 col-lg-2 col-xl-2">
                        <button
                          type="button"
                          class="btn btn-light float-left"
                          (click)="getAuthCode()"
                        >
                          <i class="fas fa-sync"></i>
                        </button>
                      </div>
                      <div class="col-7 col-sm-7 9ol-md-7 col-lg-7 col-xl-7">
                        <div>
                          <input
                            name="CaptchText"
                            #CaptchText="ngModel"
                            [(ngModel)]="login.captchaText"
                            type="text"
                            maxlength="6"
                            required
                            placeholder="Type the given text"
                            [ngClass]="{'is-invalid': CaptchText.invalid && (CaptchText.dirty || CaptchText.touched || loginForm.submitted) }"
                            class="form-control"
                          />
                          <div
                            class="invalid-feedback"
                            [ngStyle]="{display:CaptchText.invalid &&
                      (CaptchText.dirty || CaptchText.touched || loginForm.submitted) ? 'block': 'none'}"
                          >
                            <div [hidden]="!CaptchText?.errors?.required">
                              <span class="fa fa-exclamation-circle"></span>
                              Text is required
                            </div>
                          </div>
                          <div
                            class="invalid-feedback"
                            [ngStyle]="{display: isCaptchaValid || (CaptchText.invalid &&
                      (CaptchText.dirty || CaptchText.touched || loginForm.submitted)) ? 'none': 'block'}"
                          >
                            <div>
                              Enter valid text
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div
                  class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form-group"
                >
                  <input
                    name="CaptchText"
                    #CaptchText="ngModel"
                    [(ngModel)]="login.captchaText"
                    type="text"
                    maxlength="6"
                    required
                    placeholder="Enter the text as displayed in above image"
                    [ngClass]="{'is-invalid': CaptchText.invalid && (CaptchText.dirty || CaptchText.touched || loginForm.submitted) }"
                    (blur)="validateCaptch(reCaptcha.AuthCode,login.captchaText)"
                    class="form-control"
                  />
                  <div
                    class="invalid-feedback"
                    [ngStyle]="{display:CaptchText.invalid &&
                      (CaptchText.dirty || CaptchText.touched || loginForm.submitted) ? 'block': 'none'}"
                  >
                    <div [hidden]="!CaptchText?.errors?.required">
                      <span class="fa fa-exclamation-circle"></span> Text is
                      required
                    </div>
                    <div [hidden]="!CaptchText?.errors?.pattern">
                      Enter valid text
                    </div>
                  </div>
                  <div
                    class="invalid-feedback"
                    [ngStyle]="{display: isCaptchaValid ? 'none': 'block'}"
                  >
                    <div>
                      Enter valid text
                    </div>
                  </div>
                </div> -->
                </div>
                <div class="row">
                  <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="form-group">
                      <input
                        type="checkbox"
                        name="RememberMeChaeck"
                        #RememberMeChaeck="ngModel"
                        [(ngModel)]="RememberMe"
                        [checked]="RememberMe"
                      /><label
                        class="pl-1 font-weight-bold"
                        for="RememberMeChaeck"
                        >Remember me?</label
                      >
                    </div>
                  </div>
                  <div
                    class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6 float-right"
                  >
                    <div class="form-group">
                      <a
                        routerLink="/forgot-password"
                        class="color-blue float-right font-weight-bold"
                        >Forgot password?</a
                      >
                      <!-- <a
                  class="color-blue txt-bold font-weight-bold float-right"
                  id="GenerateOtp"
                  (click)="hideOtpTxtBox()"
                  >Generate OTP</a
                > -->
                    </div>
                  </div>
                </div>
                <div class="form-group pt-2 mb-4">
                  <button
                    type="submit"
                    class="btn btn-primary col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  >
                    Login
                  </button>
                </div>
                <div class="row">
                  <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div class="form-group font-weight-bold text-center">
                    <span
                      >Don't have an account?
                      <a routerLink="/registration" class="color-blue">
                        Register Now</a
                      ></span
                    >
                  </div>
                </div> -->

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4"
                  >
                    <a
                      class="btn btn-outline-lightning btn-block btn-sign-up"
                      (click)="registrationAs('STUDENT')"
                      ><div class="btn-link-text">
                        <p>
                          If you are a student, choose this option to create new
                          account.
                        </p>
                      </div>
                      Register as a student
                    </a>
                  </div>

                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <a
                      class="btn btn-outline-lightning btn-block btn-sign-up"
                      (click)="registrationAs('TEACHER')"
                      ><div class="btn-link-text">
                        <p>
                          If you are a teacher, choose this option to create new
                          account.
                        </p>
                      </div>
                      Register as a teacher
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #showmessage>
  <div class="modal-header px-4 py-3">
    <h4 class="modal-title pull-left text-info"><b>Important</b></h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body px-4 py-3">
    <span style="color: black;">
      <b>Dear Subscribers</b>,<br /><br/>

      We are pleased to announce that ART has been given an upgrade that will greatly improve your user experience and service.

      A few of the upgrades made includes a change in the way you can access the ART Portal and will require that you update your previous login credentials.<br /><br />

      If you are a current subscriber then your account has been updated and requires you to follow the procedures as follows:<br/><br/>

      <b>Previous Login Credentials</b><br />
      Username – Email ID <span style="font-size: 12px;">(Used to register your account)</span><br />
      Password&nbsp;&nbsp;– ******<br /><br />

      <b>New Login Credentials</b><br />
      Username –&nbsp;Email ID <span style="font-size: 12px;">(Used to register your account)</span><br />
      Password&nbsp;&nbsp;–&nbsp;******<span style="font-size: 12px;margin-left: 2.5%;">(Sent to your registered Email ID)</span><br/><br/>

      We have also reached out to you via email with your ’New Login Credentials’.<br/><br/>

      If you haven't received your new password or if you have forgotten your new password, please use the Forgot password option provided on the login screen to reset and change the password.<br/><br/>

      If you have forgotten your registered Email ID or the Email ID you are trying to use is not working, please contact us on
      <b><a  href="mailto:support@askrealteachers.com">support@askrealteachers.com</a></b>.<br/><br/>

      Thank you.
    </span>
  </div>
</ng-template>

<ng-template #getUserData>
  <div class="modal-header">
    <h6 class="modal-title pull-left">
      <b>Please enter required fields to send request to administrator.</b>
    </h6>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="gtusrModlRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body px-2">
    <form class="" #getData="ngForm" (ngSubmit)="SubmitUserData(getData)">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label for="" class="required">First Name</label>
              <input
                placeholder="First Name"
                type="text"
                name="FirstName"
                #FirstName="ngModel"
                [(ngModel)]="missingUser.FirstName"
                class="form-control"
                [pattern]="constants.NAME_REGEXP"
                [maxlength]="constants.MaxLengthConstants.Name"
                required
              />
              <div
                class="invalid-feedback"
                [ngStyle]="{display:FirstName.invalid &&
                        (FirstName.dirty || FirstName.touched || getData.submitted) ? 'block': 'none'}"
              >
                <div [hidden]="!FirstName?.errors?.required">
                  <span class="fa fa-exclamation-circle"></span> First Name
                  required
                </div>
                <div [hidden]="!FirstName?.errors?.pattern">
                  <span class="fa fa-exclamation-circle"></span> Enter valid
                  First Name
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label for="" class="required">Last Name</label>
              <input
                placeholder="Last Name"
                type="text"
                name="LastName"
                #LastName="ngModel"
                [(ngModel)]="missingUser.LastName"
                class="form-control"
                required
              />
              <div
                class="invalid-feedback"
                [ngStyle]="{display:LastName.invalid &&
                        (LastName.dirty || LastName.touched || getData.submitted) ? 'block': 'none'}"
              >
                <div [hidden]="!LastName?.errors?.required">
                  <span class="fa fa-exclamation-circle"></span> Last Name
                  required
                </div>
                <div [hidden]="!LastName?.errors?.pattern">
                  <span class="fa fa-exclamation-circle"></span> Enter valid
                  Last Name
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label for="" class="required">Old UserName</label>
              <input
                placeholder="Old UserName"
                type="text"
                name="UserName"
                #UserName="ngModel"
                [(ngModel)]="missingUser.UserName"
                class="form-control"
                required
              />
              <div
                class="invalid-feedback"
                [ngStyle]="{display:UserName.invalid &&
                          (UserName.dirty || UserName.touched || getData.submitted) ? 'block': 'none'}"
              >
                <div [hidden]="!UserName?.errors?.required">
                  <span class="fa fa-exclamation-circle"></span> Old UserName
                  required
                </div>
                <div [hidden]="!UserName?.errors?.pattern">
                  <span class="fa fa-exclamation-circle"></span> Enter valid Old
                  UserName
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label class="required" for="">EmailID</label>
              <input
                placeholder="EmailID"
                type="email"
                name="EmailID"
                #EmailID="ngModel"
                [(ngModel)]="missingUser.EmailID"
                class="form-control"
                required
                [pattern]="constants.EMAIL_REGEXP"
              />
              <div
                class="invalid-feedback"
                [ngStyle]="{display:EmailID.invalid &&
                            (EmailID.dirty || EmailID.touched || getData.submitted) ? 'block': 'none'}"
              >
                <div [hidden]="!EmailID?.errors?.required">
                  <span class="fa fa-exclamation-circle"></span> Email is
                  required
                </div>
                <div [hidden]="!EmailID?.errors?.pattern">
                  <span class="fa fa-exclamation-circle"></span> Enter valid
                  Email
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right col-12">
        <button class="btn btn-primary" type="submit">Send request</button>
      </div>
    </form>
  </div>
</ng-template>

<!-- <ng-template #requestReceived>
  <div class="modal-header px-4 py-3">
    <h4 class="modal-title pull-left">Your Request received.</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="requestRecived.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body px-4 py-3">
    We have recieved your request to retrive login credentials successfully.<br />
    You will be contacted via email or phone by admin to provide you the
    requested credentials.<br /><br />
    We appreciate your co-operation, if you are not contacted within 2 working
    days please let us know on support@askrealteacehrs.com<br />
    <h5>Thank you.</h5>
  </div>
</ng-template> -->
