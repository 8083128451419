import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ProfilePicComponent } from './components/profile-pic/profile-pic.component';
import { SafePipe } from './pipe/sanitize-url.pipe';
import { NgxStarsModule } from 'ngx-stars';


@NgModule({
  declarations: [
    MainLayoutComponent,
    LoginLayoutComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    OnlyNumberDirective,
    ProfilePicComponent,
    SafePipe
  ],
  imports: [
    RouterModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    NgxSpinnerModule,
    NgxStarsModule
  ],
  exports: [OnlyNumberDirective, ProfilePicComponent,SafePipe],
})
export class SharedModule {}
