import { BaseEntity } from './base-entity';
export class UserAccount extends BaseEntity {
  FirstName: string;
  LastName: string;
  Password: string;
  Email: string;
  Phone: string;
  UserRoleId: number;
  Gender: string;
  DateOfBirth: Date;
  Address: string;
  State: string;
  Zip: string;
  Country: string;
  IsActive: boolean;
  IsApproved: boolean;
  IsPhoneVerified: boolean;
  IsFirstLogin: boolean;
  LastLoginTime: Date;
  SessionId: string;
  SchoolId: number;
  GradeId: number;
  LanguageId: number;
  ProfilePictureId: number;
  ProfilePictureThumbId: number;
  ProfileUrl: string;
  ProfileThumbUrl: string;
  AboutMe: string;
  SchoolName: string;
  City: string;
  DeploymentId: number;
  Roles: string[];
  SchoolSubscriptionCode: string;
  StripeCustomerId: string;
  IsEmailVerified:boolean;
  IsFreePlanAvail:boolean;
  LastLogoutTime:Date;
  IsARTUser:boolean;
  UserName:string;
  // ContractDocumentID: number;
  constructor() {
    super();
    this.FirstName = null;
    this.LastName = null;
    this.Password = null;
    this.Email = null;
    this.Phone = null;
    this.UserRoleId = null;
    this.Gender = null;
    this.DateOfBirth = null;
    this.Address = null;
    this.State = null;
    this.Zip = null;
    this.Country = 'United States';
    this.IsActive = true;
    this.IsApproved = false;
    this.IsPhoneVerified = false;
    this.IsFirstLogin = false;
    this.LastLoginTime = null;
    this.SessionId = null;
    this.SchoolId = null;
    this.GradeId = null;
    this.LanguageId = null;
    this.ProfilePictureId = null;
    this.ProfilePictureThumbId = null;
    this.ProfileUrl = null;
    this.ProfileThumbUrl = null;
    this.AboutMe = null;
    this.SchoolName = null;
    this.City = null;
    this.DeploymentId = 1;
    this.Roles = new Array<string>();
    this.SchoolSubscriptionCode = null;
    this.StripeCustomerId = null;
    this.IsEmailVerified = false;
    this.IsFreePlanAvail = false;
    this.LastLoginTime = null;
    this.IsARTUser = true;
    // this.ContractDocumentID = null;
  }
}
