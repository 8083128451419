<div class="wrapper default-theme bg-white">
  <!-- <nav class="navbar navbar-expand-md fixed-top navbar-dark flex-fill">
    <div class="navbar-header pl-2 pr-2 ml-3 text-center">
      <a class="navbar-brand m-0 w-100" href="#">
        <img src="./assets/img/logo-new.png" alt="ART logo" width="35px" />
        <span class="ml-1">Ask Real Teachers</span>
      </a>
    </div>
  </nav> -->

  <main>
    <div class="pages login-form-image">
      <video
        *ngIf="true"
        class="bgvid"
        [autoplay]="true"
        [muted]="true"
        preload="auto"
        loop
        onloadedmetadata="this.muted = true"
      >
        <source
          src="https://storage-askrealteachers.b-cdn.net/childrentablet_Trim.mp4"
          type="video/webm"
        />
      </video>
      <div class="row p-0 m-0 mt-3">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </main>
</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.99)"
  size="medium"
  color="#fff"
  type="line-spin-fade"
  [fullScreen]="true"
  ><p style="color: white;">Loading...</p></ngx-spinner
>
