import { Component, OnInit, NgZone } from "@angular/core";
import { AppService } from "src/app/services/app.service";
import { Router } from "@angular/router";
import { UserAccount } from "../../models/user-account";
import { StudentService } from "src/app/student/student.service";
import { UserSubscription } from "../../models/user-subscription";
import { EncryptDecryptParametersService } from "src/app/services/encrypt-decrypt-parameters.service";
import { UserRole } from "../../models/user-role";
import { NgxSpinnerService } from "ngx-spinner";
import { ChatService } from "src/app/common-feature/chat/chat.service";
import { Notify } from "src/app/common-feature/chat/model/chat-summary";
import { ToastrService } from "ngx-toastr";
import { UserNotification } from "../../models/userNotification";
import { ToasterPosition } from "../../enums/toaster-position";
import { take } from "rxjs/operators";
import { element } from "protractor";
import { UserLoginDetails } from "../../models/UserLoginDetails";
import { toInt } from "ngx-bootstrap/chronos/utils/type-checks";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  isCollapsed = true;
  isquestionsubmited = false;
  currentUser: UserAccount;
  userSubscription: UserSubscription;
  userNotification: UserNotification[];
  private studentNavLink: ProfileNavLink[];
  private teacherNavLink: ProfileNavLink[];
  private adminNavLink: ProfileNavLink[];
  role: string;
  navLinks: ProfileNavLink[];
  userRole: UserRole;
  notifications: Notify[];
  isPlanSubscribed: boolean;
  positionClass: ToasterPosition;
  userNotifications: UserNotification;
  userLoginDetails: UserLoginDetails;
  isHideChangePhoneandEmail = false;
  constructor(
    private appService: AppService,
    private studentService: StudentService,
    private spinner: NgxSpinnerService,
    private chatService: ChatService,
    private router: Router,
    private _ngZone: NgZone,
    private toastr: ToastrService,
    private encryptDecryptParametersService: EncryptDecryptParametersService
  ) {
    this.currentUser = new UserAccount();
    this.role = sessionStorage.getItem("Role");
    if (this.role == null) {
      this.role = localStorage.getItem("Role");
      sessionStorage.setItem("Role", this.role);
    }
    this.currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
    if (this.currentUser == null) {
      this.currentUser = JSON.parse(localStorage.getItem("CurrentUser"));
      sessionStorage.setItem("CurrentUser", JSON.stringify(this.currentUser));
    }
    this.userSubscription = new UserSubscription();
    this.studentService.ischanageQuestionCount$.subscribe((value) => {
      if (value) {
        this.userSubscription = value;
      }
    });
    appService.NotificationCountChanged.subscribe((res: any) => {
      if (res) {
        this.getNotifications(this.currentUser.Id, null);
      }
    });
    this.userRole = new UserRole();
    this.notifications = new Array<Notify>();
    this.subscribeToEvents();
    this.userNotification = new Array<UserNotification>();
    this.userNotifications = new UserNotification();
    this.userLoginDetails = new UserLoginDetails();
  }
  ngOnInit() {
    this.assignProfileNavLink();
    this.getRemainingQuestionLimit();
    this.IsStduentSubscribedPlan();
    // this.getNotifications(this.currentUser.Id,null);
  }

  toggleSidebarPin() {
    this.appService.toggleSidebarPin();
  }
  toggleSidebar() {
    this.appService.toggleSidebar();
  }

  IsStduentSubscribedPlan() {
    this.studentService.IsUserSubscribePlan.subscribe((res) => {
      this.isPlanSubscribed = res;
    });
  }

  getRole() {
    this.spinner.show();
    const roleObj = { RoleId: this.currentUser.UserRoleId };
    this.appService.getRoleById(roleObj).subscribe(
      (res) => {
        if (!res.HasErrors) {
          this.userRole = res;
        } else {
          this.spinner.hide();
        }
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  viewProfile() {
    if (this.role === "Admin") {
      this.router.navigate(["/admin/view-profile"]);
    } else if (this.role === "Teacher") {
      this.router.navigate(["/teacher/teacherProfile"]);
    } else {
      this.router.navigate(["/student/viewStudentProfile"]);
    }
  }

  getRemainingQuestionLimit() {
    const usersubscritpion = { Id: this.currentUser.Id };
    if (this.role === "Student") {
      this.studentService.getRemainingQuestionLimit(usersubscritpion).subscribe(
        (res) => {
          if (!res.HasErrors) {
            this.userSubscription = res.Id === undefined ? null : res;
            if (this.userSubscription != null) {
              this.studentService.IsUserSubscribePlan.next(true);
              if (
                this.userSubscription.ParentSubscriptionCategory == 2 &&
                !this.currentUser.IsARTUser
              ) {
                this.studentNavLink = this.studentNavLink.splice(4, 2);
                this.isHideChangePhoneandEmail = true;
              }
            }
            this.studentService.chanageQuestionCount(this.userSubscription);
          }
        },
        () => {}
      );
    }
  }
  private assignProfileNavLink() {
    this.studentNavLink = [
      new ProfileNavLink(
        "View Profile",
        ["/student/viewStudentProfile"],
        "fa fa-user pr-1"
      ),
      new ProfileNavLink(
        "Edit Profile",
        ["/student/editStudentProfile"],
        "fa fa-user-edit pr-1",
        { id: this.currentUser.Id }
      ),
      new ProfileNavLink(
        "My Subscription Plan",
        ["/student/mySubscriptionPlan"],
        "fa fa-paper-plane pr-1"
      ),
      new ProfileNavLink(
        "Change Password",
        ["/change-password"],
        "fa fa-lock pr-1"
      ),
      new ProfileNavLink(
        "Change Email",
        ["/change-email"],
        "fa fa-envelope pr-1"
      ),
      new ProfileNavLink(
        "Change Phone",
        ["/change-phone"],
        "fa fa-phone-square pr-1"
      ),
    ];

    this.teacherNavLink = [
      new ProfileNavLink(
        "View Profile",
        ["/teacher/teacherProfile"],
        "fa fa-user pr-1"
      ),
      // new ProfileNavLink(
      // 'Edit Profile',
      // ['/teacher/editTeacherProfile'],
      // 'fa fa-user-edit pr-1'
      // ),
      new ProfileNavLink(
        "Teacher Contract",
        ["/teacher/teacherContract"],
        "fa fa-file-signature pr-1"
      ),
      new ProfileNavLink(
        "Change Password",
        ["/change-password"],
        "fa fa-lock pr-1"
      ),
      new ProfileNavLink(
        "Change Email",
        ["/change-email"],
        "fa fa-envelope pr-1"
      ),
      new ProfileNavLink(
        "Change Phone",
        ["/change-phone"],
        "fa fa-phone-square pr-1"
      ),
    ];

    this.adminNavLink = [
      new ProfileNavLink(
        "View Profile",
        ["/admin/view-profile"],
        "fa fa-user pr-1"
      ),
      new ProfileNavLink(
        "Edit Profile",
        ["/admin/edit-profile"],
        "fa fa-user-edit pr-1"
      ),
      new ProfileNavLink(
        "Change Email",
        ["/change-email"],
        "fa fa-envelope pr-1"
      ),
      new ProfileNavLink(
        "Change Phone",
        ["/change-phone"],
        "fa fa-phone-square pr-1"
      ),
    ];

    this.navLinks = this.roleBasedNavLinks(); // .concat(this.commonNavLink);
  }

  private roleBasedNavLinks() {
    switch (this.role) {
      case "Student":
        return this.studentNavLink;
      case "Teacher":
        return this.teacherNavLink;
      case "Administrator":
        return this.adminNavLink;
    }
  }

  private subscribeToEvents(): void {
    //for notification
    this.getNotifications(this.currentUser.Id, null);
    //  if(this.notifications.length!=0){
    //   this.chatService.playAudio();
    //  }

    this.chatService.notificationReceived.subscribe((notification: Notify) => {
      this._ngZone.run(() => {
        this.getNotifications(this.currentUser.Id, notification);
      });
    });
  }

  logout() {
    // this.router.navigate(['login']);
    this.UpdateLastLoginTime();
  }
  getNotifications(id, notification) {
    // this.spinner.show();
    this.chatService.getNotifications(id).subscribe(
      (res) => {
        if (!res.HasErrors) {
          this.userNotification =
            res.Rows !== null ? res.Rows : new Array<UserNotification>();
          this.spinner.hide();
          if (
            notification != null &&
            this.userNotification.length != 0 &&
            notification.userAccountId != this.currentUser.Id
          ) {
            if (
              this.userNotification.find(
                (x) => x.Id == notification.userNotifcationId
              )
            ) {
              this.ShowToster(notification);
              this.chatService.playAudio();
              this.spinner.hide();
            }
            this.spinner.hide();
          }
          if (this.userNotification.length !== 0) {
            this.spinner.hide();
          }
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  redirectToChat(notificationId) {
    this.spinner.show();
    this.getNotifications(this.currentUser.Id, null);
    this.userNotifications = this.userNotification.find(
      (x) => x.Id == notificationId
    );
    if (this.userNotifications == null || this.userNotifications == undefined) {
      this.getNotifications(this.currentUser.Id, null);
      this.userNotifications = this.userNotification.find(
        (x) => x.Id == notificationId
      );
    }
    const index = this.userNotification.findIndex(
      (u) => u.Id === notificationId
    );
    if (index !== -1) {
      this.userNotification.splice(index, 1);
    }

    const notifications = <UserNotification>{
      Id: this.userNotifications.Id,
      Description: this.userNotifications.Description,
      IsRead: true,
      Question: {
        QuestionId: this.userNotifications.Question.QuestionId,
        Description: this.userNotifications.Question.Description,
        Creator: {
          Id: this.userNotifications.Question.Creator.Id,
          FirstName: this.userNotifications.Question.Creator.FirstName,
          LastName: this.userNotifications.Question.Creator.LastName,
          Email: this.userNotifications.Question.Creator.Email,
        },
      },
      Creator: {
        Id: this.userNotifications.Creator.Id,
        FirstName: this.userNotifications.Creator.FirstName,
        LastName: this.userNotifications.Creator.LastName,
        Email: this.userNotifications.Creator.Email,
      },
      Receiver: {
        Id: this.userNotifications.Receiver.Id,

        FirstName: this.userNotifications.Receiver.FirstName,
        LastName: this.userNotifications.Receiver.LastName,
        Email: this.userNotifications.Receiver.Email,
      },
      CreatedById: this.userNotifications.CreatedById,
      CreatedDate: this.userNotifications.CreatedDate,
      UpdatedById: this.currentUser.Id,
    };

    this.chatService.updateNotification(notifications).subscribe((res) => {
      this.spinner.hide();
      if (!res.HasErrors) {
        this.spinner.show();
        this.getNotifications(this.currentUser.Id, null);
        this.spinner.hide();
        const encryptedstring = this.encryptDecryptParametersService.encrypt(
          `id=${this.userNotifications.Question.QuestionId}`
        );
        if (this.router.url.includes("/student/question-chat/conversation")) {
          this.router.navigate([
            "/student/question-chat/conversation/",
            encryptedstring,
          ]);
        } else {
          this.router.navigateByUrl(
            `/student/question-chat/conversation?${encryptedstring}`
          );
        }
        // this.userNotification = res;
      }
    });
  }

  redirectToChatForNotification(notification) {
    this.userNotifications = notification;
    const index = this.userNotification.findIndex(
      (u) => u.Id === this.userNotifications.Id
    );
    if (index !== -1) {
      this.userNotification.splice(index, 1);
    }

    const notifications = <UserNotification>{
      Id: this.userNotifications.Id,
      Description: this.userNotifications.Description,
      IsRead: true,
      Question: {
        QuestionId: this.userNotifications.Question.QuestionId,
        Description: this.userNotifications.Question.Description,
        Creator: {
          Id: this.userNotifications.Question.Creator.Id,
          FirstName: this.userNotifications.Question.Creator.FirstName,
          LastName: this.userNotifications.Question.Creator.LastName,
          Email: this.userNotifications.Question.Creator.Email,
        },
      },
      Creator: {
        Id: this.userNotifications.Creator.Id,
        FirstName: this.userNotifications.Creator.FirstName,
        LastName: this.userNotifications.Creator.LastName,
        Email: this.userNotifications.Creator.Email,
      },
      Receiver: {
        Id: this.userNotifications.Receiver.Id,

        FirstName: this.userNotifications.Receiver.FirstName,
        LastName: this.userNotifications.Receiver.LastName,
        Email: this.userNotifications.Receiver.Email,
      },
      CreatedById: this.userNotifications.CreatedById,
      CreatedDate: this.userNotifications.CreatedDate,
      UpdatedById: this.currentUser.Id,
    };

    this.chatService.updateNotification(notifications).subscribe((res) => {
      if (!res.HasErrors) {
        const encryptedstring = this.encryptDecryptParametersService.encrypt(
          `id=${this.userNotifications.Question.QuestionId}`
        );
        this.router.navigateByUrl(
          `/student/question-chat/conversation?${encryptedstring}`
        );
      }
    });
  }
  ShowToster(notification) {
    if (notification.userAccountId !== this.currentUser.Id) {
      this.toastr
        .info(
          `<a ng-click='redirectToChat(${notification});'>${notification.description}</a>`,
          "New Notification",
          {
            closeButton: true,
            timeOut: 30000,
            progressBar: false,
            positionClass: "toast-bottom-right",
            enableHtml: true,
            newestOnTop: true,
            tapToDismiss: false,
          }
        )
        .onTap.pipe(take(1))
        .subscribe(() => this.redirectToChat(notification.userNotifcationId));
    }
  }

  UserLogout() {
    this.currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
    this.userLoginDetails.firstName = this.currentUser.FirstName;
    this.userLoginDetails.lastName = this.currentUser.LastName;
    this.userLoginDetails.activityType = "Logout";
    this.userLoginDetails.userAccountId = this.currentUser.Id;
    if (this.currentUser.UserRoleId == 2) {
      this.userLoginDetails.rolePrefix = "TR";
    } else if (this.currentUser.UserRoleId == 3) {
      this.userLoginDetails.rolePrefix = "ST";
    } else if (this.currentUser.UserRoleId == 1) {
      this.userLoginDetails.rolePrefix = "AD";
    }
    this.chatService.UserLoginOrLogout(this.userLoginDetails);
  }

  UpdateLastLoginTime() {
    this.spinner.show();
    this.UserLogout();
    const userDetails = { UserDetails: this.currentUser };
    this.appService.UpdateLastLogout(userDetails).subscribe((res) => {
      if (!res.HasErrors) {
        this.spinner.hide();
        sessionStorage.clear();
        localStorage.clear();
        this.router.navigate(["/login"]);
      } else {
        this.spinner.hide();
      }
    });
  }
}

class ProfileNavLink {
  constructor(
    public title: string,
    public route: string[],
    public iconClass: string,
    public queryParams?: { [key: string]: string | number }
  ) {}
}
