import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-teacher-terms-condition',
  templateUrl: './teacher-terms-condition.component.html',
  styleUrls: ['./teacher-terms-condition.component.scss']
})
export class TeacherTermsConditionComponent implements OnInit {

  constructor( private appService: AppService) { }

  ngOnInit(): void {
  }

  goBack() {
    this.appService.goBack();
  }
}
