<div class="row pl-3">
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <button class="btn btn-outline-lightning float-right" (click)="goBack()">
      <i class="fa fa-angle-left mr-1"></i>Back
    </button>
  </div>
</div>
<div class="row pt-3">
  <div class="col-1 col-sm-1 col-md-3 col-lg-4 col-xl-4"></div>
  <div
    class="col-10 col-sm-10 col-md-5 col-lg-4 col-xl-4 mb-4 align-items-stretch"
  >
    <div class="card border-0 h-100">
      <div class="card-title mb-1 p-3">
        <h5>Change Your Password</h5>
      </div>
      <div class="card-body">
        <form
          novalidate
          autocomplete="off"
          (ngSubmit)="validateOldPassword(validatePasswordForm)"
          #validatePasswordForm="ngForm"
          [hidden]="isOldPasswordVerify"
        >
        <!-- [pattern]="constants.PASSWORD_REGEXP" -->
          <div class="form-group">
            <label class="required">Old Password</label>
            <input
              type="password"
              class="form-control"
              required
              [(ngModel)]="oldPassword"
              [ngClass]="{'is-invalid': OldPassword.invalid && (OldPassword.dirty || OldPassword.touched || validatePasswordForm.submitted) }"
              name="OldPassword"
              #OldPassword="ngModel"
              placeholder="Old Password"
              [maxLength]="15"
              [minLength]="10"
            />
            <div
              class="invalid-feedback"
              [ngStyle]="{display:OldPassword.invalid &&
          (OldPassword.dirty || OldPassword.touched || validatePasswordForm.submitted) ? 'block': 'none'}"
            >
              <div [hidden]="!OldPassword?.errors?.required">
                <span class="fa fa-exclamation-circle"></span> Old password is
                required
              </div>
            </div>
            <!-- <div
              *ngIf="!isOldPasswordVerify"
              class="invalid-feedback"
              style="display: block;"
            >
              <span class="fa fa-exclamation-circle"></span>
              Please enter correct password
            </div> -->
          </div>
          <div class="form-group row">
            <div class="col-6 col-sm-6 col-md-7 col-lg-9 col-xl-9 pr-0">
              <button type="submit" class="btn btn-primary mb-2 float-right">
                Next
              </button>
            </div>
            <div class="col-6 col-sm-6 col-md-5 col-lg-3 col-xl-3">
              <button
                type="button"
                class="btn btn-secondary mb-2 float-right"
                (click)="goBack()"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
        <form
          novalidate
          autocomplete="off"
          (ngSubmit)="confirmChangePassword(changePasswordForm)"
          #changePasswordForm="ngForm"
          [hidden]="!isOldPasswordVerify"
        >
          <div class="form-group">
            <div class="form-group">
              <label class="required">New Password</label
              ><span
                class="fa fa-info-circle text-info float-right"
                containerClass="pop-container"
                popover=" The password should be 10 to 15 characters
              long with at least 1 uppercase,1 lowercase, 1
              special character and 1 number."
                triggers="mouseenter:mouseleave"
                placement="auto"
              ></span>
              <input
                type="password"
                class="form-control"
                required
                [(ngModel)]="newPassword"
                [ngClass]="{'is-invalid': Password.invalid && (Password.dirty || Password.touched || changePasswordForm.submitted) }"
                [pattern]="constants.PASSWORD_REGEXP"
                name="Password"
                #Password="ngModel"
                placeholder="New Password"
                [maxLength]="15"
                [minLength]="10"
              />
              <div
                class="invalid-feedback"
                [ngStyle]="{display:Password.invalid &&
            (Password.dirty || Password.touched || changePasswordForm.submitted) ? 'block': 'none'}"
              >
                <div [hidden]="!Password?.errors?.required">
                  <span class="fa fa-exclamation-circle"></span> Password is
                  required
                </div>
                <div [hidden]="!Password?.errors?.pattern">
                  Enter valid password
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="form-group">
              <label class="required">Confirm Password</label>
              <input
                type="password"
                class="form-control"
                required
                [(ngModel)]="confirmPassword"
                [ngClass]="{'is-invalid': ConfirmPassword.invalid && (ConfirmPassword.dirty || ConfirmPassword.touched || changePasswordForm.submitted) }"
                [pattern]="constants.PASSWORD_REGEXP"
                (ngModelChange)="checkPassword()"
                name="ConfirmPassword"
                #ConfirmPassword="ngModel"
                [maxLength]="15"
                [minLength]="10"
                placeholder="Confirm Password"
              />

              <div
                class="invalid-feedback"
                [ngStyle]="{display:ConfirmPassword.invalid &&
                (ConfirmPassword.dirty || ConfirmPassword.touched || changePasswordForm.submitted) ? 'block': 'none'}"
              >
                <div [hidden]="!ConfirmPassword?.errors?.required">
                  <span class="fa fa-exclamation-circle"></span> Confirm
                  Password is required
                </div>
                <!-- <div [hidden]="!ConfirmPassword?.errors?.pattern">
                  Enter valid Confirm Password
                </div> -->
              </div>
              <div
                *ngIf="!checkPassword() && !ConfirmPassword?.errors?.required"
                class="invalid-feedback"
                style="display: block;"
              >
                <span class="fa fa-exclamation-circle"></span>
                Password mismatch
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-8 col-sm-4 col-md-9 col-lg-9 col-xl-9 pr-0">
              <button type="submit" class="btn btn-primary mb-2 float-right">
                Submit
              </button>
            </div>
            <div class="col-4 col-sm-4 col-md-3 col-lg-3 col-xl-3">
              <button
                type="button"
                class="btn btn-secondary mb-2 float-right"
                (click)="goBack()"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="col-1 col-sm-1 col-md-4 col-lg-4 col-xl-4"></div>
</div>
