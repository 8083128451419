import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { DmsDocument } from '../../models/dms-document';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-profile-pic',
  templateUrl: './profile-pic.component.html',
  styleUrls: ['./profile-pic.component.scss'],
})
export class ProfilePicComponent implements OnInit {
  @Input() type: string;
  @Input() userId: string;
  @Input() documentId: number;
  @Input() Imgsrc: string;
  
  dmsDocument: DmsDocument;
  imgSrc: string;
  constructor(private appService: AppService, private sanitizer: DomSanitizer) {
    this.dmsDocument = new DmsDocument();
  }

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      const Id = changes.documentId?.currentValue;
      // this.Imgsrc = changes.Imgsrc?.currentValue;
      if (Id !== null) {
        this.getProfilePicture(Id);
      }
    }
  }

  getProfilePicture(documentId) {
    const doc = { DocumentId: documentId };
    this.appService.getProfilePicture(doc).subscribe((res) => {
      if (!res.HasErrors) {
        this.dmsDocument = res;
      }
    });
  }

  public getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
