import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { throwError, Observable, BehaviorSubject, of } from "rxjs";
import {
  catchError,
  filter,
  take,
  switchMap,
  finalize,
  tap,
  map,
} from "rxjs/operators";
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private AUTH_HEADER = "Authorization";
  private token = "secrettoken";
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!request.headers.has("Content-Type")) {
      request = request.clone({
        headers: request.headers.set(
          "Authorization",
          `Bearer ${
            sessionStorage.getItem("AccessToken") == null
              ? localStorage.getItem("AccessToken")
              : sessionStorage.getItem("AccessToken")
          }`
        ),
      });
    }

    request = this.addAuthenticationToken(request);

    return <any>next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response and headers you want
          if (event && event.status === 401) {
            // console.log('event--->>>', event);
            const headerValue = event.headers.get("Bearer");
            if (headerValue != null) {
              const token = headerValue;
              this.token = headerValue;
              sessionStorage.setItem("AccessToken", token);
            }
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401) {
          // 401 errors are most likely going to be because we have an expired token that we need to refresh.
          if (this.refreshTokenInProgress) {
            const refreshToken = sessionStorage.getItem("RefreshToken");
            if (refreshToken != null) {
              this.token = refreshToken;
            }
            sessionStorage.setItem("AccessToken", this.token);
            // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
            // which means the new token is ready and we can retry the request again
            this.refreshTokenInProgress = false;
            return this.refreshTokenSubject.pipe(
              filter((result) => result !== null),
              take(1),
              switchMap(() => next.handle(this.addAuthenticationToken(request)))
            );
          } else {
            this.refreshTokenInProgress = true;
            const headerValue = error.headers.get("Bearer");
            if (headerValue != null) {
              const token = headerValue;
              this.token = token;
              if (sessionStorage.getItem("AccessToken") != null) {
                sessionStorage.removeItem("AccessToken");
                sessionStorage.setItem("AccessToken", token);
              }
            }
            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject.next(null);
            return this.refreshAccessToken().pipe(
              switchMap((success: boolean) => {
                this.refreshTokenSubject.next(success);
                sessionStorage.setItem("RefreshToken", this.token);
                return next.handle(this.addAuthenticationToken(request));
              }),
              // When the call to refreshToken completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              finalize(() => (this.refreshTokenInProgress = false))
            );
          }
        } else {
          return throwError(error);
        }
      })
    );
  }
  private refreshAccessToken(): Observable<any> {
    sessionStorage.setItem("RefreshToken", this.token);
    return of("secret token");
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    // If we do not have a token yet then we should not set the header.
    // Here we could first retrieve the token from where we store it.
    if (!this.token) {
      return request;
    }
    // If you are calling an outside domain then do not add the token.
    if (!request.url.match(/www.mydomain.com\//)) {
      // this.token = request.headers.get("authorization").split(" ")[1];
      // request.headers.set(this.AUTH_HEADER,'Bearer '+ this.token);
      // sessionStorage.setItem("AccessToken",this.token);
      return request;
    }
    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, "Bearer " + this.token),
    });
  }
}
