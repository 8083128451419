export class Constants {


  // public static BASE_URL = 'http://localhost:52069/api/values/';

  public static toastrConfig: any = {
    positionClass: 'toast-top-center',
    closeButton: true,
    progressBar: true,
    timeOut: 2000
  };
  public static MaxLengthConstants = {
    Name: 25,
    City: 15,
    Email: 50,
    Phone: 15,
    Password: 15,
    OTP: 8,
    UserName: 20,
    Address: 100
  };
  public static ReservationMaxLengthonstants = {
    Name: 25,
    Email: 50,
    Mobile: 15,
    City: 50,
    Address: 100,
  };
  public static zipMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public static USERNAME = /^\S*$/;
  public static DateFormat = 'MM/dd/yyyy';
  public static EMAIL_REGEXP = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  public static NUMBER_REGEXP = '^[a-zA-Z0-9]+$';
  // public static ALPHANUMRIC_REGEXP = /^[A-Za-z\d\s]+$/;
  public static PHONE_REGEXP = /^[(]{0,1}[0-9]{1}[)]{0,1}[-\s\.]{0,1}[0-9]{5}[-\s\.]{0,1}[0-9]{4}$/;
  public static PASSWORD_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z0-9\S]{8,15}$/;
  public static ADDRESS_REGEXP = '[A-Za-z0-9 ]*[A-Za-z-][A-Za-z0-9 -_!@#$%^&*()?/<>.]*';

  public static readonly CURRENCY = 'USD'; //  For Dollar set it to  'USD' and For Ruppee set it to 'INR' DATE_FORMAT_IMPRESSION
  public static readonly DATE_FORMAT = 'MM-dd-y';
  public static DATE_FORMAT_IMPRESSION = 'MM-dd-y hh:mm:ss';
  public static readonly DATE_FORMAT_DATEPICKER = 'MM/DD/YYYY';
  // tslint:disable-next-line:max-line-length
  // public static readonly EMAIL_REGEXP = '^[A-Za-z0-9!#$%&\'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$';
  // tslint:disable-next-line:max-line-length
  // tslint:disable-next-line:max-line-length
  // public static readonly EMAIL_REGEXP_TS = /^[A-Za-z0-9!#$%&\'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/;
  public static NAME_REGEXP = /^[A-Za-z\/\s\.'-]+$/;
  public static CHECK = /^[^\s]+(\s+[^\s]+)*$/;
  public static NAME_SPACE_REGEXP = /^[^-\s][a-zA-Z0_\s-]+$/;
  public static ALPHANUMRIC_REGEXP = /^[^-\s][a-zA-Z0-9_\s-]+$/;
  public static NOT_SPACE = /^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9\s()-]+$/;
  public static readonly WISH_TEXT = '^[a-zA-Z ]*$';
  public static readonly PHONE_REGEX_US = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
  // public static readonly PHONE_REGEXP-TS = '^[6-9]\d{9}$';
  public static readonly ZIP_REGEXP = /^[0-9]{5}(?:-[0-9]{4})?$/;
  public static readonly FAX_REGEXP = '^(?=(?:D*d){10,12}D*$)[0-9 -()\\/]{1,16}$';
  public static readonly WEBSITE_REGEXP = /^(drive.google.com|docs.google.com|(https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
  public static readonly EXCLUDE_SPACES_REGEXP = '(?!^ +$)^.+$';
  public static readonly URL = '\b((http|https):\/\/?)[^\s()<>]+(?:\([\w\d]+\)|([^[:punct:]\s]|\/?))';
  public static readonly SPECIAL_CHAR_EMAIL = /[~`!#$%^\&*+=\-\[\]\\';/{}|\\":<>\?]/;
  public static readonly SPECIAL_CHAR_MOBILE = /[~`@!#$%\^&*_+=\-\[\]\\';/{}|\\":<>\?]/;
  public static readonly CITY = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/;
  public static SCHOOL_NAME_REGEXP = /^[a-zA-Z0-9\s]+$/;


}
