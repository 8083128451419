import { Account } from 'src/app/common-feature/chat/model/account';

export class UserNotification {
    public Id: string;
    public Description:string;
    public Creator: Account;
    public Receiver: Account;
    public Question:Question;
    public IsRead:boolean;
    public CreatedById: number;
    public UpdatedById: number;
    public UpdatedDate: Date;
    public CreatedDate: Date;
    constructor() {
        this.Id=null;
        this.Description=null;
        this.Creator = new Account();
        this.Receiver = new Account();
        this.Question=new Question();
        this.IsRead=false;
        this.CreatedById=null;
        this.UpdatedById=null;
        this.UpdatedDate=null;
        this.CreatedDate=null;
    }
}

export class Question{
    public QuestionId:string;
    public Description:string;
    public Creator:Account;
    constructor()
    {
        this.QuestionId=null;
        this.Description=null;
        this.Creator=new Account();
    }
}
