import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginLayoutComponent } from "./shared/layouts/login-layout/login-layout.component";
import { LoginComponent } from "./common-feature/components/login/login.component";
import { RegistrationComponent } from "./common-feature/components/registration/registration.component";
import { ForgotPasswordComponent } from "./common-feature/components/forgot-password/forgot-password.component";
import { MainLayoutComponent } from "./shared/layouts/main-layout/main-layout.component";
import { ChangePasswordComponent } from "./common-feature/components/change-password/change-password.component";
import { PrivacyPolicyComponent } from "./common-feature/components/privacy-policy/privacy-policy.component";
import { TeacherTermsConditionComponent } from "./common-feature/components/teacher-terms-condition/teacher-terms-condition.component";
import { StudentSubscriptionPlansComponent } from "./common-feature/components/student-subscription-plans/student-subscription-plans.component";
import { TeacherApproveComponent } from "./common-feature/components/teacher-approve/teacher-approve.component";
import { RegistrationCompletedComponent } from "./common-feature/components/registration-completed/registration-completed.component";
import { OtpVerifiedComponent } from "./common-feature/components/otp-verified/otp-verified.component";
import { SharedModule } from './shared/shared.module';
import { ChangeemailComponent } from './common-feature/components/changeemail/changeemail.component';
import { ChangephoneComponent } from './common-feature/components/changephone/changephone.component';

export const routes: Routes = [
  {
    path: "",
    component: LoginLayoutComponent,
    data: {
      title: "",
    },
    children: [
      {
        path: "",
        // redirectTo: "login",
        pathMatch: "full",
        data: {},
        component:LoginComponent
      },
      {
        path: "login",
        component: LoginComponent,
        data: {},
      },
      {
        path: "registration",
        component: RegistrationComponent,
        data: {},
      },
      {
        path: "registration-completed",
        component: RegistrationCompletedComponent,
        data: {},
      },
      {
        path: "forgot-password",
        component: ForgotPasswordComponent,
        data: {},
      },
      {
        path: "otpVerified",
        component: OtpVerifiedComponent,
        data: {},
      },
      {
        path: "sso",
        loadChildren: () => import("./sso/sso.module").then((m) => m.SSOModule),
      },
    ],
  },
  {
    path: "privacyPolicy",
    component: PrivacyPolicyComponent,
    data: {},
  },
  {
    path: "teacherTermsCondition",
    component: TeacherTermsConditionComponent,
    data: {},
  },
  {
    path: "subscription-plan",
    component: StudentSubscriptionPlansComponent,
    data: {},
  },
  // {
  //   path: 'approve',
  //   component: TeacherApproveComponent,
  //   data: {},
  // },
  {
    path: "",
    component: MainLayoutComponent,
    data: {},
    children: [
      {
        path: "admin",
        loadChildren: () =>
          import("./admin/admin.module").then((m) => m.AdminModule),
      },
      {
        path: "teacher",
        loadChildren: () =>
          import("./teacher/teacher.module").then((m) => m.TeacherModule),
      },
      {
        path: "commonFeature",
        loadChildren: () =>
          import("./common-feature/common-feature.module").then(
            (m) => m.CommonFeatureModule
          ),
      },
      {
        path: "change-password",
        component: ChangePasswordComponent,
        data: {},
      },
      {
        path: "change-email",
        component: ChangeemailComponent,
        data: {},
      },
      {
        path: "change-phone",
        component: ChangephoneComponent,
        data: {},
      },
      {
        path: "student",
        loadChildren: () =>
          import("./student/student.module").then((m) => m.StudentModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes),SharedModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
