import { Component, OnInit, SimpleChanges } from "@angular/core";
import { AppService } from "src/app/services/app.service";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { UserAccount } from "../../models/user-account";
import { UserLoginDetails } from "../../models/UserLoginDetails";
import { ChatService } from "src/app/common-feature/chat/chat.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  private studentNavLink: NavLink[];
  private teacherNavLink: NavLink[];
  private adminNavLink: NavLink[];
  role: string;
  userAccount: UserAccount;
  currentUser: UserAccount;
  navLinks: NavLink[];
  userLoginDetails: UserLoginDetails;
  constructor(
    private appService: AppService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private chatService: ChatService
  ) {
    this.appService.profileChange$.subscribe((value) => {
      if (value !== undefined && value !== null) {
        this.currentUser = value;
      }
    });
    this.userAccount = new UserAccount();
    this.currentUser = new UserAccount();
    this.userLoginDetails = new UserLoginDetails();
  }

  ngOnInit() {
    this.role = sessionStorage.getItem("Role");
    if (this.role == null) {
      this.role = localStorage.getItem("Role");
      sessionStorage.setItem("Role", this.role);
    }
    this.currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
    if (this.currentUser == null) {
      this.currentUser = JSON.parse(localStorage.getItem("CurrentUser"));
      sessionStorage.setItem("CurrentUser", JSON.stringify(this.currentUser));
    }
    this.assignNavLink();
  }

  changePassword() {
    this.router.navigate(["/change-password"]);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      const Id = changes.documentId?.currentValue;
      // this.Imgsrc = changes.Imgsrc?.currentValue;
      if (Id !== null) {
        this.currentUser.ProfilePictureId = Id;
      }
    }
  }

  viewProfile() {
    if (this.role === "Administrator") {
      this.router.navigate(["/admin/view-profile"]);
    } else if (this.role === "Teacher") {
      this.router.navigate(["/teacher/teacherProfile"]);
    } else {
      this.router.navigate(["/student/viewStudentProfile"]);
    }
  }
  private assignNavLink() {
    this.studentNavLink = [
      new NavLink(
        "Dashboard",
        ["/student/studentDashboard"],
        "fas fa-tachometer-alt"
      ),
      new NavLink(
        "Answered Questions",
        ["/student/studentQuestion/answeredQuestionsList"],
        "fas fa-graduation-cap"
      ),
      new NavLink(
        "Unanswered Questions",
        ["/student/studentQuestion/unAnsweredQuestionsList"],
        "fa fa-inbox"
      ),
      new NavLink(
        "Ask Teacher Question",
        ["/student/studentQuestion/askTeacherQuestion"],
        "fa fa-clipboard"
      ),
      new NavLink(
        "Ask Public Question",
        ["/student/studentQuestion/askPublicQuestion"],
        "fa fa-trophy"
      ),
      new NavLink(
        "Question Bank",
        ["/commonFeature/questionBank/questionBankList"],
        "fa fa-inbox"
      ),
      new NavLink(
        "Video Bank",
        ["/commonFeature/videoBank/list"],
        "fa fa-video"
      ),
    ];
    this.teacherNavLink = [
      new NavLink(
        "Dashboard",
        ["/teacher/teacherDashboard"],
        "fas fa-tachometer-alt"
      ),
      new NavLink(
        "Answered Questions",
        ["/teacher/teacherQuestion/teacherAnsweredQuestionList"],
        "fas fa-graduation-cap"
      ),
      new NavLink(
        "Unanswered Questions",
        ["/teacher/teacherQuestion/teacherUnansweredQuestionList"],
        "fa fa-inbox"
      ),
      new NavLink(
        "Public Questions",
        ["/teacher/teacherQuestion/teacherPublicQuestionList"],
        "fa fa-inbox"
      ),
      new NavLink(
        "Question Bank",
        ["/commonFeature/questionBank/questionBankList"],
        "fa fa-inbox"
      ),
      new NavLink(
        "Video Bank",
        ["/commonFeature/videoBank/list"],
        "fa fa-video"
      ),
    ];

    this.adminNavLink = [
      new NavLink("Dashboard", ["/admin/dashboard"], "fas fa-tachometer-alt"),
      new NavLink(
        "Administrator Manager",
        ["/admin/administrator-manager"],
        "fas fa-user-plus"
      ),
      new NavLink(
        "Teacher Manager",
        ["/admin/teacher-manager/list"],
        "fas fa-user-plus"
      ),
      new NavLink(
        "Student Manager",
        ["/admin/student-manager"],
        "fas fa-user-plus"
      ),
      new NavLink(
        "Question Manager",
        ["/admin/questions-manager/list"],
        "fas fa-chalkboard-teacher"
      ),
      new NavLink(
        "Teacher Rating",
        ["/admin/teacher-manager/rating"],
        "fa fa-star"
      ),
      new NavLink(
        "Public Questions",
        ["/admin/questions-manager/public-questions"],
        "fa fa-users"
      ),
      new NavLink(
        "Subject/Course Manager",
        ["/admin/subject-courses-manager"],
        "fas fa-graduation-cap"
      ),
      new NavLink(
        "Announcement Manager",
        ["/admin/announcements"],
        "fa fa-bullhorn"
      ),
      new NavLink(
        "Default Email Templates",
        ["/admin/email-templates"],
        "fa fa-envelope"
      ),
      new NavLink(
        "Subscription Plans",
        ["/admin/subscription/plan-list"],
        "fa fa-tasks"
      ),
      new NavLink(
        "School Manager",
        ["/admin/school-manager"],
        "fa fa-university"
      ),
      new NavLink(
        "Subscriber List",
        ["/admin/subscription/subscriber-list"],
        "fa fa-list-alt"
      ),
      new NavLink(
        "Expiring Subscription ",
        ["/admin/subscription/expiring-subscriber-list"],
        "fa fa-clock"
      ),
      new NavLink(
        "Question Bank",
        ["/commonFeature/questionBank/questionBankList"],
        "fa fa-inbox"
      ),
      new NavLink(
        "Video Bank",
        ["/commonFeature/videoBank/list"],
        "fa fa-video"
      ),
    ];

    this.navLinks = this.roleBasedNavLinks(); // .concat(this.commonNavLink);
  }

  private roleBasedNavLinks() {
    switch (this.role) {
      case "Student":
        return this.studentNavLink;
      case "Teacher":
        return this.teacherNavLink;
      case "Administrator":
        return this.adminNavLink;
    }
  }

  logout() {
    this.UpdateLastLoginTime();
  }

  UserLogout() {
    this.currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
    this.userLoginDetails.firstName = this.currentUser.FirstName;
    this.userLoginDetails.lastName = this.currentUser.LastName;
    this.userLoginDetails.activityType = "Logout";
    this.userLoginDetails.userAccountId = this.currentUser.Id;
    if (this.currentUser.UserRoleId == 2) {
      this.userLoginDetails.rolePrefix = "TR";
    } else if (this.currentUser.UserRoleId == 3) {
      this.userLoginDetails.rolePrefix = "ST";
    } else if (this.currentUser.UserRoleId == 1) {
      this.userLoginDetails.rolePrefix = "AD";
    }
    this.chatService.UserLoginOrLogout(this.userLoginDetails);
  }

  UpdateLastLoginTime() {
    this.spinner.show();
    this.UserLogout();
    const userDetails = { UserDetails: this.currentUser };
    this.appService.UpdateLastLogout(userDetails).subscribe((res) => {
      if (!res.HasErrors) {
        this.spinner.hide();
        sessionStorage.clear();
        this.router.navigate(["/login"]);
      } else {
        this.spinner.hide();
      }
    });
  }
}

class NavLink {
  constructor(
    public title: string,
    public route: string[],
    public iconClass: string,
    public queryParams?: { [key: string]: string | number }
  ) {}
}
