import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { HttpService } from "../core/services/http.service";
import { Observable, BehaviorSubject } from "rxjs";
import { UserAccount } from "../shared/models/user-account";
import { LogUserActivity } from "../shared/models/log-user-activity";
import { Session } from "protractor";
import { Usersmissing } from "../shared/models/Usersmissing";

@Injectable({
  providedIn: "root",
})
export class AppService {
  isSidebarPinned = false;
  isSidebarToggeled = false;

  constructor(private location: Location, private httpService: HttpService) {}

  private isProfileChange = new BehaviorSubject<any>(null);

  public NotificationCountChanged = new BehaviorSubject<boolean>(false);

  profileChange$ = this.isProfileChange.asObservable();

  private getCheckoutSession = new BehaviorSubject<any>(null);

  currentDateFormat(
    y: number,
    m: number,
    d: number,
    h: number,
    min: number,
    sec: number,
    ms: number
  ) {
    const date = new Date();
    date.setFullYear(y);
    date.setMonth(m);
    date.setDate(d);
    date.setHours(h);
    date.setMinutes(min);
    date.setSeconds(sec);
    date.setMilliseconds(ms);
    return date;
  }

  getCheckoutSession$ = this.getCheckoutSession.asObservable();

  profileChange(user: UserAccount) {
    this.isProfileChange.next(user);
  }
  setSession(sessionId: string) {
    this.getCheckoutSession.next(sessionId);
  }

  toggleSidebar() {
    this.isSidebarToggeled = !this.isSidebarToggeled;
  }

  toggleSidebarPin() {
    this.isSidebarPinned = !this.isSidebarPinned;
  }

  getSidebarStat() {
    return {
      isSidebarPinned: this.isSidebarPinned,
      isSidebarToggeled: this.isSidebarToggeled,
    };
  }

  goBack() {
    this.location.back();
  }

  login(loginDetails: any): Observable<any> {
    return this.httpService.postAnonymous("security/Login", loginDetails);
  }

  uploadProfile(file, base64File): Observable<any> {
    const fileObj = {
      FileName: file.name,
      File: base64File,
      DocumentType: file.type,
      status: "1",
    };
    const abc = { FilesMetadata: fileObj };
    return this.httpService.postAnonymous("account/SaveProfile", abc);
  }

  getUser(user): Observable<any> {
    return this.httpService.post("account/Get", user);
  }

  getRoleByPrefix(prefix): Observable<any> {
    return this.httpService.post("security/RoleByPrefix", prefix);
  }

  getRoleById(roleObj): Observable<any> {
    return this.httpService.post("security/RoleById", roleObj);
  }

  getGrades(): Observable<any> {
    return this.httpService.getAnonymous("master/ListGrade");
  }

  getCourses(): Observable<any> {
    return this.httpService.get("course/List");
  }

  getSubjects(): Observable<any> {
    return this.httpService.get("master/ListSubject");
  }

  getCountries(): Observable<any> {
    return this.httpService.getAnonymous("master/ListCountry");
  }

  getStatesByCountry(countryId): Observable<any> {
    // const Id = { CountryId: countryId };
    return this.httpService.getAnonymous("master/ListState");
  }

  registration(user: any): Observable<any> {
    return this.httpService.postAnonymous("security/Registration", user);
  }

  getSchoolsByCode(code: any): Observable<any> {
    return this.httpService.postAnonymous("school/ListByCode", code);
  }

  sendOTP(email: any): Observable<any> {
    return this.httpService.postAnonymous("security/SendOTP", email);
  }

  verifyOTP(userDetails): Observable<any> {
    return this.httpService.postAnonymous("security/VerifyOTP", userDetails);
  }

  sendRegistrationOTP(email: any): Observable<any> {
    return this.httpService.postAnonymous("security/RegistrationOTP", email);
  }

  sendOTPForVerify(email: any): Observable<any> {
    return this.httpService.postAnonymous("security/SendOTPForVerify", email);
  }

  getGradeById(grade): Observable<any> {
    return this.httpService.postAnonymous("security/getGradeById", grade);
  }
  getCourseById(course): Observable<any> {
    return this.httpService.postAnonymous("course/Get", course);
  }
  getSubjectById(subject): Observable<any> {
    return this.httpService.postAnonymous("subject/Get", subject);
  }

  getAuthCode(): Observable<any> {
    return this.httpService.get(`captcha/GetAuthcode`);
  }
  getCaptcha(code: string): Observable<any> {
    return this.httpService.get(`captcha/GetCaptcha?authCode=${code}`);
  }
  validateCaptha(code: string, text: string): Observable<any> {
    return this.httpService.get(
      `captcha/ValidateCaptcha?authCode=${code}&captcha=${text}`
    );
  }

  getProfilePicture(document) {
    return this.httpService.postAnonymous(
      "account/GetProfilePicture",
      document
    );
  }
  addLogUserActivity(logUserActivity: LogUserActivity): Observable<any> {
    const userActivity = { UserActivity: logUserActivity };
    return this.httpService.postAnonymous("activitylog/Add", userActivity);
  }

  createSession(data) {
    const planData = { SessionData: data };
    return this.httpService.post(
      "subscription/CreateCheckoutSession",
      planData
    );
  }

  checkoutSession(sessionId) {
    const session = { SessionId: sessionId };
    return this.httpService.post("subscription/CheckoutSession", session);
  }

  ssoRegistration(user) {
    return this.httpService.postAnonymous("sso/SSORegistration", user);
  }

  UpdateUserDetails(userDetails: any): Observable<any> {
    return this.httpService.postAnonymous(
      "account/UpdateUserDetails",
      userDetails
    );
  }

  sendEmailOTP(email: any): Observable<any> {
    return this.httpService.postAnonymous("security/SendEmailOTP", email);
  }

  UpdateLastLogout(userDetails: any): Observable<any> {
    return this.httpService.post("security/Logout", userDetails);
  }

  UpdateLastLogin(userDetails: any): Observable<any> {
    return this.httpService.post("security/UpdateLastLogin", userDetails);
  }

  ChangeEmail(userDetails): Observable<any> {
    return this.httpService.post("security/ChangeEmail", userDetails);
  }

  SendPhoneOTP(userDetails): Observable<any> {
    return this.httpService.postAnonymous("security/SendPhoneOTP", userDetails);
  }

  ChangePhone(userDetails): Observable<any> {
    return this.httpService.post("security/ChangePhone", userDetails);
  }

  SendAdminEmail(userDetails: Usersmissing): Observable<any> {
    const userInfo = { missingUserInfo: userDetails };
    return this.httpService.post("account/SendEmailToAdmin", userInfo);
  }
}
