import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registration-completed',
  templateUrl: './registration-completed.component.html',
  styleUrls: ['./registration-completed.component.scss']
})
export class RegistrationCompletedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
