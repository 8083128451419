import { Component, OnInit } from "@angular/core";
import { AppService } from "../../../services/app.service";
import { Constants } from "src/app/shared/models/constants";
import { UserAccount } from "../../../shared/models/user-account";
import { NgForm } from "@angular/forms";
import { AdminService } from "../../../admin/admin.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { UserRole } from "src/app/shared/models/user-role";
import { isBuffer } from "util";
import { Router } from "@angular/router";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  constants: any;
  currentUser: UserAccount;
  newPassword: string;
  confirmPassword: string;
  oldPassword: string;
  userOldPassword: string;
  isOldPasswordVerify: boolean;
  userRole: UserRole;

  constructor(
    private router: Router,
    private appService: AppService,
    private adminService: AdminService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    this.constants = Constants;
    this.userRole = new UserRole();
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
    this.getRole();
    this.spinner.hide();
  }
  getRole() {
    const roleObj = { RoleId: this.currentUser.UserRoleId };
    this.appService.getRoleById(roleObj).subscribe(
      (res) => {
        if (!res.HasErrors) {
          this.userRole = res;
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  validateOldPassword(validatePasswordForm: NgForm) {
    if (validatePasswordForm.valid) {
      this.spinner.show();
      const user = {
        UserAccountId: this.currentUser.Id,
        Password: this.oldPassword,
      };
      this.adminService.validateOldPassword(user).subscribe(
        (res) => {
          if (!res.HasErrors) {
            this.isOldPasswordVerify = true;
            this.spinner.hide();
          } else {
            this.toastr.error(res.Errors[0].Message, "Error");
            this.isOldPasswordVerify = false;
            this.spinner.hide();
          }
        },
        (err) => {
          this.spinner.hide();
        }
      );
    }
  }

  checkPassword() {
    return this.newPassword === this.confirmPassword ? true : false;
  }

  goBack() {
    this.appService.goBack();
  }

  confirmChangePassword(changePasswordForm: NgForm) {
    if (changePasswordForm.valid) {
      this.spinner.show();
      this.currentUser.Password = this.confirmPassword;
      this.currentUser.ModifiedById = this.currentUser.Id;
      this.currentUser.ModifiedBy =
        this.currentUser.FirstName + " " + this.currentUser.LastName;
      sessionStorage.setItem("CurrentUser", JSON.stringify(this.currentUser));
      this.adminService.changePassword(this.currentUser).subscribe(
        (res) => {
          if (!res.HasErrors) {
            changePasswordForm.resetForm();
            this.newPassword = null;
            this.oldPassword = null;
            this.confirmPassword = null;
            this.toastr.success("Password changed successfully", "Success");
            if (this.userRole.Prefix === "TR") {
              if (!this.currentUser.IsApproved) {
                this.router.navigate(["/teacher/approve"]);
              } else {
                this.router.navigate(["/teacher/teacherDashboard"]);
              }
            }
            else if(this.userRole.Prefix == "ST"){
              this.router.navigate(['/student/studentDashboard']);
            }
            else if(this.userRole.Prefix == "AD"){
              this.router.navigate(['/admin/dashboard']);
            }
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
        },
        (err) => {
          this.spinner.hide();
        }
      );
    }
  }
}
