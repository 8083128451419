import { BaseEntity } from './base-entity';
export class DmsDocument extends BaseEntity {
  DeploymentId: number;
  DocumentType: string;
  SiteId: number;
  Title: string;
  FileName: string;
  FileUniqueName: string;
  FileSize: string;
  FileExtension: string;
  FolderPath: string;
  IsActive: boolean;
  Url: string;
  Content: string;
  Base64Url: string;

  constructor() {
    super();
    this.DeploymentId = null;
    this.DocumentType = null;
    this.SiteId = null;
    this.Title = null;
    this.FileName = null;
    this.FileUniqueName = null;
    this.FileSize = null;
    this.FileExtension = null;
    this.FolderPath = null;
    this.IsActive = false;
    this.Url = null;
    this.Content = null;
    this.Base64Url = null;
  }
}
