export class BaseEntity {
  Id: number;
  CreatedById: number;
  CreatedDate: Date;
  ModifiedById: number;
  ModifiedDate: Date;
  ModifiedBy: string;
  CreatedBy: string;

  constructor() {
    this.Id = 0;
    this.CreatedById = 0;
    this.CreatedDate = null;
    this.ModifiedById = 0;
    this.ModifiedDate = null;
    this.ModifiedBy = null;
    this.CreatedBy = null;
  }
}
