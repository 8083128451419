import { BaseEntity } from "./base-entity";

export class UserSubscription extends BaseEntity {
  SubscriptionId: number;
  Title: string;
  SubscriptionCode: string;
  RemainingQuestion: number;
  QuestionLimit: number;
  StartDate: Date;
  EndDate: Date;
  IsActive: boolean;
  DeploymentId: number;
  UserAccountId: number;
  SubscriptionDays: number;
  ParentSubscriptionCategory: number;
  Price: number;
  Duration: number;

  constructor() {
    super();
    this.SubscriptionId = 0;
    this.Title = null;
    this.SubscriptionCode = null;
    this.QuestionLimit = 0;
    this.RemainingQuestion = 0;
    this.StartDate = null;
    this.EndDate = null;
    this.IsActive = false;
    this.SubscriptionDays = 0;
    this.DeploymentId = 1;
    this.ParentSubscriptionCategory = 0;
    this.Price = null;
    this.Duration = null;
  }
}
