import { Component, OnInit, ViewChild } from '@angular/core';
import { UserAccount } from 'src/app/shared/models/user-account';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserRole } from 'src/app/shared/models/user-role';
import { AppService } from 'src/app/services/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Constants } from 'src/app/shared/models/constants';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-changephone',
  templateUrl: './changephone.component.html',
  styleUrls: ['./changephone.component.scss']
})
export class ChangephoneComponent implements OnInit {

  c
  constructor(private appService:AppService,private spinner:NgxSpinnerService,private toastr:ToastrService,private router:Router) {
    this.constants = Constants;
   }

  @ViewChild("changePhoneForm") ChangePhoneForm:NgForm;
  MobileOtp:string;
  EmailOtp:string;
  isOTPSend = false;
  isEmailNull = true;
  newPhone = null;
  isOTPVerified = false;
  bsModalRef:BsModalRef;
  userAccount:UserAccount;
  userRole:UserRole;
  constants: any;
  emailPattern: string;
  changePhone: {
    Phone: string;
    OTP: string;
  };

  ngOnInit(): void {
    this.userAccount = JSON.parse(sessionStorage.getItem('CurrentUser'));
    this.getRole();
  }

  sendOTP(isResentOtp) {
    this.spinner.show();
    if(this.ChangePhoneForm.valid || isResentOtp){
      if (this.newPhone !== null) {
        if (this.userAccount.Email.localeCompare(this.newPhone)) {
          const user = {
            Phone: this.newPhone,
            UserDetails: this.userAccount,
          };
          this.appService.SendPhoneOTP(user).subscribe(
            (res) => {
              if (!res.HasErrors) {
                this.isOTPSend = true;
                this.toastr.success(
                  'OTP has been successfully sent to your phone.',
                  'Success'
                );
                this.spinner.hide();
              } else {
                if (res.Errors !== undefined) {
                  this.toastr.error(res.Errors[0].Message, 'Error');
                }
                this.spinner.hide();
              }
            },
            (err) => {
              this.spinner.show();
            }
          );
        }
        else{
          this.toastr.error("You entered same phone, Please enter different phone.");
        }
      } else {
        this.isEmailNull = true;
        this.spinner.hide();
      }
    }
    this.spinner.hide();
  }

  getRole() {
    const roleObj = { RoleId: this.userAccount.UserRoleId };
    this.appService.getRoleById(roleObj).subscribe(
      (res) => {
        if (!res.HasErrors) {
          this.userRole = res;
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  verifyOTP(changePhoneForm:NgForm) {
    if(changePhoneForm.valid){
      this.spinner.show();
      const userDetails = {
        Phone: this.newPhone,
        OTP: this.MobileOtp,
        UserDetails:this.userAccount
      };
      this.appService.ChangePhone(userDetails).subscribe(
        (res) => {
          if (!res.HasErrors) {
            this.userAccount =
              res !== undefined && res !== null ? res : new UserAccount();
              if(sessionStorage.getItem("CurrentUser")!=null){
                sessionStorage.removeItem("CurrentUser");
                sessionStorage.setItem("CurrentUser",JSON.stringify(this.userAccount));
              }
            this.isOTPVerified = true;
            this.toastr.success('Change Phone successfully.');
            if (this.userRole.Prefix === "TR") {
              if (!this.userAccount.IsApproved) {
                this.router.navigate(["/teacher/approve"]);
              } else {
                this.router.navigate(["/teacher/teacherDashboard"]);
              }
            }
            else if(this.userRole.Prefix == "ST"){
              this.router.navigate(['/student/studentDashboard']);
            }
            else if(this.userRole.Prefix == "AD"){
              this.router.navigate(['/admin/dashboard']);
            }
            this.spinner.hide();
          } else {

            this.toastr.error(res.Errors[0].Message, 'Error');
            this.spinner.hide();
          }
        },
        (err) => {
          this.spinner.hide();
        }
      );
    }
  }
  getCurrentUser(currentUserId) {
    this.spinner.show();
    const user = { Id: currentUserId };
    this.appService.getUser(user).subscribe(
      (res) => {
        if (!res.HasErrors) {
          this.userAccount = res;
          this.userAccount.DateOfBirth = new Date(this.userAccount.DateOfBirth);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  


  goBack() {
    this.appService.goBack();
  }

}