export class UserRole {
  Id: number;
  Name: string;
  Prefix: string;

  constructor() {
    this.Id = 0;
    this.Name = null;
    this.Prefix = null;
  }
}
