import { UserAccount } from './user-account';
import { Teacher } from './teacher';
import { TeacherReview } from './teacher-review';
import { BaseEntity } from './base-entity';

export class TeacherGrade {
  Id: number;
  TeacherId: number;
  GradeId: number;

  constructor() {
    this.Id = null;
    this.TeacherId = null;
    this.GradeId = null;
  }
}

export class TeacherSubject {
  Id: number;
  TeacherId: number;
  SubjectId: number;

  constructor() {
    this.Id = null;
    this.TeacherId = null;
    this.SubjectId = null;
  }
}

export class TeacherCourse {
  Id: number;
  TeacherId: number;
  CourseId: number;

  constructor() {
    this.Id = null;
    this.TeacherId = null;
    this.CourseId = null;
  }
}

export class TeacherVM {
  UserAccount: UserAccount;
  Teacher: Teacher;
  Courses: TeacherCourse[];
  Subjects: TeacherSubject[];
  Grades: TeacherGrade[];
  Answered: number;
  TeacherReviewList: TeacherReview[];
  QualificationFiles: QualificationFiles[];
  Rate: number;
  constructor() {
    this.UserAccount = new UserAccount();
    this.Teacher = new Teacher();
    this.Courses = new Array<TeacherCourse>();
    this.Subjects = new Array<TeacherSubject>();
    this.Grades = new Array<TeacherGrade>();
    this.Answered = 0;
    this.TeacherReviewList = new Array<TeacherReview>();
    this.QualificationFiles= new Array<QualificationFiles>();
    this.Rate = 0;
  }
}
export class UserAccountDocument extends BaseEntity{
  UserAccountId: number;
  DocumentId: number;
  IsActive: boolean;
  constructor() {
    super();
    this.UserAccountId = 0;
    this.DocumentId = 0;
    this.IsActive = null;
  }
}
  export class QualificationFiles {
    UserAccountDocument: UserAccountDocument;
    DocumentName: string;
    DocumentUrl: string;
    constructor() {
      this.UserAccountDocument = new UserAccountDocument();
      this.DocumentName = null;
      this.DocumentUrl = null;
    }
  }

