import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Constants } from 'src/app/shared/models/constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../../../services/app.service';
import { UserRole } from 'src/app/shared/models/user-role';
import { UserAccount } from 'src/app/shared/models/user-account';
import { Grade } from 'src/app/shared/models/Grade';
import {
  TeacherGrade,
  TeacherCourse,
  TeacherSubject,
} from '../../../shared/models/teacher-vm';
import { Course } from 'src/app/shared/models/Course';
import { Subject } from '../../../shared/models/subject';
import { RegistrationVM } from '../../../shared/models/registration-vm';
import { School } from '../../../admin/school-manager/models/school';
import { Country } from 'src/app/shared/models/country';
import { State } from 'src/app/shared/models/state';
import { SubjectCoursesService } from '../../../admin/subject-courses-manager/subject-courses.service';
import { EncryptDecryptParametersService } from 'src/app/services/encrypt-decrypt-parameters.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  registrationVM: RegistrationVM;
  constants: any;
  passwordMismatch: boolean;
  isTeacher = false;
  userRole: UserRole;
  gradesList: Grade[];
  currentUser: UserAccount;
  courseList: Course[];
  subjectList: Subject[];
  teacherGrade: TeacherGrade;
  teacherSubject: TeacherSubject;
  teacherCourse: TeacherCourse;
  isEmptyGrades: boolean;
  isEmptyCourses: boolean;
  isEmptySubjects: boolean;
  confirmPassword: string;
  schoolList: School[];
  countryList: Country[];
  stateList: State[];
  userAccount: any;
  emailPattern: string;
  isOTPSend = false;
  registrationOTPForEmail: string;
  registrationOTPForMobile: string;
  isEmailNull = false;
  isOTPVerified = false;
  isOTPNull = false;
  registerAs: string;
  constructor(
    private appService: AppService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private subjectCourseService: SubjectCoursesService,
    private encryptDecryptParametersService: EncryptDecryptParametersService,
    private router: Router
  ) {
    this.userRole = new UserRole();
    this.constants = Constants;
    this.registrationVM = new RegistrationVM();
    this.constants = Constants;
    this.gradesList = new Array<Grade>();
    this.courseList = new Array<Course>();
    this.subjectList = new Array<Subject>();
    this.teacherGrade = new TeacherGrade();
    this.teacherSubject = new TeacherSubject();
    this.teacherCourse = new TeacherCourse();
    this.schoolList = new Array<School>();
    this.countryList = new Array<Country>();
    this.stateList = new Array<State>();
    this.emailPattern = this.constants.EMAIL_REGEXP;
    // this.registrationOTP = null;
  }

  ngOnInit(): void {
    // this.currentUser = JSON.parse(sessionStorage.getItem('CurrentUser'));
    const finalURL =
      this.router.url.split('?')[0] +
      '?' +
      this.encryptDecryptParametersService.decrypt(this.router.url);
    this.registerAs = this.encryptDecryptParametersService.getQueryParams(
      'as',
      finalURL
    );
    this.isTeacher = this.registerAs === 'STUDENT' ? false : true;
    this.getGrades();
  }

  getGrades() {
    this.spinner.show();
    this.appService.getGrades().subscribe(
      (res) => {
        if (!res.HasErrors) {
          this.gradesList =
            res.Rows.length !== 0 ? res.Rows : new Array<Grade>();
          this.getSubjects();
        } else {
          this.spinner.hide();
        }
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  getSubjects() {
    this.spinner.show();
    this.appService.getSubjects().subscribe(
      (res) => {
        if (!res.HasErrors) {
          this.subjectList =
            res.Rows.length !== 0 ? res.Rows : new Array<Subject>();
          this.getCountries();
        } else {
          this.spinner.hide();
        }
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  getCoursesById(subject: Subject) {
    if (subject.IsChecked === true) {
      this.spinner.show();
      const subjectObj = { Id: subject.Id };
      this.subjectCourseService.getCourseListBySubjectId(subjectObj).subscribe(
        (res) => {
          if (!res.HasErrors) {
            const courseList =
              res.Rows.length !== 0 ? res.Rows : new Array<Course>();
            if (this.courseList.length !== 0) {
              this.courseList.push(...courseList);
            } else {
              this.courseList = courseList;
            }
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
        },
        () => {
          this.spinner.hide();
        }
      );
    } else {
      // this.courseList.forEach((e, i) => {
      //   if (e.SubjectId === subject.Id) {
      //     this.courseList.splice(i, 1);
      //   }
      // });
      let courseRecords = this.courseList.filter(x=>x.SubjectId === subject.Id);
      this.courseList = this.courseList.filter(function(obj) {
        return !this.has(obj.Id);
    }, new Set(courseRecords.map(obj => obj.Id)));
      // this.courseList.forEach((e, i) => {
      //   if (e.SubjectId === subject.Id) {
      //     this.courseList.splice(i, 1);
      //   }
      // });


      // for(let i=0;i<=this.courseList.length;i++){
      //   if(this.courseList[i].SubjectId == subject.Id){
      //     this.courseList[i].IsChecked = false;
      //     this.courseList.splice(i,1);
      //   }
      // }
      
      // this.countryList.filter()
      
      // let i = 0;
      // this.courseList.find(course => course.SubjectId == subject.Id)
      // {
      //   this.courseList.splice(i,1);
      //   i++;
      // }
    }
  }

  getCountries() {
    this.appService.getCountries().subscribe(
      (res) => {
        if (!res.HasErrors) {
          this.countryList =
            res.Rows.length !== 0 ? res.Rows : new Array<Country>();
          this.getStatesByCountry(this.registrationVM.UserAccount.Country);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  getStatesByCountry(event: string) {
    this.spinner.show();
    const c = this.countryList.filter((c) => c.Name === event)[0];
    this.appService.getStatesByCountry(c.Id).subscribe(
      (res) => {
        if (!res.HasErrors) {
          this.registrationVM.UserAccount.State = null;
          this.stateList =
            res.Rows !== null && res.Rows !== undefined && res.Rows.length !== 0
              ? res.Rows
              : new Array<State>();
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  checkPassword() {
    return this.registrationVM.UserAccount.Password === this.confirmPassword
      ? true
      : false;
  }

  changeRegistrationType(form: NgForm) {
    this.isTeacher = !this.isTeacher;
    form.resetForm();
    this.registrationVM = new RegistrationVM();
    this.registrationVM.UserAccount.Country = 'United States';
    this.getCountries();
  }

  getSchools(schoolSubscriptionCode) {
    if (schoolSubscriptionCode !== null) {
      this.spinner.show();
      const code = { SubscriptionCode: schoolSubscriptionCode };
      this.appService.getSchoolsByCode(code).subscribe(
        (res) => {
          if (!res.HasErrors) {
            this.schoolList =
              res.Rows !== undefined && res.Rows !== null
                ? res.Rows
                : new Array<School>();
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this.toastr.error('Please enter valid code', 'Error');
          }
        },
        () => {
          this.spinner.hide();
        }
      );
    }
  }

  getSchoolName(event) {
    const school = this.schoolList.filter((x) => x.Id === event)[0];
    this.userAccount.SchoolName = school.Name;
  }

  submitRegistration(registrationForm: NgForm) {
    if (registrationForm.valid && this.checkPassword()) {
      if (this.isTeacher === true) {
        if (this.gradesList.some((x) => x.IsChecked === true)) {
          this.gradesList.forEach((e) => {
            if (e.IsChecked === true) {
              this.teacherGrade = new TeacherGrade();
              this.teacherGrade.Id = 0;
              this.teacherGrade.TeacherId = 0;
              this.teacherGrade.GradeId = e.Id;
              this.registrationVM.TeacherGrades.push(this.teacherGrade);
            }
          });
          this.isEmptyGrades = false;
        } else {
          this.isEmptyGrades = true;
        }
        if (this.subjectList.some((x) => x.IsChecked === true)) {
          this.subjectList.forEach((e) => {
            if (e.IsChecked === true) {
              this.teacherSubject = new TeacherSubject();
              this.teacherSubject.Id = 0;
              this.teacherSubject.TeacherId = 0;
              this.teacherSubject.SubjectId = e.Id;
              this.registrationVM.TeacherSubjects.push(this.teacherSubject);
              if (
                this.courseList.some(
                  (x) => x.SubjectId === e.Id && x.IsChecked === true
                )
              ) {
                const courses = this.courseList.filter(
                  (x) => x.SubjectId === e.Id
                );
                courses.forEach((e) => {
                  if (e.IsChecked === true) {
                    this.teacherCourse = new TeacherCourse();
                    this.teacherCourse.Id = 0;
                    this.teacherCourse.TeacherId = 0;
                    this.teacherCourse.CourseId = e.Id;
                    this.registrationVM.TeacherCourses.push(this.teacherCourse);
                  }
                });
                this.isEmptyCourses = false;
              } else {
                this.isEmptyCourses = true;
                this.isEmptyCourses = true;
                return;
              }
            }
          });
          this.isEmptySubjects = false;
        } else {
          this.isEmptySubjects = true;
        }
        if (!this.isEmptyCourses && !this.isEmptySubjects) {
          this.getRoleId('TR', registrationForm);
        }
      } else {
        this.getRoleId('ST', registrationForm);
      }
    }
  }

  getRoleId(prefix, form: NgForm) {
    this.spinner.show();
    const prefixObj = { Prefix: prefix };
    this.appService.getRoleByPrefix(prefixObj).subscribe(
      (res) => {
        if (!res.HasErrors) {
          this.userRole = res;
          if (this.userRole.Prefix === 'TR') {
            if (form.valid && !this.isEmptySubjects && !this.isEmptyCourses) {
              this.sendOTP();
            }
          } else {
            if (form.valid) {
              this.sendOTP();
            }
          }
        } else {
          this.spinner.hide();
        }
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  sendOTP() {
    this.registrationVM.UserAccount.UserRoleId = this.userRole.Id;
    if (this.registrationVM.UserAccount.Email !== null) {
      if (this.registrationVM.UserAccount.Email.match(this.emailPattern)) {
        this.spinner.show();
        const user = {
          Email: this.registrationVM.UserAccount.Email,
          Mobile: this.registrationVM.UserAccount.Phone,
          UserDetails: this.registrationVM.UserAccount,
        };
        this.appService.sendRegistrationOTP(user).subscribe(
          (res) => {
            if (!res.HasErrors) {
              this.isOTPSend = true;
              this.toastr.success(
                'OTP has been successfully sent to your email.',
                'Success'
              );
              this.spinner.hide();
            } else {
              if (res.Errors !== undefined) {
                this.toastr.error(res.Errors[0].Message, 'Error');
              } else {
                this.toastr.error('Something went wrong', 'Error');
              }
              this.spinner.hide();
            }
          },
          (err) => {
            this.spinner.show();
          }
        );
      }
    } else {
      this.isEmailNull = true;
    }
  }

  // verifyOTP() {
  //   if (this.registrationOTP !== null) {
  //     if (this.registrationOTP.length === 8) {
  //       if (
  //         this.registrationVM.UserAccount.Email !== null &&
  //         this.registrationOTP !== null
  //       ) {
  //         this.spinner.show();
  //         const userDetails = {
  //           Email: this.registrationVM.UserAccount.Email,
  //           OTP: this.registrationOTP,
  //         };
  //         this.appService.verifyRegistrationOTP(userDetails).subscribe(
  //           (res) => {
  //             if (!res.HasErrors) {
  //               this.isOTPVerified = true;
  //               this.toastr.success('OTP verified successfully');
  //               this.spinner.hide();
  //             } else {
  //               this.toastr.error(res.Errors[0].Message);
  //               this.spinner.hide();
  //             }
  //           },
  //           () => {
  //             this.spinner.hide();
  //           }
  //         );
  //       }
  //     } else {
  //       this.isOTPVerified = false;
  //     }
  //   } else {
  //     this.isOTPNull = true;
  //   }
  // }

  registration(form: NgForm) {
    if (form.valid) {
      if (this.userRole.Prefix === 'TR') {
        this.submitTeacherManagerDetails(form);
      } else {
        this.submitStudentManagerDetails(form);
      }
    }
  }

  submitStudentManagerDetails(studentDetailsForm: NgForm) {
    if (studentDetailsForm.valid) {
      this.spinner.show();
      if(this.registrationVM.UserAccount.Zip!=null){
        this.registrationVM.UserAccount.Zip.replace(/\D+/g, '');
      }
      this.registrationVM.UserAccount.UserRoleId = this.userRole.Id;
      this.registrationVM.UserAccount.CreatedById = 0;
      this.registrationVM.UserAccount.ModifiedById = 0;
      this.registrationVM.UserAccount.IsPhoneVerified = true;
      this.registrationVM.UserAccount.IsApproved = true;
      this.registrationVM.UserAccount.IsEmailVerified = true;
      this.registrationVM.UserAccount.IsFirstLogin =
        this.registrationVM.SchoolSubscriptionCode != null ? false : true;
      const OTP = {
        mailOtp: this.registrationOTPForEmail,
        phoneOtp: this.registrationOTPForMobile,
      };
      const user = {
        UserRole: this.userRole,
        UserDetails: this.registrationVM,
        OTP: OTP,
      };
      this.appService.registration(user).subscribe(
        (res) => {
          if (!res.HasErrors) {
            this.toastr.success('Student registration successful.', 'Success');
            this.spinner.hide();
            studentDetailsForm.resetForm();
            this.router.navigate(['/registration-completed']);
          } else {
            if (res.Errors !== undefined) {
              this.toastr.error(res.Errors[0].Message, 'Error');
            } else {
              this.toastr.error('Something went wrong', 'Error');
            }
            this.spinner.hide();
          }
        },
        () => {
          this.spinner.hide();
        }
      );
    }
  }

  submitTeacherManagerDetails(teacherDetailsForm: NgForm) {
    if (
      teacherDetailsForm.valid &&
      !this.isEmptySubjects &&
      !this.isEmptyCourses &&
      !this.isEmptyGrades
    ) {
      this.spinner.show();
      if(this.registrationVM.UserAccount.Zip!=null){
      this.registrationVM.UserAccount.Zip.replace(/\D+/g, '');
      }
      this.registrationVM.UserAccount.UserRoleId = this.userRole.Id;
      this.registrationVM.UserAccount.CreatedById = 0;
      this.registrationVM.Teacher.CreatedById = 0;
      this.registrationVM.UserAccount.IsPhoneVerified = true;
      this.registrationVM.UserAccount.IsEmailVerified = true;
      this.registrationVM.UserAccount.IsFirstLogin = true;
      const OTP = {
        mailOtp: this.registrationOTPForEmail,
        phoneOtp: this.registrationOTPForMobile,
      };
      const user = {
        UserRole: this.userRole,
        UserDetails: this.registrationVM,
        OTP: OTP,
      };
      this.appService.registration(user).subscribe(
        (res) => {
          if (!res.HasErrors) {
            this.toastr.success('Teacher registration successful.', 'Success');
            this.spinner.hide();
            teacherDetailsForm.resetForm();
            this.router.navigate(['/registration-completed']);
          } else {
            if (res.Errors !== undefined) {
              this.toastr.error(res.Errors[0].Message, 'Error');
            } else {
              this.toastr.error('Something went wrong', 'Error');
            }
            this.spinner.hide();
          }
        },
        () => {
          this.spinner.hide();
        }
      );
    }
  }

  resetForm(registrationForm: NgForm) {
    registrationForm.resetForm();
    this.registrationVM = new RegistrationVM();
    this.courseList = new Array<Course>();
    this.confirmPassword = null;
  }
  goBack() {
    this.appService.goBack();
  }
}
