export class Account {
  public Id: number;
  public FirstName: string;
  public LastName: string;
  public Email: string;
  public ProfileUrl: string;
  constructor() {
    this.Id = 0;
    this.FirstName = null;
    this.LastName = null;
    this.Email = null;
    this.ProfileUrl = null;
  }
}
