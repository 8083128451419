import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RegistrationVM } from 'src/app/shared/models/registration-vm';
import { UserRole } from 'src/app/shared/models/user-role';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/services/app.service';
import { Router } from '@angular/router';
import { UserAccount } from 'src/app/shared/models/user-account';
import { Constants } from 'src/app/shared/models/constants';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-otp-verified',
  templateUrl: './otp-verified.component.html',
  styleUrls: ['./otp-verified.component.scss']
})
export class OtpVerifiedComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private toastr:ToastrService,
    private appService:AppService,
    private router:Router) {
    this.registrationVM = new RegistrationVM();
    this.constants = Constants;
    this.emailPattern = this.constants.EMAIL_REGEXP;
   }
  registrationVM:RegistrationVM;
  registrationOTPForEmail:string;
  registrationOTPForMobile:string;
  userRole:UserRole;
  emailPattern:string;
  isOTPSend = false;
  isEmailNull = false;
  isOTPVerified = false;
  currentUser:UserAccount;
  constants: any;
  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem('CurrentUser'));
    this.registrationVM.UserAccount = this.currentUser;
    this.getRole();
  }

  sendOTP() {
    if (this.registrationVM.UserAccount.Email !== null) {
      if (this.registrationVM.UserAccount.Email.match(this.emailPattern)) {
        const user = {
          Email: this.registrationVM.UserAccount.Email,
          Mobile: this.registrationVM.UserAccount.Phone,
          UserDetails: this.registrationVM.UserAccount,
        };
        this.spinner.show();
        this.appService.sendOTPForVerify(user).subscribe(
          (res) => {
            if (!res.HasErrors) {
              this.isOTPSend = true;
              this.toastr.success(
                'OTP has been successfully sent to your email.',
                'Success'
              );
              this.spinner.hide();
            } else {
              if (res.Errors !== undefined) {
                if(res?.Errors[0]?.Message!=null){
                  this.toastr.error(res?.Errors[0]?.Message, 'Error');
                }
                else if(res?.Errors[1]?.Message!=null){
                  this.toastr.error(res?.Errors[1]?.Message, 'Error');
                }
                this.toastr.error('Please enter both email and phone to get OTP.', 'Error');
              } else {
                this.toastr.error('Something went wrong', 'Error');
              }
              this.spinner.hide();
            }
          },
          (err) => {
            this.spinner.show();
          }
        );
      }
    } else {
      this.isEmailNull = true;
    }
  }

  submitUserDetails(otpVerifiedForm: NgForm) {
    if (otpVerifiedForm.valid) 
    {
      this.spinner.show();
      // this.registrationVM.UserAccount.IsFirstLogin = true;
      const OTP = {
        mailOtp: this.registrationOTPForEmail,
        phoneOtp: this.registrationOTPForMobile,
      };
      const user = {
        UserRole: this.userRole,
        UserDetails: this.registrationVM.UserAccount,
        OTP: OTP,
      };
      if(this.isOTPSend){
        this.appService.UpdateUserDetails(user).subscribe(
  (res) => {
    if (!res.HasErrors) {
      this.toastr.success('Otp verification successfull.', 'Success');
      this.spinner.hide();
      otpVerifiedForm.resetForm();
      if(this.userRole.Prefix=="TR"){
        this.router.navigate(['/teacher/teacherDashboard']);
      }
      else if(this.userRole.Prefix=="ST"){
        if (res.IsFirstLogin) {
          this.router.navigate(['/subscription-plan']);
          this.spinner.hide();
        } else {
          this.router.navigate(['/student/studentDashboard']);
          this.spinner.hide();
        }
      }
    } else {
      if (res.Errors !== undefined) {
        this.toastr.error(res.Errors[0].Message, 'Error');
      } else {
        this.toastr.error('Something went wrong', 'Error');
      }
      this.spinner.hide();
    }
  },
  () => {
    this.spinner.hide();
  }
);
      }
    }
  }

  getRole() {
    const roleObj = { RoleId: this.currentUser.UserRoleId };
    this.spinner.show();
    this.appService.getRoleById(roleObj).subscribe(
      (res) => {
        if (!res.HasErrors) {
          this.userRole = res;
          // this.sendOTP();
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  valuechange(event){
    this.isOTPSend = false;
  }
  goBack(){
    this.router.navigate(['/login']);
  }
}


